<template>
  <div class="home">
    <!-- 遮罩层 -->
    <div class="cover" v-show="hongbaoState1"></div>
    <div class="cover2" v-show="hongbaoState2"></div>
    <!-- 左边盒子 -->
    <div class="box-left">
      <!-- logo -->
      <div class="drop-feed__header" @click="goIndex">
        <img src="../assets/img/logo.png" alt="" />
      </div>
      <!-- 侧边轮播图 -->
      <div class="socketService">
        <!-- 筛选 -->
        <div class="drop-feed__sort">
          <div v-for="item in 3" :key="item">
            <div class="sort-item"></div>
          </div>
        </div>
        <div class="drop-feed__items-column">
          <leftSlide :showNav="showNav"></leftSlide>
        </div>
      </div>
    </div>
    <!-- 右边盒子 -->
    <div class="box-right">
      <div class="top">
        <el-row class="top-el-row">
          <div class="top-l-r">
            <el-col
              class="logo logo1 el-col-18"
              :style="{
                marginLeft: showNav ? '30px' : '10px',
              }"
            >
              <!-- <sapn @click="$router.back()" v-if="isOpenBox">
                <img
                  src="../assets/img/back.png"
                  style="width: 20px; height: 24px; margin-right: 12px"
                  alt=""
                />
              </sapn> -->
              <div
                class="top-right-logo"
                @click="goIndex"
                :class="{ loginStatus: !loginfalse }"
              >
                <img src="../assets/img/logo.png" alt="" />
              </div>
              <BaseHeader ref="baseHeader" @goMenu="goMenu" @goBox="goBox" />
            </el-col>
            <el-col
              :class="['top-name', { 'top-name-have': loginfalse }]"
              class="el-col-6"
            >
              <!-- 语言配置区域 -->
              <div class="language" v-if="showNav" @click="changeLangEvent">
                <el-tooltip class="item" effect="dark" placement="bottom-start">
                  <div slot="content">{{ $t("setting.title") }}</div>
                  <div>
                    <img :src="languageList[language].url" alt="" />
                  </div>
                </el-tooltip>
              </div>
              <div
                class="top-right"
                v-if="loginfalse || !$store.state.loginStates"
                @click="showLogin"
              >
                <!-- {{ loginfalse }}---{{ !$store.state.loginStates }} -->
                <span>{{ $t("login.loginButton") }}</span>
                <!-- <span style="margin: 0 5px">/</span>
                <span @click="showReg">注册</span> -->
              </div>

              <!-- <div class="full" @click="changeLangEvent()">
                <span class="lan">{{ language }}</span>
                <h3 class="title">{{ $t("login.systemName") }}</h3>
              </div> -->
              <!-- <div
                class="buttons"
                @click="goexchange"
                v-if="!loginfalse && $store.state.loginStates && showNav"
              >
                钻石兑换金币
              </div>
              <div
                class="buttons"
                @click="chongzhi"
                v-if="!loginfalse && $store.state.loginStates && showNav"
              >
                立即充值
              </div> -->
              <div
                class="top-right1"
                :style="{ 'margin-right': !showNav ? '25%' : '' }"
                v-if="!loginfalse && $store.state.loginStates && showNav"
                @click="drawerFun"
              >
                <div class="mess">
                  <div class="mess-left">
                    <img :src="me.image" />
                    <div class="nickname">
                      {{ me.name }}
                    </div>
                  </div>
                  <!-- <div class="mess-right" title="查看->个人中心">
                    <span class="mess-span2">
                      <div>
                        <img src="../assets/img/money.png" />
                        <strong>{{ Number(money).toFixed(2) }}</strong>
                      </div>
                      <div>
                        <img src="../assets/img/masonry.png" />
                        <strong>{{ Number(masonry).toFixed(2) }}</strong>
                      </div>
                    </span>
                  </div> -->
                </div>
                <!-- <div class="top-right1-ico" v-if="!showNav">
                <img src="../assets/img/menu.png" />
              </div> -->
              </div>
              <div
                class="top-right2"
                v-if="!loginfalse && $store.state.loginStates && showNav"
              >
                <div
                  style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <div class="button-green" @click="goPay">
                    {{ Number(money).toFixed(2) }}
                    <span class="button button-plus"></span>
                  </div>
                  <!-- 退出登录 -->
                  <div
                    class="moblieGoout"
                    @click="nextLogin('logout')"
                    v-if="showNav"
                  ></div>
                </div>
              </div>
            </el-col>
            <!-- 手机端充值 -->
            <div
              class="mobile-userarea"
              v-if="!showNav && !loginfalse && $store.state.loginStates"
            >
              <div class="mobile-userarea__profil" @click="goProfile">
                <div class="userarea-wallet ng-binding">
                  {{ Number(money).toFixed(2) }}
                  <img
                    src="../assets/img/wallet.svg"
                    alt="Wallet"
                    class="userarea-wallet__icon"
                  />
                </div>
                <div class="userarea-nickname ng-binding">{{ me.name }}</div>
              </div>
              <div class="userarea-upbalance" @click="goPay">
                <img src="../assets/img/circled_plus_green.svg" alt="Add $" />
              </div>
            </div>
            <!-- 设置语言 -->
            <div class="language" v-if="!showNav" @click="changeLangEvent">
              <el-tooltip class="item" effect="dark" placement="bottom-start">
                <div slot="content">{{ $t("setting.title") }}</div>
                <div>
                  <img :src="languageList[language].url" alt="" />
                </div>
              </el-tooltip>
            </div>
            <div class="menu" @click="drawer = true">
              <img src="../assets/img/three-bars-horizontal-bold.svg" alt="" />
            </div>
          </div>
        </el-row>
      </div>
      <div class="maringtop75 backColor">
        <div class="bot-right">
          <!-- <div v-if="loading" class="el-loading-spinner">
            <i class="el-icon-loading"> </i>
            <p class="el-loading-text">正在加载中...</p>
          </div> -->

          <!-- 公告栏 -->
          <!-- <div class="advertisement" v-if="$route.path=='/Index'">
          <div class="laba">
            <img src="./img/laba.png" alt="">
          </div>
          <div class="span">
            <div class="wenzi" v-html="advertisement"></div>
          </div>
        </div> -->
          <keep-alive>
            <router-view
              v-if="$route.meta.keepAlive"
              @backAction="backAction"
              :openHongbao="openHongbao"
              :yidongmenu="yidongmenu"
              :showNav="showNav"
              :bannerLists="bannerList"
              :advertisements="advertisement"
            ></router-view>
          </keep-alive>
          <router-view
            v-if="!$route.meta.keepAlive"
            @backAction="backAction"
            :openHongbao="openHongbao"
            :yidongmenu="yidongmenu"
            :showNav="showNav"
            :bannerLists="bannerList"
            :advertisements="advertisement"
          ></router-view>
        </div>
      </div>
    </div>

    <!--注册盒子-->
    <div class="reg" v-if="regBox">
      <div class="content">
        <div class="left">
          <img src="./img/login-bg.png" alt="" />
          <img class="img-t" src="../assets/img/logo.png" />
        </div>
        <div class="right">
          <div class="reg-warp">
            <div class="btn-x" @click="hideReg">
              <span class="el-icon-close"></span>
            </div>
            <div class="reg-sel">
              <span
                :class="phoneregBox ? 'span1' : 'span2'"
                @click="phoneReg"
                style="margin-top: 20px"
                >账号注册</span
              >
              <!-- <span :class="emilregBox ? 'span1' : 'span2'" @click="emilReg"
            >邮箱注册</span
          > -->
            </div>
            <div class="reg-hint" v-if="regHint">{{ regHintText }}</div>
            <div class="input" v-if="phoneregBox">
              <el-input
                class="input1"
                v-model="phoneinput1"
                placeholder="请输入手机号码"
                prefix-icon="el-icon-user"
              ></el-input>
              <el-input
                class="input1"
                v-model="phoneinput2"
                placeholder="请输入密码"
                type="password"
                prefix-icon="el-icon-lock"
                how-password="true"
              ></el-input>
              <div class="input1-warp">
                <el-input
                  class="input1"
                  v-model="phoneinput3"
                  prefix-icon="el-icon-chat-dot-square"
                  placeholder="请输入验证码"
                ></el-input>
                <button
                  class="getCode"
                  @click="getphoneCode"
                  :disabled="!phonecodeState"
                >
                  <i v-if="loadingReg" class="el-icon-loading"></i>
                  {{ phonecodeState ? "获取验证码" : phonecodeTime + " s" }}
                </button>
              </div>
              <el-input
                v-if="inputCode"
                class="input1"
                v-model="phoneinput4"
                prefix-icon="el-icon-present"
                placeholder="请输入邀请码（选填）"
              ></el-input>
            </div>
            <div class="input" v-if="emilregBox">
              <el-input
                class="input1"
                v-model="emilinput1"
                placeholder="邮箱"
              ></el-input>
              <el-input
                class="input1"
                v-model="emilinput2"
                placeholder="设置密码"
                type="password"
              ></el-input>
              <div class="input1-warp">
                <el-input
                  class="input1"
                  v-model="emilinput3"
                  placeholder="验证码"
                ></el-input>
                <span class="getCode" @click="getemilCode">{{
                  emilcodeState ? "获取验证码" : emilcodeTime + " s"
                }}</span>
              </div>
              <el-input
                class="input1"
                v-model="emilinput4"
                placeholder="邀请码（没有可以不填）"
              ></el-input>
            </div>
            <div class="reg-btn ripple" @click="getLogin">
              <el-button type="success" class="btn-sub" @click="regBtn"
                >注册</el-button
              >
            </div>
            <div class="reg-deal">
              <el-checkbox v-model="Regchecked"
                ><p>本人已年满18周岁</p></el-checkbox
              >
            </div>
            <div class="reg-deal">
              <el-checkbox v-model="Regchecked1">
                <span>我接受</span
                ><strong @click="goAgreement">《用户协议》</strong
                ><span>和</span><strong @click="goPrivacy">《隐私条款》</strong>
              </el-checkbox>
            </div>
            <div class="go-login" style="margin-top: 20px">
              已有账号?<span style="color: #ff2171" @click="goLogin"
                >立即登录</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--登录盒子-->
    <!-- <div class="login" v-if="$store.state.loginState"> -->
    <div class="login" v-if="false">
      <!-- <div class="reg-warp"> -->
      <div class="content">
        <div class="left">
          <img src="./img/login-bg.png" alt="" />
          <img class="img-t" src="../assets/img/logo.png" />
        </div>
        <div class="right">
          <div class="reg-warp" style="margin-top: 30px">
            <div class="btn-x" @click="hideLogin">
              <span class="el-icon-close"></span>
            </div>
            <div class="login-title">登录</div>
            <div class="reg-hint" v-if="loginHintState">
              {{ loginHintText }}
            </div>
            <div class="input">
              <el-input
                class="input1"
                v-model="account"
                placeholder="请输入手机号码或邮箱"
                prefix-icon="el-icon-user"
              ></el-input>
              <el-input
                class="input1"
                v-model="password"
                placeholder="请输入密码"
                type="password"
                show-password="true"
                prefix-icon="el-icon-lock"
              ></el-input>
            </div>
            <div class="login-pass" style="margin-top: 20px">
              <div class="go-login" @click="goReg">去注册>></div>
              <div class=""><span @click="goForget">忘记密码?</span></div>
            </div>
            <div class="reg-deals" style="margin-top: 30px">
              <el-checkbox class="login-pass1" v-model="loginChecked"
                ><p>记住登录</p></el-checkbox
              >
              <el-checkbox
                class="login-pass1"
                v-model="loginChecked1"
                style="margin-top: 15px"
              >
                <p>
                  我接受<strong @click="goAgreement">《用户协议》</strong
                  >和<strong @click="goPrivacy">《隐私条款》</strong>
                </p>
              </el-checkbox>
            </div>
            <div
              class="reg-btn ripple"
              id="ripple"
              @click="getLogin"
              @mousedown="suoxiaos"
              @mouseup="fangda"
            >
              <el-button
                type="success"
                class="btn-sub"
                :class="{ loging, suoxiao: suoxiao }"
                >登录</el-button
              >
            </div>
          </div>
        </div>
      </div>

      <!--  <div class="login-rest">
          <span>—— 其他方式登录 ——</span>
          <img src="../assets/img/steam.png" @click="login_steam"/>
        </div>
        <div class="login-hint">
          <span>中国大陆用户访问 Steam 需要借助智能加速</span>
          <span>器 ( 否则库存功能和饰品充值功能会无法使用)</span>
        </div>-->
    </div>
    <!-- </div> -->

    <!-- 忘记密码1 -->
    <div class="reg" v-if="forgetBox">
      <div class="content">
        <div class="left">
          <img src="./img/login-bg.png" alt="" />
          <img class="img-t" src="../assets/img/logo.png" />
        </div>
        <div class="right">
          <div class="reg-warp">
            <div class="btn-x" @click="hideForgetBox">
              <span class="el-icon-close"></span>
            </div>
            <div class="reg-sel">
              <span style="margin-top: 30px">忘记密码</span>
            </div>
            <div class="reg-hint" v-if="forgetHint">{{ forgetHintText }}</div>
            <div class="input">
              <el-input
                class="input1"
                v-model="forgetNum"
                prefix-icon="el-icon-user"
                placeholder="手机号码或邮箱"
              ></el-input>
              <el-input
                class="input1"
                v-model="forgetPass"
                prefix-icon="el-icon-lock"
                placeholder="重置密码"
                type="password"
              ></el-input>
              <div class="input1-warp">
                <el-input
                  class="input1"
                  v-model="forgetCode"
                  placeholder="验证码"
                  prefix-icon="el-icon-chat-dot-square"
                ></el-input>
                <button
                  class="getCode"
                  @click="getForgetCode"
                  :disabled="!forgetcodeState"
                >
                  {{ forgetcodeState ? "获取验证码" : forgetcodeTime + " s" }}
                </button>
              </div>
            </div>
            <div class="reg-btn">
              <el-button type="success" class="btn-sub" @click="reset"
                >重置密码</el-button
              >
            </div>
            <div
              class="go-login"
              @click="goLogin1"
              style="
                margin-top: 20px;
                margin-left: 50%;
                width: 100px;
                transform: translate(-50%);
                text-align: center;
              "
            >
              返回登录
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--登录完成右边导航-->
    <transition name="slide-fade">
      <div class="boxss" v-if="drawer">
        <div class="rightBoxs" v-if="drawer">
          <div class="cancel">
            <span @click="drawer = false"
              ><img src="./img/cancel.png" alt=""
            /></span>
          </div>

          <!-- 登录按钮 -->
          <!-- 登录成功显示头像 -->
          <div
            class="moblieAvatar"
            v-if="!loginfalse && $store.state.loginStates"
            @click="$router.push('/Profile'), (drawer = false)"
          >
            <img :src="me.image" alt="" />
            <!-- 昵称 -->
            <div class="nickname">{{ me.name }}</div>
          </div>
          <div
            class="login"
            v-if="loginfalse || !$store.state.loginStates"
            @click="mobileLogin"
          >
            {{ $t("login.loginButton") }}
          </div>
          <div class="mobileMenu">
            <!-- 钱的数量 -->
            <div
              style="display: flex; justify-content: center"
              v-if="!loginfalse && $store.state.loginStates"
            >
              <div class="button-green" @click="goPay">
                {{ Number(money).toFixed(2) }}
                <span class="button button-plus"></span>
              </div>
              <!-- 退出登录 -->
              <div class="moblieGoout" @click="nextLogin('logout')"></div>
            </div>
            <div
              class="menu-item"
              v-for="(item, index) in mobileMenu"
              :key="item.id"
              @click="goNavMenu(item.id, item.path)"
            >
              <img :src="item.img2" alt="" />
              <div class="menu-title">
                {{ item.value }}
              </div>
            </div>
            <div class="m-giveaway">
              <div class="menu-item" @click="showGiveaway = !showGiveaway">
                <img src="@/assets/img/giveaway.svg" alt="" />
                <div class="menu-title">
                  {{ $t("赠品") }}
                </div>
              </div>
              <ul class="sub giveaway-menu" v-show="showGiveaway">
                <li
                  v-for="(item, index) in giveawayList"
                  :key="index"
                  @click="goBox(item.id, 1)"
                  class="menu-item"
                >
                  <div></div>
                  {{ item.name }}
                </li>
                <li
                  v-for="(item, index) in boxInfo"
                  :key="'timebox' + index + item.id"
                  @click="goBox(item.id, 2)"
                  class="menu-item"
                >
                  <div></div>
                  {{ item.title }}
                </li>
              </ul>
            </div>
          </div>
          <!-- <div class="user">
            <div class="chognzhi" @click="goPay">充值+</div>
            <div
              class="chognzhi"
              @click="goexchange"
              style="background-color: #17b4ed"
            >
              货币转换
            </div>
            <div class="admin">
              <div>
                <span>{{ me.name }}</span>
              </div>
              <span @click="goAll('/MeYiDong')"><img :src="me.image" /></span>
            </div>
          </div>
          <div class="titleGo" @click="goActive">
            <img src="./img/title1.png" alt="" />
          </div>
          <div class="list">
            <div class="title">游戏</div>
            <div class="conts">
              <div class="cont">
                <div>
                  <span class="img" @click="goAll('/Tai')"
                    ><img src="./img/tai.png" alt=""
                  /></span>
                  <span>汰换</span>
                </div>
              </div>
              <div class="cont">
                <div>
                  <span class="img" @click="goAll('/Arena')"
                    ><img src="./img/roll.png" alt=""
                  /></span>
                  <span>ROLL房</span>
                </div>
              </div>
              <div class="cont">
                <div>
                  <span class="img" @click="goAll('/OrnamentOpen')"
                    ><img src="./img/meng.png" alt=""
                  /></span>
                  <span>追梦</span>
                </div>
              </div>
              <div class="cont">
                <div>
                  <span class="img" @click="goAll('/Lucky')"
                    ><img src="./img/fight.png" alt=""
                  /></span>
                  <span>竞技场</span>
                </div>
              </div>
            </div>
          </div>
          <div class="list">
            <div class="title">特惠</div>
            <div class="conts">
              <div class="cont">
                <div>
                  <span class="img" @click="goAll('/Activity')"
                    ><img src="./img/mianfei.png" alt=""
                  /></span>
                  <span>免费</span>
                </div>
              </div>
              <div class="cont">
                <div>
                  <span
                    class="img"
                    @click="
                      $router.push('/hongbao');
                      drawer = false;
                    "
                    ><img src="./img/chongzhi2.png" alt=""
                  /></span>
                  <span>红包</span>
                </div>
              </div>
              <div class="cont">
                <div>
                  <span class="img" @click="goAll('/CDK')"
                    ><img src="./img/cdk.png" alt=""
                  /></span>
                  <span>CDK</span>
                </div>
              </div>
              <div class="cont">
                <div>
                  <span class="img" @click="goAll('/Vip')"
                    ><img src="./img/vip.png" alt=""
                  /></span>
                  <span>福利</span>
                </div>
              </div>
            </div>
          </div>
          <div class="list">
            <div class="title">其他</div>
            <div class="conts">
              <div class="cont">
                <div>
                  <span class="img" @click="goAll('/Dota')"
                    ><img src="./img/myBox.png" alt=""
                  /></span>
                  <span>饰品背包</span>
                </div>
              </div>
              <div class="cont">
                <div>
                  <span class="img" @click="qqGroup"
                    ><img src="./img/invite.png" alt=""
                  /></span>
                  <span>群聊</span>
                </div>
              </div>
              <div class="cont">
                <div>
                  <span class="img" @click="goAllsss('/kefu')"
                    ><img src="./img/kefu.png" alt=""
                  /></span>
                  <span>在线客服</span>
                </div>
              </div>
              <div class="cont">
                <div>
                  <span class="img" @click="qqsGroup()"
                    ><img src="./img/kefu2.png" alt=""
                  /></span>
                  <span>客服</span>
                </div>
              </div>
            </div>
          </div>
          <div class="goout">
            <span class="el-icon-switch-button s1"></span>
            <span @click="nextLogin">退出登录</span>
          </div> -->
        </div>
      </div>
    </transition>

    <el-drawer
      title="我是标题"
      :visible.sync="rightBarDrawer"
      direction="rtl"
      :size="quhuiSize"
      :before-close="handleClose"
      class="quhui-box"
    >
      <div class="give-box">
        <ul v-for="(item, index) in tableData" :key="index">
          <li>
            <div class="give-true" v-if="item.steamNameAnother">
              <div class="give-left">
                <div class="give-img">
                  <img :src="item.steamAvatarAnother" />
                </div>
                <div class="give-text">
                  <span>{{ item.steamNameAnother }}</span>
                  <span>加入steam时间：{{ item.steamCreateTimeAnother }}</span>
                </div>
              </div>
              <div
                class="give-right"
                @click="takeOffer(item.steam_receive_url)"
              >
                接受报价
              </div>
            </div>
            <div class="give-false" v-if="!item.steamNameAnother">
              <div class="give-false-left">
                <img :src="item.img" />
                <span>{{ item.name }}</span>
              </div>
              <div class="give-false-right">待发货</div>
            </div>
          </li>
        </ul>
      </div>
      <div class="give-box1" v-if="tableData.length == 0">没有进行中的报价</div>
      <div class="func">
        <div class="hidden" @click="funcHidden">全部隐藏</div>
        <div class="refresh" @click="funcRefresh">
          <img src="../assets/img/refresh.svg" />
        </div>
      </div>
    </el-drawer>

    <div class="openhong" v-if="hongbaoState1">
      <div class="close" @click="hongbaoState1 = false">
        <img src="./img/cancel.png" alt="" />
      </div>
      <div class="title">口令红包</div>
      <div class="topss">
        <div class="all">
          <input type="text" v-model="hongbaoText" />
          <span @click="getHongbao">兑换</span>
        </div>
      </div>
      <div class="cont">
        <div class="item" v-for="item in hongbaoListData" :key="item.id">
          <div class="xian"></div>
          <div class="shang">
            <span class="left">{{ item.name }}</span>
            <span class="right">总数量：{{ item.total_num }}</span>
          </div>
          <div class="xia">
            <div class="left">
              <img src="./img/2.gif" alt="" />
              <div class="content">
                <span class="t1">{{ item.describe }}</span>
                <!-- <span>结束时间：2024-07-30 23:59:59</span> -->
                <span>结束时间：{{ item.end_time }}</span>
              </div>
            </div>
            <div class="right">
              <span v-if="item.is_qu == 0" @click="gotHongbao(item.id)"
                >领取</span
              >
              <span v-if="item.is_qu == 1">已领取</span>
              <span v-if="item.is_qu == 2">不可领取</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="win-box" v-if="hongbaoState2">
      <div class="win">
        <div class="win-conm">
          <div class="win-tips">恭喜获得</div>
        </div>
        <div
          class="win-conm"
          style="
            color: white;
            display: flex;
            align-items: center;
            flex-flow: row;
          "
        >
          <div>{{ my_hongbao }}</div>
          <img src="./img/money.png" style="width: 24px" />
        </div>
        <div class="win-span2" @click="hongbaoState2 = false">放入背包</div>
      </div>
    </div>

    <!-- <BaseSlider
      @openHongbao="openHongbao"
      :showNav="showNav"
      @showBox="showFuliBoxs"
    /> -->
    <div class="pass" v-if="passState">
      <div class="pass-box" :class="{ pc: showNav }">
        <span class="pass-hint" @click="hidePass"
          ><i class="el-icon-close"></i
        ></span>
        <div class="pass-title">钻石转化</div>
        <div class="input">
          <input type="text" v-model="coin" />
          <span class="all" @click="allChange"> 全部转化 </span>
        </div>
        <p><span>*</span>钻石转换不可逆转</p>
        <div class="pass-btn">
          <el-button class="cancel" type="warning" plain @click="hidePass"
            >取消</el-button
          >
          <el-button class="submit" type="warning" @click="exchange"
            >确认</el-button
          >
        </div>
      </div>
    </div>
    <!-- 福利中心弹窗 -->
    <div class="fuli" v-if="showFuliBox">
      <div class="bg">
        <div class="cancel" @click="showFuliBox = false">
          <img src="./img/cancel.png" alt="" />
        </div>
        <div class="cont">
          <div class="left">
            <div
              class="day"
              @click="showRight = 1"
              :class="showRight == 1 ? 'isActive' : ''"
            >
              <img src="./img/day.png" alt="" />
              <span>每日签到</span>
            </div>
            <div
              class="quest"
              @click="showRight = 2"
              :class="showRight == 2 ? 'isActive' : ''"
            >
              <img src="./img/quest.png" alt="" />
              <span>任务中心</span>
            </div>
            <!-- <div
              class="quest"
              @click="showRight = 3"
              :class="showRight == 3 ? 'isActive' : ''"
            >
              <img src="./img/chongzhi.png" alt="" />
              <span>充值补给</span>
            </div> -->
          </div>
          <div class="right">
            <div class="day" v-if="showRight == 1">
              <!-- 登陆签到 -->
              <div class="loginQ">
                <div class="title">
                  <div>
                    <span style="color: #ffc600; font-weight: 600"
                      >登陆签到</span
                    >
                    <span style="margin: 0 7px">|</span>
                    <span
                      >已签到<span style="margin: 0 7px; color: #ffc600">{{
                        qusign
                      }}</span
                      >天</span
                    >
                  </div>
                  <div class="rightT">
                    <span v-if="sign == 0" @click="goSign(1)">签到</span>
                    <span v-else>已签到</span>
                  </div>
                </div>
                <!-- 签到按钮 -->
                <div class="lists">
                  <div
                    class="item"
                    v-for="(item, index) in 7"
                    :key="index"
                    :class="[
                      item <= qusign ? 'itemActive' : '',
                      index == qusign && sign == 0 ? 'keActive' : '',
                    ]"
                  >
                    <img
                      src="./img/dui.png"
                      alt=""
                      v-if="item <= qusign"
                      style="width: 30px; height: auto"
                    />
                    <span class="cont1" v-else
                      ><span
                        style="
                          font-size: 24px;
                          margin-bottom: -7px;
                          font-weight: 700;
                        "
                        >{{ item }}</span
                      ><span>Day</span></span
                    >
                  </div>
                </div>
                <!-- 下方描述文字 -->
                <div
                  class="description"
                  style="color: #717382; font-size: 14px"
                >
                  连续7天登陆签到，第7天最高可获取红包福利（中断任何一天将充值回第一天）
                </div>
              </div>
              <!-- 充值签到 -->
              <div class="chargeQ" style="margin-top: 50px">
                <div class="title">
                  <div>
                    <span style="color: #ffc600; font-weight: 600"
                      >充值签到</span
                    >
                    <span style="margin: 0 7px">|</span>
                    <span
                      >已签到<span style="margin: 0 7px; color: #ffc600">{{
                        qusign_recharge
                      }}</span
                      >天</span
                    >
                  </div>
                  <div class="rightT">
                    <span v-if="sign_recharge == 0" @click="goSign(2)"
                      >签到</span
                    >
                    <span v-else>已签到</span>
                  </div>
                </div>
                <!-- 签到按钮 -->
                <div class="lists">
                  <div
                    class="item"
                    v-for="(item, index) in 7"
                    :key="index"
                    :class="[
                      index + 1 <= qusign_recharge ? 'itemActive' : '',
                      index == qusign_recharge && sign_recharge == 0
                        ? 'keActive'
                        : '',
                    ]"
                  >
                    <img
                      src="./img/dui.png"
                      alt=""
                      v-if="item <= qusign_recharge"
                      style="width: 30px; height: auto"
                    />
                    <span class="cont1" v-else
                      ><span
                        style="
                          font-size: 24px;
                          margin-bottom: -7px;
                          font-weight: 700;
                        "
                        >{{ item }}</span
                      ><span>Day</span></span
                    >
                  </div>
                </div>
                <!-- 下方描述文字 -->
                <div
                  class="description"
                  style="color: #717382; font-size: 14px"
                >
                  连续7天登陆签到，第7天最高可获取红包福利（中断任何一天将充值回第一天）
                </div>
              </div>
              <div class="win-box" v-if="winMoney">
                <div class="win">
                  <div class="win-conm">
                    <div class="win-tips">恭喜获得</div>
                  </div>
                  <div
                    class="win-conm"
                    style="
                      color: white;
                      display: flex;
                      align-items: center;
                      flex-flow: row;
                    "
                  >
                    <div>{{ winMoney }}</div>
                    <img src="./img/money.png" style="width: 24px" />
                  </div>
                  <div class="win-span2" @click="winMoney = null">放入背包</div>
                  <!-- winState = false;   上面一行的代码 -->
                </div>
              </div>
            </div>
            <div class="quest" v-if="showRight == 2">
              <div class="topNav">
                <div
                  class="box"
                  @click="goNav(1)"
                  :class="showBox == 1 ? 'boxActive' : ''"
                >
                  <img src="./img/box.png" alt="" />
                  <span>宝箱任务</span>
                </div>
                <div
                  class="box"
                  @click="goNav(4)"
                  :class="showBox == 4 ? 'boxActive' : ''"
                >
                  <img src="./img/meng.png" alt="" />
                  <span>追梦任务</span>
                </div>
                <div
                  class="box"
                  @click="goNav(2)"
                  :class="showBox == 2 ? 'boxActive' : ''"
                >
                  <img src="./img/fight.png" alt="" />
                  <span>对战任务</span>
                </div>
                <div
                  class="box"
                  @click="goNav(3)"
                  :class="showBox == 3 ? 'boxActive' : ''"
                >
                  <img src="./img/kui.png" alt="" />
                  <span>亏损补偿</span>
                </div>
              </div>
              <div class="main">
                <!-- 宝箱任务 -->
                <div class="box1" v-if="showBox == 1">
                  <div
                    class="boxs"
                    v-for="(item, index) in boxList"
                    :key="index"
                  >
                    <div class="xian"></div>
                    <div class="bleft">
                      <div class="c1">
                        每日消耗{{ item.lim }}钻石（<span
                          style="color: #fbc600"
                          >{{ item.ls }}</span
                        >/<span>{{ item.lim }}</span
                        >）
                      </div>
                      <div class="c2">
                        <div class="img">
                          <span>任务奖励：</span>
                          <!-- <img src="./img/money.png" alt="" /> -->
                        </div>
                        <div class="img">
                          <span>最高{{ item.max }}</span>
                          <img src="./img/money.png" alt="" />
                        </div>
                      </div>
                    </div>
                    <div class="bright" @click="consumeRecharge1(item, index)">
                      <span
                        v-if="[1].includes(item.status)"
                        style="font-weight: bold"
                        >已领取</span
                      >
                      <span v-else style="font-weight: bold">{{
                        item.status == 0 ? "GO" : "领取"
                      }}</span>
                    </div>
                  </div>
                </div>
                <!-- 追梦任务 -->
                <div class="box1" v-if="showBox == 4">
                  <div
                    class="boxs"
                    v-for="(item, index) in mengList"
                    :key="index"
                  >
                    <div class="xian"></div>
                    <div class="bleft">
                      <div class="c1">
                        每日消耗{{ item.lim }}钻石（<span
                          style="color: #fbc600"
                          >{{ item.ls }}</span
                        >/<span>{{ item.lim }}</span
                        >）
                      </div>
                      <div class="c2">
                        <div class="img">
                          <span>任务奖励：</span>
                          <!-- <img src="./img/money.png" alt="" /> -->
                        </div>
                        <div class="img">
                          <span>最高{{ item.max }}</span>
                          <img src="./img/money.png" alt="" />
                        </div>
                      </div>
                    </div>
                    <div class="bright" @click="consumeRecharge6(item, index)">
                      <span
                        v-if="[1].includes(item.status)"
                        style="font-weight: bold"
                        >已领取</span
                      >
                      <span v-else style="font-weight: bold">{{
                        item.status == 0 ? "GO" : "领取"
                      }}</span>
                    </div>
                  </div>
                </div>
                <!-- 对战任务 -->
                <div class="box2" v-if="showBox == 2">
                  <div
                    class="boxs"
                    v-for="(item, index) in fightList"
                    :key="index"
                  >
                    <div class="xian"></div>
                    <div class="bleft">
                      <div class="c1">
                        每日消耗{{ item.lim }}钻石（<span
                          style="color: #fbc600"
                          >{{ item.ls }}</span
                        >/<span>{{ item.lim }}</span
                        >）
                      </div>
                      <div class="c2">
                        <!-- 保底{{ item.min }} ， -->
                        <div class="img">
                          <span>任务奖励：</span>
                          <!-- <img src="./img/money.png" alt="" /> -->
                        </div>
                        <div class="img">
                          <span>最高{{ item.max }}</span>
                          <img src="./img/money.png" alt="" />
                        </div>
                      </div>
                    </div>
                    <div class="bright">
                      <span
                        v-if="[1].includes(item.status)"
                        style="font-weight: bold"
                        >已领取</span
                      >
                      <span
                        @click="consumeRecharge2(item, index)"
                        v-else
                        style="font-weight: bold"
                        >{{ item.status == 0 ? "GO" : "领取" }}</span
                      >
                    </div>
                  </div>
                </div>
                <!-- 亏损补偿 -->
                <div class="box3" v-if="showBox == 3">
                  <div
                    class="boxs"
                    v-for="(item, index) in lossList"
                    :key="index"
                  >
                    <div class="xian"></div>
                    <div class="bleft">
                      <div class="c1">亏损到达{{ item.upgrade }}钻石</div>
                      <div class="c2">
                        <div class="img">
                          <span>当前亏损{{ item.loss }} ，</span>
                          <img src="./img/money.png" alt="" />
                        </div>
                        <div class="img">
                          <span>补偿比列{{ item.reward }}%</span>
                          <img src="./img/money.png" alt="" />
                        </div>
                      </div>
                    </div>
                    <div class="bright">
                      <span v-if="item.status == 2" style="font-weight: bold"
                        >已领取</span
                      >
                      <span
                        v-else-if="item.status == 1"
                        @click="consumeRecharge3(item, index)"
                        style="font-weight: bold"
                        >领取</span
                      >
                      <span v-else style="font-weight: bold">不可领取</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="chongzhi" v-if="showRight == 3">充值补给</div>
          </div>
        </div>
      </div>
      <!-- 领取成功弹窗 -->
      <div class="win-box" v-if="boxmoney">
        <div class="win">
          <div
            class="win-conm"
            style="
              color: white;
              display: flex;
              align-items: center;
              flex-flow: row;
              justify-content: center;
            "
          >
            <div class="win-conm">
              <div class="win-tips">恭喜获得</div>
            </div>
            <div>{{ boxmoney }}</div>
            <img src="@/assets/img/money.png" style="width: 24px" />
          </div>
          <div class="win-span2" @click="boxmoney = null">放入背包</div>
        </div>
      </div>
    </div>
    <!-- 设置语言弹框 -->
    <el-dialog :visible.sync="dialogVisible" :show-close="false" width="30%">
      <div class="language-box">
        <div class="language-top">{{ $t("setting.title") }}</div>
        <div class="language-content">
          <div class="content-title">{{ $t("setting.language") }}</div>
          <div class="content-list">
            <div
              class="item"
              :class="{ active: key == languageName }"
              v-for="(item, key) in languageList"
              @click="changeLanguage(key)"
            >
              <img :src="item.url" alt="" />
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="language-bottom">
          <!-- 保存按钮 -->
          <div class="save" @click="saveLanguage">
            {{ $t("setting.button") }}
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 支付弹窗 -->
    <el-dialog
      class="payment"
      :visible.sync="dialogPayment"
      :show-close="false"
      :width="showNav ? '60%' : '100%'"
    >
      <div class="payment-box" v-if="payList != null">
        <div class="tabs">
          <div
            class="item"
            :class="item.id == payType ? 'selected' : ''"
            v-for="item in payList"
            :key="item.id"
            @click="ToggleEvent(item)"
          >
            {{ item.showname }}
          </div>
          <div class="close" @click="dialogPayment = false"></div>
        </div>
        <div class="payment-content">
          <div class="payment-list">
            <div
              class="item"
              v-for="item1 in filterPayList()"
              :key="item1.id"
              @click="selectPay(item1)"
              :class="{ selected: item1.id == moneyId }"
            >
              <!-- {{ item1 }} -->
              <div class="symbol">$</div>
              <div class="money">{{ item1.money }}</div>
            </div>
          </div>
          <!-- 联系客服 -->
        </div>
        <span class="payment-services" @click="contactService">{{ $t("联系客服") }}</span>
        <div class="payment-bottom">
          <div class="payment-buy" @click="recharge">{{ $t("购买") }}</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Clickoutside from "element-ui/src/utils/clickoutside";
import myslide from "../components/my_slide1.vue";
import leftSlide from "../components/leftSlide.vue";

import { BaseHeader, BaseSlider } from "@/views/common";
import SlotMachine from "./slot-machine/slot-machine.vue";
import Utils from "./../assets/js/util.js";
export default {
  name: "Home",
  components: {
    BaseHeader,
    BaseSlider,
    myslide,
    SlotMachine,
    leftSlide,
  },
  directives: {
    Clickoutside,
  },
  data() {
    return {
      showGiveaway: false,
      rechargeMoney: 0,
      mode: "weixin",
      mode1: "",
      payList: null,
      currentType: 0,
      dialogVisible: false,
      dialogPayment: false,
      hongbaoListData: [],
      qqqun_url: "",
      qq_kefu: "",
      kefu_url: "",
      // 公告
      advertisement: "",
      // 完成7天签到奖励
      winMoney: 0,
      // 登录是否签到 1是 0否
      sign: 0,
      // 充值是否签到 1是 0否
      sign_recharge: 0,
      // 登录签到天数
      qusign: 0,
      // 充值签到天数
      qusign_recharge: 0,
      boxmoney: null,
      lossList: [],
      fightList: [],
      boxList: [],
      mengList: [],
      showFuliBox: false,
      showBox: 1,
      showRight: 1,
      suoxiao: false,
      hongbaoName: "",
      loadingReg: false,
      passState: false,
      hongbaoData: [],
      my_hongbao: "",
      hongbaoid: "",
      coin: 0,
      masonry: "",
      quhuiSize: "680px",
      windowWidth: document.body.clientWidth,
      showMenuState: false,
      hongbaoText: "",
      hongbaoState1: false,
      hongbaoState2: false,
      tableData: [],
      rightBarDrawer: false,
      rightBar: true,
      // loading: false,
      forgetHint: false,
      forgetHintText: "",
      forgetBox: false,
      forgetNum: "",
      forgetPass: "",
      forgetCode: "",
      forgetcodeState: true,
      forgetcodeTime: 60,
      forgettimer: null,

      loginHintState: false,
      loginHintText: "",
      regHint: false,
      regHintText: "",
      Index: "Index",
      name: "盲盒大厅",
      regBox: false,
      phoneregBox: true,
      emilregBox: false,
      phonecodeState: true,
      phonecodeTime: 60,
      phonetimer: null,
      emilcodeState: true,
      emilcodeTime: 60,
      emiltimer: null,
      account: "",
      password: "",
      phoneinput1: "",
      phoneinput2: "",
      phoneinput3: "",
      phoneinput4: "",
      emilinput1: "",
      emilinput2: "",
      emilinput3: "",
      emilinput4: "",
      money: "",
      Regchecked: false,
      isOpenBox: false,
      Regchecked1: false,

      me: {},
      code: "",

      loginfalse: "true", //登录状态
      loginChecked: false, //登录
      loginChecked1: false,
      loginBox: false,
      drawer: false, //右侧导航状态
      // language: false, //语言
      // languageImg: require("../assets/img/13mdpi.png"),
      // languageText: "简体中文",
      // languageList: [
      //   {
      //     url: require("../assets/img/13mdpi.png"),
      //     name: "简体中文",
      //   },
      //   {
      //     url: require("../assets/img/yinguo.png"),
      //     name: "English",
      //   },
      // ],
      languageList: {
        zh: {
          name: "中文",
          url: require("../assets/img/language/zh.png"),
        },
        en: {
          name: "English",
          url: require("../assets/img/language/en.png"),
        },
      },
      language: localStorage.getItem("lang") || "zh",
      // 选择的语言
      languageName: localStorage.getItem("lang") || "zh",
      menu: [
        {
          id: 1,
          img1: require("../assets/img/nav2/b1.png"),
          img2: require("../assets/img/nav2/b11.png"),
          selState: true,
          path: "Index",
          child: ["Index", "Openbox"],
          value: "首页",
        },
        {
          id: 2,
          img1: require("../assets/img/nav2/b2.png"),
          img2: require("../assets/img/nav2/b22.png"),
          selState: false,
          path: "Lucky",
          child: ["Lucky", "LuckyRoom", "LuckyRule", "LuckyHistory"],
          value: "盲盒对战",
        },
        {
          id: 3,
          img1: require("../assets/img/nav2/b3.png"),
          img2: require("../assets/img/nav2/b33.png"),
          selState: false,
          path: "Arena",
          child: ["Arena", "ArenaRoom"],
          value: "ROLL房",
        },
        {
          id: 4,
          img1: require("../assets/img/nav2/b4.png"),
          img2: require("../assets/img/nav2/b44.png"),
          selState: false,
          path: "Ornament",
          child: [
            "Ornament",
            "OrnamentOpen",
            "OrnamentHistory",
            "OrnamentHistory2",
          ],
          value: "幸运饰品",
        },
        {
          id: 5,
          img1: require("../assets/img/nav2/b5.png"),
          img2: require("../assets/img/nav2/b55.png"),
          selState: false,
          path: "Roll",
          child: ["Roll"],
          value: "饰品商城",
        },
        {
          id: 6,
          img1: require("@/assets/img/nav2/b6.png"),
          img2: require("@/assets/img/nav2/b66.png"),
          selState: false,
          path: "task-center",
          child: ["task-center"],
          value: "福利中心",
        },
        {
          id: 8,
          img1: require("../assets/img/nav2/b6.png"),
          img2: require("../assets/img/nav2/b66.png"),
          selState: false,
          path: "Spread",
          child: ["Spread"],
          value: "活动推广",
        },
        {
          id: 7,
          img1: require("../assets/img/nav2/b8.png"),
          img2: require("../assets/img/nav2/b88.png"),
          selState: false,
          path: "Dota",
          child: ["Dota"],
          value: "饰品背包",
        },
        // {
        //   id: 8,
        //   img1: require("../assets/img/nav2/b7.png"),
        //   img2: require("../assets/img/nav2/b77.png"),
        //   selState: false,
        //   path: "Payment",
        //   child: ["Payment"],
        //   value: '充值'
        // },
      ],
      yidongmenu: [
        {
          id: 3,
          img1: require("../assets/img/tabbar/fight2.png"),
          img2: require("../assets/img/tabbar/fight1.png"),
          // img1: require("../assets/img/tabbar/classify_sel.png"),
          // img2: require("../assets/img/tabbar/classify.png"),
          selState: false,
          path: "Lucky",
          child: ["Lucky", "LuckyRoom", "LuckyRule", "LuckyHistory"],
          value: "竞技场",
        },

        {
          id: 4,
          img1: require("../assets/img/tabbar/shangcheng2.png"),
          img2: require("../assets/img/tabbar/shangcheng1.png"),
          // img1: require("../assets/img/tabbar/exchange_sel.png"),
          // img2: require("../assets/img/tabbar/exchange.png"),
          selState: false,
          path: "Roll",
          child: ["Roll"],
          value: "商城",
        },

        {
          id: 1,
          img1: require("../assets/img/tabbar/box2.png"),
          img2: require("../assets/img/tabbar/box1.png"),
          // img1: require("../assets/img/tabbar/home_sel.png"),
          // img2: require("../assets/img/tabbar/home.png"),
          selState: true,
          path: "Index",
          child: ["Index", "Openbox"],
          // value: "首页",
          value: "盲盒",
        },

        {
          id: 2,
          img1: require("../assets/img/tabbar/zhuimeng2.png"),
          img2: require("../assets/img/tabbar/zhuimeng1.png"),
          // img1: require("../assets/img/tabbar/percentage_sel.png"),
          // img2: require("../assets/img/tabbar/percentage.png"),
          selState: false,
          path: "OrnamentOpen",
          child: [
            "Ornament",
            "OrnamentOpen",
            "OrnamentHistory",
            "OrnamentHistory2",
          ],
          value: "追梦",
        },
        // {
        //   id: 5,
        //   img1: require("../assets/img/nav2/b8.png"),
        //   img2: require("../assets/img/nav2/b88.png"),
        //   selState: false,
        //   path: "Dota",
        //   child: ["Dota"],
        //   value: "背包",
        // },
        // {
        //   id: 6,
        //   img1: require("../assets/img/tabbar/476a5dc1-0b21-4d4e-83b3-824812ac9ab9.png"),
        //   img2: require("../assets/img/tabbar/476a5dc1-0b21-4d4e-83b3-824812ac9ab9.png"),
        //   selState: false,
        //   path: "null",
        //   child: ["null"],
        //   value: "口令",
        // },
        {
          id: 99,
          img1: require("../assets/img/tabbar/user2.png"),
          img2: require("../assets/img/tabbar/user1.png"),
          // img1: require("../assets/img/tabbar/my_sel.png"),
          // img2: require("../assets/img/tabbar/my.png"),
          selState: false,
          path: "MeYiDong",
          child: ["Mess"],
          value: "个人中心",
        },
        // {
        //   id: 7,
        //   img1: require("../assets/img/nav2/b6.png"),
        //   img2: require("../assets/img/nav2/b66.png"),
        //   selState: false,
        //   path: "Spread",
        //   child: ["Spread"],
        //   value: "推广",
        // },
        // {
        //   id: 8,
        //   img1: require("../assets/img/nav2/fuli66.png"),
        //   img2: require("../assets/img/nav2/fuli.png"),
        //   selState: false,
        //   path: "Vip",
        //   child: ["Vip"],
        //   value: "福利",
        // },
      ],
      showNav: true,
      code: "",
      inputCode: true,
      giveBillie: 0,
      num: 0,
      money: 0,
      loging: "",
      loginReturn: [],
      scrollTop: 0,
      bannerList: [],
      mobileMenu: [
        {
          id: 1,
          img1: require("@/assets/img/nav2/box2.png"),
          img2: require("@/assets/img/nav2/box.png"),
          selState: true,
          path: "Index",
          paths: "Index",
          child: ["Index", "Openbox"],
          value: this.$t("menu.Index"),
        },
        {
          id: 5,
          img1: require("@/assets/img/nav2/store2.png"),
          img2: require("@/assets/img/nav2/store.png"),
          selState: false,
          path: "Roll",
          paths: "Roll",
          child: ["Roll"],
          value: this.$t("menu.Roll"),
        },
        {
          id: 8,
          img1: require("@/assets/img/nav2/taihuan2.png"),
          img2: require("@/assets/img/nav2/taihuan.png"),
          selState: false,
          path: "upgrader",
          paths: "upgrader",
          child: ["upgrader", "Openbox"],
          value: this.$t("升级"),
        },
        {
          id: 4,
          img1: require("@/assets/img/nav2/lucky2.png"),
          img2: require("@/assets/img/nav2/lucky.png"),
          selState: false,
          path: "LuckySkins",
          paths: "LuckySkins",
          child: [
            "Ornament",
            "LuckySkins",
            "OrnamentHistory",
            "OrnamentHistory2",
          ],
          value: this.$t("menu.OrnamentOpen"),
        },
        //   {
        //     id: 2,
        //     img1: require("@/assets/img/nav2/battle2.png"),
        //     img2: require("@/assets/img/nav2/battle.png"),
        //     selState: false,
        //     path: "Lucky",
        //     paths: "Lucky",
        //     child: ["Lucky", "LuckyRoom", "LuckyRule", "LuckyHistory"],
        //     value: this.$t("menu.Lucky"),
        //   },
        //   {
        //     id: 3,
        //     img1: require("@/assets/img/nav2/roll2.png"),
        //     img2: require("@/assets/img/nav2/roll.png"),
        //     selState: false,
        //     path: "Arena",
        //     paths: "Arena",
        //     child: ["Arena", "ArenaRoom"],
        //     value: this.$t("menu.Arena"),
        //   },

        //   {
        //     id: 7,
        //     img1: require("@/assets/img/nav2/mianfeifuli2.png"),
        //     img2: require("@/assets/img/nav2/mianfeifuli.png"),
        //     selState: false,
        //     path: "Activity",
        //     paths: "Activity",
        //     child: ["Activity", "Openbox"],
        //     value: this.$t("menu.Activity"),
        //   },

        //   {
        //     id: 9,
        //     img1: require("@/assets/img/nav2/shijianbox2.png"),
        //     img2: require("@/assets/img/nav2/shijianbox.png"),
        //     selState: false,
        //     path: "TimeBox",
        //     paths: "TimeBox",
        //     child: ["TimeBox", "Openbox"],
        //     value: this.$t("menu.TimeBox"),
        //   },
      ],
      moneyId: 0,
      giveawayList: [],
      boxInfo: [],
    };
  },
  watch: {
    //监听路由
    $route(to, from) {
      var path = to.name;
      document.body.scrollTop = 0; // 兼容老版本浏览器
      document.documentElement.scrollTop = 0; // 兼容新版本浏览器
      if (path == "Openbox" || path == "ActiveMove" || path == "hongbao") {
        this.isOpenBox = true;
      } else {
        this.isOpenBox = false;
      }
      if (path == "Index") {
        this.name = "盲盒大厅";
      } else if (path == "Lucky") {
        this.name = "盲盒对战";
      } else if (path == "Arena") {
        this.name = "免费皮肤";
      } else if (path == "Roll") {
        this.name = "饰品商城";
      } else if (path == "Ornament") {
        this.name = "幸运饰品";
      }
      this.hongbaoState1 = false;
      this.hongbaoState2 = false;
      for (let i = 0; i < this.menu.length; i++) {
        if (this.menu[i].child.includes(path)) {
          this.menu[i].selState = true;
        } else {
          this.menu[i].selState = false;
        }
      }
      for (let i = 0; i < this.yidongmenu.length; i++) {
        if (this.yidongmenu[i].child.includes(path)) {
          this.yidongmenu[i].selState = true;
        } else {
          this.yidongmenu[i].selState = false;
        }
      }
    },
    //监听手机验证码
    phonecodeState(val) {
      if (val == false) {
        this.phonetimer = setInterval(() => {
          this.phonecodeTime--;
        }, 1000);
      }
    },
    //监听手机验证码时间
    phonecodeTime(val) {
      if (val == 0) {
        this.phonecodeState = true;
        this.phonecodeTime = 60;
        clearInterval(this.phonetimer);
      }
    },
    //监听邮箱验证码
    emilcodeState(val) {
      if (val == false) {
        this.emiltimer = setInterval(() => {
          this.emilcodeTime--;
        }, 1000);
      }
    },
    //监听邮箱验证码时间
    emilcodeTime(val) {
      if (val == 0) {
        this.emilcodeState = true;
        this.emilcodeTime = 60;
        clearInterval(this.emiltimer);
      }
    },
    //监听忘记密码验证码
    forgetcodeState(val) {
      if (val == false) {
        this.forgettimer = setInterval(() => {
          this.forgetcodeTime--;
        }, 1000);
      }
    },
    //监听忘记密码验证码时间
    forgetcodeTime(val) {
      if (val == 0) {
        this.forgetcodeState = true;
        this.forgetcodeTime = 60;
        clearInterval(this.forgettimer);
      }
    },
  },
  created() {
    // this.selfLogins();
    this.selfLogin();
    // this.checkQueryParams();
  },
  beforeMount() {
    //判断屏幕宽度
    if (this.windowWidth < 1024) {
      this.quhuiSize = "100%";
      this.showMenuState = true;
      this.showNav = false;
    }
  },
  async mounted() {
    this.getInfo();
    this.getboxList();
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (!userInfo) {
      await this.checkQueryParams();
    }
    let codeInfo = this.GetUrlParam("code");
    this.code = codeInfo ? codeInfo.split("#")[0] : "";
    this.inputCode = this.code ? false : true;
    // 请求客服数据
    this.$axios({
      url: "/api/index/getsite",
      method: "post",
    }).then((res) => {
      if (res.data.code == 1) {
        this.kefu_url = res.data.data.kefu_url;
        this.qqqun_url = res.data.data.qqqun_url;
        this.qq_kefu = res.data.data.qq_kefu;
      }
    });
    // 请求轮播图数据
    this.$axios
      .post("/api/index/banner", { page: 1, pagesize: 10 })
      .then((res) => {
        if (res.data.code == 1) {
          this.bannerList = res.data.data;
        }
      });
    // 获取公告数据
    this.$axios({
      url: "/api/index/notice",
      method: "post",
    }).then((res) => {
      if (res.data.code == 1) {
        this.advertisement = res.data.data.center;
      }
    });

    this.getFightInfo();
    this.getLossInfo();
    this.getMengInfo();

    this.getActive();
    this.backAction();
    this.getHongbaoList();
    //判断屏幕宽度
    // if (this.windowWidth < 1024) {
    // if (document.body.clientWidth < 1024) {
    //   this.quhuiSize = "100%";
    //   this.showMenuState = true;
    //   this.showNav = false;
    // }else{
    //   this.showNav = true
    // }

    //判断屏幕宽度
    if (this.windowWidth < 1024) {
      this.quhuiSize = "100%";
      this.showMenuState = true;
      this.showNav = false;
    }
    // this.$bus.$on("loading", (e) => {
    //   this.loading = e;
    // });

    // this.$bus.$on("loading", (e) => {
    //   this.loading = e;
    // });
    //查看缓存 自动登录
    this.selfLogin();
    //路由名称
    this.getPlayerInfo();
    this.Index = this.$route.name;

    for (let i = 0; i < this.menu.length; i++) {
      if (this.menu[i].child.includes(this.Index)) {
        this.menu[i].selState = true;
      } else {
        this.menu[i].selState = false;
      }
    }

    let _this = this;
    Utils.$on("money", function (money) {
      // console.log(money);
      // this.money = money
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      console.log(userInfo, "dahjidajidla");
      userInfo.total_amount = money;
      //userInfo.masonry = masonry
      localStorage.setItem("userInfo", JSON.stringify(userInfo));
      _this.selfLogin();
    });
    Utils.$on("masonry", function (masonry) {
      // console.log(money);
      // this.money = money
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      //userInfo.total_amount = money
      userInfo.masonry = masonry;
      localStorage.setItem("userInfo", JSON.stringify(userInfo));
      _this.selfLogin();
    });
    Utils.$on("img", function (img) {
      console.log(img);
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      userInfo.image = img;
      localStorage.setItem("userInfo", JSON.stringify(userInfo));
      _this.selfLogin();
    });
    Utils.$on("login", function (data) {
      _this.selfLogin();
    });
    this.$router.push({
      path: `/Index`,
      // path: '/Mes'
    });
    this.getPayType();
    //
    // console.log(this.showNav,'89898989898')
  },
  methods: {
    getboxList() {
      let _this = this;
      _this.$axios.post("/api/welfare/getnewlist").then((res) => {
        if (res.data.code == 1) {
          _this.giveawayList = res.data.data;
        }
      });
    },
    // 充值
    recharge() {
      let data = {
        mode: this.mode,
        mode1: this.mode1,
        money: this.rechargeMoney,
        mobile: this.me.mobile,
        id: this.moneyId,
      };
      this.dialogPayment = false;
      this.$axios.post("/api/pay/recharge", data).then((res) => {
        let data = res.data;
        // this.loading = false;
        if (data.code == 1) {
          window.location.href = res.data.data;
          return;
          //支付宝跳转
          // if (this.isMobile()) {
          //   //移动端支付宝操作
          //   if (type == "zhifubao") {
          //     window.location = res.data.data;
          //     return;
          //   }
          // }

          // if (this.returntype == 3) {
          //   window.open(res.data.data);
          //   return;
          // } else if (this.returntype == 1) {
          //   this.payBox = true;
          //   this.loading = false;
          //   this.codeImg = red.data.data;
          //   return;
          // }
          // //this.$refs.qrcode.innerHTML = "";
          // this.payBox = true;
          // this.loading = false;
          // this.$nextTick(() => {
          //   const qrCode = new QRCode("qrcode", {
          //     width: 160,
          //     height: 160,
          //     text: res.data.data,
          //   });
          // });
        } else {
          this.$message({
            message:
              this.language == "zh" ? res.data.msg : res.data.english_msg,
            type: "error",
            customClass: "message-styles",
          });
        }
      });
    },
    selectPay(item) {
      this.moneyId = item.id;
      this.rechargeMoney = item.money;
    },
    ToggleEvent(item) {
      this.payType = item.id;
      this.mode1 = item.mode;
      // 强制刷新数据
      this.$forceUpdate();
      // console.log(id,'测试')
    },
    // 过滤出充值列表
    filterPayList() {
      let list = this.payList.filter((item) => {
        return item.id == this.payType;
      });
      return list[0].setcharge;
    },
    // 获取充值类型
    getPayType() {
      this.$axios({
        url: "/api/pay/paytype",
        method: "post",
      }).then((res) => {
        console.log(res, "测试");
        if (res.data.code == 1) {
          let data = res.data.data.list;
          this.payList = data;
          const firstData = data[0];
          // 默认选中第一个
          this.payType = firstData.id;
          this.moneyId = firstData.setcharge[0].id;
          this.rechargeMoney = firstData.setcharge[0].money;
          this.mode1 = firstData.mode;
          // const firstData = data[0];
          // if(firstData.zhi!=''){
          //   this.payType = firstData.zhi.mode;
          //   this.payType2 = firstData.zhi.mode1;
          //   this.returntype = firstData.zhi.returntype;
          //   this.selectedMethodIndex=1;
          // }else{
          //   this.payType = firstData.wei.mode;
          //   this.payType2 = firstData.wei.mode1;
          //   this.selectedMethodIndex=2;
          //   this.returntype = firstData.wei.returntype;
          // }
          // this.payTypes = data;
          // this.minMoney = firstData.min;
          // this.maxMoney = firstData.max;
          // this.list = firstData.setcharge;
          // this.moneyId = firstData.setcharge[0].id;
          // this.rmb = res.data.data.exchange_rate;
          // 默认选中支付方式

          // this.payObj.rmb = data.data.exchange_rate * 5;
        } else {
          this.$message({
            message: this.language == "zh" ? data.msg : data.english_msg,
            type: "error",
            customClass: "message-styles",
          });
          return;
        }
      });
    },
    checkQueryParams() {
      let _this = this;
      const urlParams = new URLSearchParams(window.location.search);
      const invite_code = urlParams.get("code");
      const openid_ns = urlParams.get("openid.ns");
      const openid_mode = urlParams.get("openid.mode");
      const openid_op_endpoint = urlParams.get("openid.op_endpoint");
      const openid_claimed_id = urlParams.get("openid.claimed_id");
      const openid_identity = urlParams.get("openid.identity");
      const openid_return_to = urlParams.get("openid.return_to");
      const openid_response_nonce = urlParams.get("openid.response_nonce");
      const openid_assoc_handle = urlParams.get("openid.assoc_handle");
      const openid_signed = urlParams.get("openid.signed");
      const openid_sig = urlParams.get("openid.sig");
      // 检查必要的参数是否存在
      if (
        !openid_ns ||
        !openid_mode ||
        !openid_op_endpoint ||
        !openid_claimed_id ||
        !openid_identity ||
        !openid_return_to ||
        !openid_response_nonce ||
        !openid_assoc_handle ||
        !openid_signed ||
        !openid_sig
      ) {
        console.log("缺少必要的参数");
        return;
      }
      // 登录
      let params = {
        invite_code,
        openid_ns,
        openid_mode,
        openid_op_endpoint,
        openid_claimed_id,
        openid_identity,
        openid_return_to,
        openid_response_nonce,
        openid_assoc_handle,
        openid_signed,
        openid_sig,
      };
      _this.loging = "loging";
      _this.$axios
        .post("/api/login/steamlogin", _this.$qs.stringify(params))
        .then((res) => {
          let data = res.data;
          _this.$store.commit("setUserToken", data.data.token);
          localStorage.setItem("loginStatus", new Date());
          localStorage.setItem("token", data.data.token);
          _this.getPlayerInfo();
          _this.getPayType();
          _this.getInfo();
          _this.$refs.baseHeader.getboxList();
          _this.$refs.baseHeader.getInfo();
          // if (data.code == 1) {
          //   _this.$store.commit("setUserToken", data.data.token);
          //   localStorage.setItem("loginStatus", new Date());
          //   localStorage.setItem("token", data.data.token);
          //   _this.$store.commit("getLogin", false);
          //   _this.loginfalse = false;
          //   _this.regHint = false;
          //   _this.getPlayerInfo();
          //   _this.loginfalse = false;
          // } else {
          //   _this.loginHintState = true;
          //   _this.loginHintText = data.msg;
          //   setTimeout(() => {
          //     _this.loging = "";
          //   }, 500);
          //   _this.$message({
          //     message: res.data.msg,
          //     type: "warning",
          //     customClass: "messageStyle",
          //   });
          // }
        });
    },
    // 切换语言
    changeLangEvent() {
      if (this.language == "en") {
        this.$i18n.locale = "zh";
        this.$message({
          message: "切换为中文！",
          type: "success",
        });
        localStorage.setItem("lang", "zh");
        this.language = "zh";
      } else if (this.language == "zh") {
        this.$i18n.locale = "en";
        this.$message({
          message: "Switch to English!",
          type: "success",
        });
        localStorage.setItem("lang", "en");
        this.language = "en";
      }
    },
    // 领取红包
    gotHongbao(id) {
      this.$axios({
        url: "/api/welfare/envelopeentryqu",
        method: "post",
        data: { id: id },
      }).then((res) => {
        if (res.data.code == 1) {
          this.$message({
            message: res.data.msg,
            type: "success",
            customClass: "messageStyle",
          });
          this.my_hongbao = res.data.data.money;
          this.hongbaoState2 = true;
          this.getHongbaoList();
        } else {
          this.$message({
            message: res.data.msg,
            type: "warning",
            customClass: "messageStyle",
          });
        }
      });
    },
    // 获取红包列表
    getHongbaoList() {
      this.$axios({
        url: "/api/welfare/envelopeentrylist",
        methos: "post",
      }).then((res) => {
        if (res.data.code == 1) {
          this.hongbaoListData = res.data.data;
        }
      });
    },
    // 点击签到按钮进行签到
    goSign(type) {
      this.$axios({
        url: "/api/welfare/sign_in",
        method: "post",
        data: { type: type },
      })
        .then((res) => {
          if (res.data.code == 1) {
            this.winMoney = res.data.data.money;
            this.$message({
              message: res.data.msg,
              type: "success",
              customClass: "messageStyle",
            });
            // 签到后重新获取数据
            this.getSignInfo();
          } else {
            this.$message({
              message: res.data.msg,
              type: "warning",
              customClass: "messageStyle",
            });
          }
        })
        .catch((res) => {
          this.$message({
            message: res.data.msg,
            type: "warning",
            customClass: "messageStyle",
          });
        });
    },
    qqsGroup() {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //判断是否是 android终端
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //判断是否是 iOS终端
      if (isAndroid) {
        window.open(
          // "mqqwpa://im/chat?chat_type=wpa&uin=3692073479"
          `mqqwpa://im/chat?chat_type=wpa&uin=${this.qq_kefu}`
        );
        return;
      }
      if (isIOS) {
        window.open(
          `mqqapi://card/show_pslcard?src_type=internal&source=sharecard&version=1&uin=${this.qq_kefu}`
        );
        return;
      }
      window.open(
        `https://wpa.qq.com/msgrd?v=3&uin=${this.qq_kefu}&site=qq&menu=yes&jumpflag=1`
      );
    },
    goPay() {
      this.dialogPayment = true;
      // this.$router.push("/Payment");
      // this.drawer = false;
    },
    goActive() {
      this.$router.push("/ActiveMove");
      this.drawer = false;
    },
    goAll(route) {
      this.$router.push(`${route}`);
      this.drawer = false;
    },
    goAllsss(route) {
      this.drawer = false;
      this.$router.push({ name: "kefu", params: { url: this.kefu_url } });
    },
    showFuliBoxs() {
      // 弹出弹窗
      this.showFuliBox = !this.showFuliBox;
      this.getSignInfo();
      this.getBoxInfo();
      this.getFightInfo();
      this.getLossInfo();
      this.getMengInfo();
    },
    goNav(index) {
      if (index == 1) {
        this.showBox = 1;
        this.getBoxInfo();
        console.log(index);
      }
      if (index == 2) {
        this.showBox = 2;
        this.getFightInfo();
        console.log(index);
      }
      if (index == 3) {
        this.showBox = 3;
        this.getLossInfo();
      }
      if (index == 4) {
        this.showBox = 4;
        this.getMengInfo();
      }
    },
    // 获取签到数据
    getSignInfo() {
      this.$axios({
        url: "/api/welfare/sign",
        data: {},
      }).then((res) => {
        if (res.data.code == 1) {
          // console.log(res.data.data,'data')
          let data = res.data.data.info;
          this.sign = res.data.data.sign;
          this.sign_recharge = res.data.data.sign_recharge;
          this.qusign = res.data.data.qusign;
          this.qusign_recharge = res.data.data.qusign_recharge;
        }
      });
    },
    // 获取宝箱任务数据
    getBoxInfo() {
      const params = {};
      this.$axios({
        url: "/api/welfare/openbox_reward_info",
        method: "post",
        data: {},
      }).then((res) => {
        if (res.data.code == 1) {
          let data = res.data.data.info;
          data.sort((a, b) => {
            return a["lim"] - b["lim"];
          });
          // console.log(data, "ooooooooooo");
          this.boxList = data;
        }
      });
    },
    // 获取追梦任务数据
    getMengInfo() {
      const params = {};
      this.$axios({
        url: "/api/welfare/lucky_reward_info",
        method: "post",
        data: {},
      }).then((res) => {
        if (res.data.code == 1) {
          let data = res.data.data.info;
          data.sort((a, b) => {
            return a["lim"] - b["lim"];
          });
          // console.log(data, "ooooooooooo");
          this.mengList = data;
        }
      });
    },
    async consumeRecharge1(item, index) {
      if (item.ls >= item.lim) {
        const params = {
          id: item.id,
        };
        const { data } = await this.$axios.post(
          "/api/welfare/kaixiangtask",
          this.$qs.stringify(params)
        );
        if (data.code) {
          this.boxmoney = data.data.money;
          this.boxList[index].status = 1;
          this.boxList.sort((a, b) => {
            return a["lim"] - b["lim"];
          });
          // this.$parent.$parent.getPlayerInfo();
          this.$message({
            message: "领取成功",
            type: "success",
            customClass: "messageStyle",
          });
        } else {
          this.$message({
            message: data.msg,
            type: "error",
            customClass: "messageStyle",
          });
        }
      } else {
        this.$router.push({
          path: "/",
        });
        this.showFuliBox = false;
      }
    },
    async consumeRecharge20(item, index) {
      if (item.ls >= item.lim) {
        const params = {
          id: item.id,
        };
        const { data } = await this.$axios.post(
          "/api/welfare/lucky_task",
          this.$qs.stringify(params)
        );
        if (data.code) {
          this.boxmoney = data.data.money;
          this.boxList[index].status = 1;
          this.boxList.sort((a, b) => {
            return a["lim"] - b["lim"];
          });
          // this.$parent.$parent.getPlayerInfo();
          this.$message({
            message: "领取成功",
            type: "success",
            customClass: "messageStyle",
          });
        } else {
          this.$message({
            message: data.msg,
            type: "error",
            customClass: "messageStyle",
          });
          // this.$message.error(data.msg);
        }
      } else {
        this.$router.push({
          path: "/",
        });
        this.showFuliBox = false;
      }
    },
    // 获取对战任务数据
    getFightInfo() {
      const params = {
        player_id: JSON.parse(localStorage.getItem("userInfo")).id,
      };
      this.$axios({
        url: "/api/welfare/battle_reward_info",
        method: "post",
        data: params,
      }).then((res) => {
        if (res.data.code == 1) {
          let data = res.data.data.info;
          data.sort((a, b) => {
            return a["lim"] - b["lim"];
          });
          // console.log(data, "ooooooooooo");
          this.fightList = data;
        }
      });
    },
    async consumeRecharge2(item) {
      if (item.ls >= item.lim) {
        const params = {
          id: item.id,
          player_id: JSON.parse(localStorage.getItem("userInfo")).id,
        };
        const { data } = await this.$axios.post(
          "/api/welfare/battle_task",
          this.$qs.stringify(params)
        );
        if (data.code) {
          this.boxmoney = data.data.money;
          this.fightList.forEach((e) => {
            if (e.id == item.id) {
              e.status = 1;
            }
          });
          this.fightList.sort((a, b) => {
            return a["lim"] - b["lim"];
          });
          // this.$parent.$parent.getPlayerInfo();
          this.$message.success("领取成功");
        } else {
          this.fightList.forEach((e) => {
            if (e.id == item.id) {
              e.status = 1;
            }
          });
          this.$message.error(data.msg);
        }
      } else {
        this.$router.push({
          path: "/Lucky",
        });
        this.showFuliBox = false;
      }
    },
    async consumeRecharge6(item) {
      if (item.ls >= item.lim) {
        const params = {
          id: item.id,
          player_id: JSON.parse(localStorage.getItem("userInfo")).id,
        };
        const { data } = await this.$axios.post(
          "/api/welfare/lucky_task",
          this.$qs.stringify(params)
        );
        if (data.code) {
          this.boxmoney = data.data.money;
          this.mengList.forEach((e) => {
            if (e.id == item.id) {
              e.status = 1;
            }
          });
          this.mengList.sort((a, b) => {
            return a["lim"] - b["lim"];
          });
          // this.$parent.$parent.getPlayerInfo();
          this.$message.success("领取成功");
        } else {
          this.mengList.forEach((e) => {
            if (e.id == item.id) {
              e.status = 1;
            }
          });
          this.$message.error(data.msg);
        }
      } else {
        this.$router.push({
          path: "/OrnamentOpen",
        });
        this.showFuliBox = false;
      }
    },
    // 获取亏损补偿数据
    getLossInfo() {
      const params = {
        player_id: JSON.parse(localStorage.getItem("userInfo")).id,
      };
      this.$axios({
        url: "/api/welfare/losslist",
        method: "post",
        data: {},
      }).then((res) => {
        console.log(res, "res");
        if (res.data.code == 1) {
          let data = res.data.data;
          // data.sort((a, b) => {
          //   return a["lim"] - b["lim"];
          // });
          // console.log(data, "ooooooooooo");
          this.lossList = data;
        }
      });
    },
    async consumeRecharge3(item, index) {
      // if (item.ls > item.lim) {
      const params = {
        loss_reward_id: item.id,
      };
      const { data } = await this.$axios.post(
        "/api/welfare/lossreceive",
        this.$qs.stringify(params)
      );
      if (data.code) {
        this.boxmoney = data.data.money;
        this.lossList[index].status = 1;
        this.lossList.sort((a, b) => {
          return a["lim"] - b["lim"];
        });
        // this.$parent.$parent.getPlayerInfo();
        this.getLossInfo();
        this.$message({
          message: "领取成功",
          type: "success",
          customClass: "messageIndex",
        });
      } else {
        this.$message.error(data.msg);
      }
      // } else {
      // this.$router.push({
      // 	path: "/",
      // });
      // }
    },
    suoxiaos() {
      this.suoxiao = true;
    },
    fangda() {
      this.suoxiao = false;
    },
    chongzhi() {
      if (this.showNav) {
        this.$router.push({
          path: "/Mes",
          query: {
            type: "充值",
          },
        });
      } else {
        this.$router.push({
          path: `/Payment`,
        });
      }
    },
    openTop() {
      this.isTop = true;
    },
    closeTop() {
      this.isTop = false;
    },
    clickOut() {
      if (this.windowWidth < 1024) {
        this.showNav = false;
      }
    },
    //左侧导航
    goMenu(id, path) {
      if (this.windowWidth < 1024) {
        this.showNav = !this.showNav;
      }
      for (let i = 0; i < this.menu.length; i++) {
        if (id == this.menu[i].id) {
          this.menu[i].selState = true;
        } else {
          this.menu[i].selState = false;
        }
      }
      if (id == 6) {
        this.$router.push({
          path: `/${path}`,
          query: {
            type: "推广",
          },
        });
        return;
      }
      this.$router.push({
        path: `/${path}`,
      });
    },
    // 免费箱子
    goBox(box_id, type) {
      if (!this.showNav) {
        this.drawer = false;
        this.showGiveaway = false;
      }
      const currentPath = this.$route.path;
      // 先导航到其他地方再回来
      if (type == 1) {
        this.$router.push({ path: "/" }).then(() => {
          this.$router.push({
            path: "/Openbox",
            query: {
              box_id: box_id,
              is_activity: 0,
              // type_name: type_name,
              // box_colour: box_colour,
              type: "free",
            },
          });
        });
      } else if (type == 2) {
        this.$router.push({ path: "/" }).then(() => {
          this.$router.push({
            path: "/lottery",
            query: {
              box_id: box_id,
            },
          });
        });
      }
      // this.$router.push({ path: "/" }).then(() => {
      //   if (type == 1) {
      //   } else if (type == 2) {

      //   }
      // });
    },
    // 获取时间盲盒数据
    getInfo() {
      this.$axios({
        url: "/api/rechargetime/rechargetimelist",
        method: "post",
      }).then((res) => {
        if (res.data.code == 1) {
          this.boxInfo = res.data.data;
        }
      });
    },
    //左侧导航
    goNavMenu(id, path) {
      this.drawer = false;
      for (let i = 0; i < this.yidongmenu.length; i++) {
        if (id == this.yidongmenu[i].id) {
          if (this.yidongmenu[i].value == "口令") {
            this.openHongbao();
            return;
          }
          this.yidongmenu[i].selState = true;
        } else {
          this.yidongmenu[i].selState = false;
        }
      }
      this.$router.push({
        path: `/${path}`,
      });
    },
    switchBar() {
      this.rightBar = !this.rightBar;
    },
    qqGroup() {
      // window.open("https://jq.qq.com/?_wv=1027&k=DRGtGaXu");
      // https://qm.qq.com/cgi-bin/qm/qr?k=9KCG2IpBHLC40Qz19ClpCbqC7fohaLMY&jump_from=webapi"
      // window.open("https://qm.qq.com/cgi-bin/qm/qr?k=9KCG2IpBHLC40Qz19ClpCbqC7fohaLMY&jump_from=webapi");
      window.open(
        "https://qm.qq.com/cgi-bin/qm/qr?k=s7qihLq_4vvaIaOwNlsGQDq6e3wSxdzM&jump_from=webapi"
      );
    },
    getActive() {
      let param = {
        player_id: localStorage.getItem("id"),
      };
      this.$axios
        .post("/api/player/giveaboutrecharge", this.$qs.stringify(param))
        .then((res) => {
          if (res.data.code == 1) {
            if (res.data.data.new) {
              res.data.data.recharge_activity.forEach((e) => {
                e.type == 1 ? (this.giveBillie = e.billie) : "";
                e.type == 2 ? (this.giveBillie = e.billie) : "";
              });
            } else {
              res.data.data.recharge_activity.forEach((e) => {
                e.type == 2 ? (this.giveBillie = e.billie) : "";
              });
            }
          }
        });
    },
    gotoNav() {
      for (let i = 0; i < this.menu.length; i++) {
        if (this.menu[i].id == 1) {
          this.menu[i].selState = true;
        } else {
          this.menu[i].selState = false;
        }
      }
      console.log(this.menu);
      this.$router.push({
        path: `/Index`,
      });
    },
    //取回助手
    backAction(click) {
      if (click) {
        this.rightBarDrawer = true;
      }
      let param = {
        page: 1,
        pageSize: 10,
      };
      // this.$axios
      //   .post("/index/User/getRetrieveStatus", this.$qs.stringify(param))
      //   .then((res) => {
      //     let data = res.data;
      //     //  console.log(data);
      //     if (data.status == 1) {
      //       this.tableData = data.data.list;
      //       this.num = data.data.list.length;
      //     } else {
      //       this.tableData = [];
      //       this.num = 0;
      //     }
      //   });
    },
    //单个取回
    takeOffer(url) {
      window.open(url, "_blank");
    },
    tableRowStyle({ row, rowIndex }) {
      return "background-color: red";
    },
    handleClose() {},
    funcHidden() {
      this.rightBarDrawer = false;
    },
    funcRefresh() {
      this.backAction();
    },
    //选取语言
    getLanguage() {
      this.language = !this.language;
    },
    //改变语言
    changeLanguage(index) {
      this.languageImg = this.languageList[index].url;
      this.languageText = this.languageList[index].name;
      this.language = false;
    },
    //菜单路径跳转
    gopath(key) {
      if (this.$store.state.token) {
        this.$router.push({
          path: `/${key}`,
        });
      } else {
        this.$store.commit("getLogin", true);
      }
    },
    // 跳转用户协议
    goAgreement() {
      this.regBox = false;
      this.hideLogin();
      this.$router.push({
        path: `/footer`,
      });
    },
    // 跳转隐私条款
    goPrivacy() {
      this.regBox = false;
      this.hideLogin();
      this.$router.push({
        path: `/footer`,
      });
    },
    // 跳转隐私条款
    goClause() {
      this.regBox = false;
      this.hideLogin();
      this.$router.push({
        path: `/Clause`,
      });
    },
    //跳转到疑问解答
    goDoubt() {
      this.$router.push({
        path: `/Doubt`,
      });
    },
    //兑换弹窗
    goexchange() {
      this.passState = true;
      this.drawer = false;
    },
    //隐藏密码框
    hidePass() {
      this.coin = 0;
      this.passState = false;
    },
    allChange() {
      this.coin = Number(this.masonry).toFixed(2);
    },
    //钻石兑换金币
    exchange() {
      if (Number.parseFloat(this.coin) > Number.parseFloat(this.masonry)) {
        this.$message.error("钻石数量不足");
        return;
      }
      var _this = this;
      let param = {
        coin: this.coin,
      };
      this.$axios
        .post("/api/player/exchange", this.$qs.stringify(param))
        .then((res) => {
          var newMoney = parseFloat(this.money) + parseFloat(param.coin);
          var newMasonry = parseFloat(this.masonry) - parseFloat(param.coin);
          let userInfo = JSON.parse(localStorage.getItem("userInfo"));
          userInfo.total_amount = newMoney;
          userInfo.masonry = newMasonry;
          //userInfo.masonry = masonry
          localStorage.setItem("userInfo", JSON.stringify(userInfo));
          _this.selfLogin();
          //console.log(res.data);
          var data = res.data;
          if (data.code == "1") {
            this.$message({
              message: data.msg,
              type: "success",
              customClass: "messageStyle",
            });
            //this.getRoomList();
            this.passState = false;
          } else {
            this.$message({
              message: data.msg,
              type: "error",
              customClass: "messageStyle",
            });
          }
        });
    },

    //右侧菜单跳转饰品商城
    goRoll() {
      this.$router.push({
        path: `/Roll`,
      });
      this.drawer = false;
    },
    //跳转充值界面
    goPayment() {
      this.$router.push({
        path: `/Payment`,
      });
      this.drawer = false;
    },
    //右侧菜单路径
    gopath1(key, keyPath) {
      this.$router.push({
        path: `/${key}`,
      });
      this.drawer = false;
    },
    //注册盒子显示隐藏
    showReg() {
      this.regBox = true;
    },
    hideReg() {
      this.regBox = false;
    },
    //点击手机或邮箱注册
    phoneReg() {
      this.phoneregBox = true;
      this.emilregBox = false;
    },
    emilReg() {
      this.phoneregBox = false;
      this.emilregBox = true;
    },

    //忘记密码
    goForget() {
      //this.loginBox = false;
      this.$store.commit("getLogin", false);
      this.forgetBox = true;
    },
    goLogin1() {
      //this.loginBox = true;
      this.$store.commit("getLogin", true);
      this.forgetBox = false;
    },
    hideForgetBox() {
      this.forgetBox = false;
    },
    getForgetCode() {
      let param = {
        account: this.forgetNum,
      };
      let param1 = {
        // mobile:JSON.parse(localStorage.getItem('userInfo')).mobile,
        mobile: this.forgetNum,
        event: "updatepass",
      };
      this.$axios
        .post("/api/login/getsmscode", this.$qs.stringify(param1))
        .then((res) => {
          let data = res.data;
          // console.log(data);
          if (data.code == 1) {
            this.forgetcodeState = false;
            this.forgetHint = false;
          } else {
            this.forgetHint = true;
            this.forgetHintText = data.msg;
          }
        });
    },
    reset() {
      let param = {
        account: this.forgetNum,
        code: this.forgetCode,
        password: this.forgetPass,
      };
      this.$axios
        .post("/api/login/findpassword", this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          //  console.log(data);
          if (data.code == 1) {
            this.$message({
              message: data.msg,
              type: "success",
              customClass: "messageStyle",
            });
            this.forgetBox = false;
            // this.loginBox = true;
            this.$store.commit("getLogin", true);
          } else {
            this.forgetHint = true;
            this.forgetHintText = data.msg;
          }
        });
    },

    //获取验证码
    getphoneCode() {
      this.loadingReg = true;
      let _this = this;
      let param = {
        account: _this.phoneinput1,
      };
      let param2 = {
        mobile: _this.phoneinput1,
        event: "register",
      };
      _this.$axios
        .post("/api/login/getsmscode", _this.$qs.stringify(param2))
        .then((res) => {
          //  console.log(res);
          let data = res.data;
          if (data.code == 1) {
            this.loadingReg = false;
            this.phonecodeState = false;
            this.regHint = false;
          } else {
            this.loadingReg = false;
            this.regHint = true;
            this.regHintText = data.msg;
          }
        });
    },

    // 获取参数
    GetUrlParam(name) {
      var url = window.location.href;
      let params = url.substr(url.lastIndexOf("?") + 1).split("&");
      for (let i = 0; i < params.length; i++) {
        let param = params[i];
        let key = param.split("=")[0];
        let value = param.split("=")[1];
        if (key === name) {
          return value;
        }
      }
    },

    //点击注册按钮
    // regBtn() {
    //   return;
    //   if (!this.Regchecked || !this.Regchecked1) {
    //     this.$message({
    //       message: "请仔细阅读用户协议并勾选",
    //       type: "warning",
    //       customClass: "messageStyle",
    //     });
    //     return;
    //   }

    //   if (!this.phoneregBox) {
    //     this.$message({
    //       message: "后续将会开放邮箱注册",
    //       type: "warning",
    //       customClass: "messageStyle",
    //     });
    //     return;
    //   }

    //   let _this = this;
    //   var a = this.GetUrlParam("code");
    //   if (a) {
    //     var code = a.split("#")[0];
    //     var param = {
    //       account: _this.phoneinput1,
    //       code: _this.phoneinput3,
    //       password: _this.phoneinput2,
    //       invite_code: code,
    //     };
    //   } else {
    //     var param = {
    //       account: _this.phoneinput1,
    //       code: _this.phoneinput3,
    //       password: _this.phoneinput2,
    //       invite_code: this.phoneinput4,
    //       type: "input",
    //     };
    //   }
    //   var param8 = {
    //     account: _this.phoneinput1,
    //     code: _this.phoneinput3,
    //     password: _this.phoneinput2,
    //     invite_code: this.phoneinput4,
    //     type: "input",
    //   };
    //   this.$axios
    //     .post("/api/login/register", this.$qs.stringify(param8))
    //     .then((res) => {
    //       let data = res.data;
    //       //  console.log(data);
    //       if (data.code == 1) {
    //         this.$message({
    //           message: res.data.msg,
    //           type: "success",
    //           customClass: "messageStyle",
    //         });
    //         localStorage.setItem("csgoNum", this.phoneinput1);
    //         localStorage.setItem("csgoPass", this.phoneinput2);
    //         localStorage.setItem("loginStatus", new Date());
    //         localStorage.setItem("id", data.data.id);
    //         localStorage.setItem("userInfo", JSON.stringify(res.data.data));
    //         this.$store.commit("setUserToken", data.data.token);
    //         this.regBox = false;
    //         this.selfLogin();
    //         // this.loginBox = false;
    //         this.$store.state.mobile = data.data.mobile;
    //         this.$store.commit("getLogin", false);
    //         this.$store.commit("getLogins", true);
    //         this.loginfalse = false;
    //         this.regHint = false;
    //         this.$store.commit("getId", data.data);
    //         this.me = data.data;
    //         setTimeout(function () {
    //           _this.getPlayerInfo();
    //         }, 200);
    //       } else {
    //         this.$message({
    //           message: res.data.msg,
    //           type: "warning",
    //           customClass: "messageStyle",
    //         });
    //       }
    //     });
    // },
    getemilCode() {
      //this.emilcodeState = false;
      this.$message({
        message: "后续将会开放邮箱注册",
        type: "warning",
        customClass: "messageStyle",
      });
    },

    getPlayerInfo(pid) {
      var that = this;
      this.$axios.post("/api/player/playerdata", {}).then((res) => {
        if (res.data.code == 1) {
          localStorage.setItem(
            "userInfo",
            JSON.stringify(res.data.data.player)
          );
          console.log(localStorage.getItem("userInfo"), "121212ressss");
          Utils.$emit("login", true);
          const userInfo = res.data.data.player;
          this.me = userInfo;
          this.money = userInfo.total_amount;
          this.masonry = userInfo.masonry;
          this.loginfalse = false;
          this.regHint = false;
          this.$store.commit("getLogins", true);
          this.getBoxInfo();
          // that.selfLogin();
        }
      });
    },
    //显示隐藏登录框
    showLogin() {
      //this.loginBox = true;
      this.$store.commit("getLogin", true);
      // 通过steam登录
      this.getSteamLoginUrl();
    },
    // steam登录地址
    getSteamLoginUrl() {
      let params = {
        invite_code: this.code,
      };
      this.$axios
        .post("/api/login/getsteamloginurl", this.$qs.stringify(params))
        .then((res) => {
          if (res.data.code == 1) {
            window.location.href = res.data.data.url;
          }
        });
    },
    mobileLogin() {
      this.drawer = false;
      this.$store.commit("getLogin", true);
      this.getSteamLoginUrl();
    },
    hideLogin() {
      //this.loginBox = false;
      this.$store.commit("getLogin", false);
    },

    //点开登录 注册盒子
    goLogin() {
      this.regBox = false;
      //this.loginBox = true;
      this.$store.commit("getLogin", true);
    },
    goReg() {
      this.regBox = true;
      //this.loginBox = false;
      this.$store.commit("getLogin", false);
    },
    //自动登录
    // selfLogins() {
    // 	let _this = this;
    // 	if (localStorage.getItem("loginChecked")) {
    // 		let num = localStorage.getItem("csgoNum");
    // 		let pass = localStorage.getItem("csgoPass");
    // 		if (num) {
    // 			let param = {
    // 				account: num,
    // 				password: pass,
    // 			};
    // 			this.$axios
    // 				.post("/index/Login/Login", this.$qs.stringify(param))
    // 				.then((res) => {
    // 					let data = res.data;
    // 					//  console.log(data);
    // 					if (data.status == 1) {
    // 						localStorage.setItem("csgoNum", num);
    // 						localStorage.setItem("csgoPass", pass);
    // 						localStorage.setItem("id", data.data.id);
    // 						localStorage.setItem(
    // 							"userInfo",
    // 							JSON.stringify(res.data.data)
    // 						);
    // 						this.$store.commit("setUserToken", data.data.token);
    // 						this.selfLogin();
    // 						// this.loginBox = false;
    // 						this.$store.state.mobile = data.data.mobile;
    // 						this.$store.commit("getLogin", false);
    // 						this.loginfalse = false;
    // 						this.regHint = false;
    // 						this.$store.commit("getId", data.data);
    // 						this.me = data.data;
    // 						setTimeout(function() {
    // 							_this.getPlayerInfo();
    // 						}, 200)
    // 					}
    // 				});
    // 		}
    // 	}
    // },
    selfLogin() {
      if (localStorage.getItem("loginChecked")) {
        this.loginChecked = true;
        this.account = localStorage.getItem("csgoNum");
        this.password = localStorage.getItem("csgoPass");
      }
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      if (!userInfo) {
        return;
      }
      this.me = userInfo;
      // console.log(this.me,'mememe')
      this.money = userInfo.total_amount;
      this.masonry = userInfo.masonry;
      this.loginfalse = false;
      this.regHint = false;
    },
    steamSelfLogin() {
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      console.log("捡来", userInfo);
      if (!userInfo) {
        return;
      }
      this.me = userInfo;
      this.money = userInfo.total_amount;
      this.masonry = userInfo.masonry;
      this.loginfalse = false;
      this.regHint = false;
    },
    //steam登录
    login_steam() {
      let _this = this;
      let param = {
        steam_login: "steam_login",
      };
      _this.$axios
        .post("/index/Login/steam_login", _this.$qs.stringify(param))
        .then((res) => {
          console.log(res.data.data);
          let re = _this.isUrl(res.data.data);
          if (re) {
            // window.open(res.data.data,'_blank');
            window.location.href = res.data.data;
            return;
          }
          return;
          // window.location.href='/'
          let data = res.data;
          if (res.data.status == 1) {
            localStorage.setItem("csgoNum", _this.account);
            localStorage.setItem("csgoPass", _this.password);
            localStorage.setItem("id", data.data.id);
            localStorage.setItem("userInfo", JSON.stringify(res.data.data));
            //_this.loginBox = false;
            setTimeout(() => {
              _this.$store.state.mobile = data.data.mobile;
              _this.$store.commit("getLogin", false);
              _this.loginfalse = false;
              _this.regHint = false;
              _this.$store.commit("getId", data.data);
              _this.me = data.data;
              _this.selfLogin();
              _this.loging = "";
            }, 500);
          } else {
            _this.loginHintState = true;
            _this.loginHintText = data.msg;
            setTimeout(() => {
              _this.loging = "";
            }, 500);
          }
        });
    },
    isUrl(url) {
      var reg = "[a-zA-z]+://[^s]*"; //正则
      if (url.length > 0) {
        var reg_test = new RegExp(reg);
        var result = reg_test.test(url);
        console.log(result);
        if (result != 1) {
          return false;
        } else {
          return true;
        }
      }
    },
    //登录按钮 记住账号密码
    // getLogin(event) {
    //   return;
    //   if (event) {
    //     var ripple = document.getElementById("ripple");
    //     var style = document.createElement("style");
    //     style.innerHTML = `.ripple::after{top:${event.offsetY - 20}px;left:${
    //       event.offsetX - ripple.offsetWidth / 2
    //     }px;}`;
    //     document.head.appendChild(style);
    //   }
    //   if (!this.loginChecked1) {
    //     this.$message({
    //       message: "请仔细阅读用户协议并勾选",
    //       type: "warning",
    //       customClass: "messageStyle",
    //     });
    //     return;
    //   }
    //   let _this = this;
    //   let param = {
    //     account: _this.account,
    //     password: _this.password,
    //   };
    //   _this.loging = "loging";
    //   // _this.$axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
    //   _this.loginReturn;
    //   _this.$axios
    //     .post("/api/login/login", _this.$qs.stringify(param))
    //     .then((res) => {
    //       let data = res.data;
    //       _this.loginReturn = res.data;
    //       if (data.code == 1) {
    //         _this.getPlayerInfo();
    //         localStorage.setItem("csgoNum", this.account);
    //         localStorage.setItem("csgoPass", this.password);
    //         localStorage.setItem("id", data.data.id);
    //         localStorage.setItem("loginStatus", new Date());
    //         localStorage.setItem("userInfo", JSON.stringify(res.data.data));
    //         //_this.loginBox = false;
    //         this.$store.commit("setUserToken", data.data.token);
    //         this.$store.commit("getLogins", true);
    //         this.selfLogin();
    //         if (this.loginChecked) {
    //           localStorage.setItem("loginChecked", true);
    //         }
    //         setTimeout(() => {
    //           _this.$store.state.mobile = data.data.mobile;
    //           _this.$store.commit("getLogin", false);
    //           _this.loginfalse = false;
    //           _this.regHint = false;
    //           _this.$store.commit("getId", data.data);
    //           _this.me = data.data;
    //           _this.selfLogin();
    //           _this.loging = "";
    //         }, 500);
    //       } else {
    //         this.loginHintState = true;
    //         this.loginHintText = data.msg;
    //         setTimeout(() => {
    //           _this.loging = "";
    //         }, 500);
    //         this.$message({
    //           message: res.data.msg,
    //           type: "warning",
    //           customClass: "messageStyle",
    //         });
    //       }
    //     });
    // },
    //退出登录
    nextLogin(value) {
      localStorage.setItem("userInfo", null);
      this.loginfalse = true;
      this.drawer = false;
      this.$store.commit("getId", {
        name: "",
        id: "",
        img: "",
        money: "",
      });
      this.$store.commit("loginOut");
      // 清除 URL 中的参数
      if(value=='logout'){
        const url = window.location.href.split("?")[0];
        window.history.replaceState({}, document.title, url);
      }
      this.$router.push({
        path: `/Index`,
      });
    },

    //红包
    //打开红包
    openHongbao() {
      this.drawer = false;
      let param = {};
      this.$axios
        .post("/api/welfare/existenvelope", this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          //  console.log(data);
          if (data.code == 1) {
            this.hongbaoid = data.data.id;
            //未抢
            if (data.data.status == 0) {
              this.hongbaoState1 = true;
            } else {
              this.hongbaoState1 = true;
              //this.hongbaoList()
            }
          } else {
            if (data.msg == "参数错误") {
              this.$message({
                message: "请先登录",
                type: "warning",
                customClass: "messageStyle",
              });
              this.$store.commit("getLogin", true);
            } else {
              this.$message({
                message: "暂时没有红包哦",
                type: "warning",
                customClass: "messageStyle",
              });
            }
          }
        });
    },
    openTaskCenter() {
      this.$router.push({
        path: "/task-center",
      });
    },
    //红包数据
    hongbaoList() {
      let param = {
        envelope_id: this.hongbaoid,
      };
      this.$axios
        .post("/api/welfare/getdetails", this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          //  console.log(data);
          if (data.code == 1) {
            let hongbaoDataCopy = data.data.details;
            // this.my_hongbao = data.data.my_envelope;
            this.hongbaoName = data.data.name;
            if (hongbaoDataCopy.length > 4) {
              for (let i = 0; i < hongbaoDataCopy.length; i++) {
                if (i < 5) {
                  this.hongbaoData.push(hongbaoDataCopy[i]);
                }
              }
            } else {
              this.hongbaoData = hongbaoDataCopy;
            }
          }
        });
    },
    qqGroup() {
      window.open(
        // "http://qm.qq.com/cgi-bin/qm/qr?_wv=1027&k=vDMYvVo9o_a9a1Plbt691nTbgAthqHTJ&authKey=rw7IQ5LHQzkc8a7m0JAv8KgLrplbjEbmbNXpBdRBMuNTHwAF%2Fv%2BTx9TGu9ZodU4S&noverify=0&group_code=955634995"
        `https://${this.qqqun_url}`
      );
    },
    //领取红包
    getHongbao() {
      if (!this.hongbaoText) {
        this.$message({
          message: "请输入红包口令",
          type: "warning",
          customClass: "messageStyle",
        });
        return;
      }
      // this.hongbaoState1 = false;
      // this.hongbaoState2 = true;
      let param = {
        envelope_id: this.hongbaoid,
        password: this.hongbaoText,
      };
      this.$axios
        .post("/api/welfare/envelope", this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          //  console.log(data);
          if (data.code == 1) {
            // this.hongbaoState1 = false;
            this.hongbaoState2 = true;
            this.my_hongbao = data.data.gotMoney;
            this.hongbaoList();
            this.getPlayerInfo();
          } else {
            this.$message({
              message: data.msg,
              type: "warning",
              customClass: "messageStyle",
            });
          }
        });
    },

    hideHongbao1() {
      this.hongbaoState1 = false;
      this.hongbaoText = "";
    },
    hideHongbao2() {
      this.hongbaoState2 = false;
    },

    //手机端打开左侧菜单
    showMenu() {
      this.showNav = !this.showNav;
      // if (this.windowWidth < 1024) {
      //   this.showNav = true;
      // }
    },

    //右侧导航关闭
    handleClose(done) {
      done();
    },
    goProfile() {
      this.$router.push({
        path: "/Profile",
      });
    },
    goIndex() {
      this.$router.push({
        path: "/",
      });
    },
    drawerFun() {
      if (!this.showNav) {
        this.drawer = !this.drawer;
      } else {
        this.$router.push({
          path: "/Profile",
        });
      }
      // if (this.showNav) {

      // 	this.$router.push({
      // 		path: "/Mes",
      // 	});
      // } else {
      // 	this.$router.push({
      // 		path: "/Mess",
      // 	});
      // }
    },
    // 切换语言
    changeLangEvent() {
      console.log("切换语言");
      this.dialogVisible = true;
    },
    changeLanguage(lang) {
      this.languageName = lang;
    },
    saveLanguage() {
      this.dialogVisible = false;
      const language = this.languageName;
      this.$i18n.locale = language;
      localStorage.setItem("lang", language);
      localStorage.setItem("isManualSet", "true"); // 标记为手动设置
      this.language = language;
      this.$store.commit("setLanguage", language);
      window.location.reload();
    },
    handleClose() {
      this.dialogVisible = false;
    },
    contactService() {
      // 跳转到客服
      window.location.href = this.kefu_url;
      this.dialogPayment = false;
      console.log("联系客服");
    },
  },

  // created() {
  //   var _this = this;
  //   document.onkeydown = (e) => {
  //     let e1 =
  //       e || event || window.event || arguments.callee.caller.arguments[0];
  //     if (e1 && e1.keyCode == 13) {
  //       if (this.$store.state.loginState) {
  //         _this.getLogin();
  //       }
  //     }
  //   };
  // },
};
</script>

<style lang="less" scoped>
@media screen and (max-width: 767px) {
  .reg,
  .login {
    .content {
      width: auto !important;
      height: auto !important;
      .left {
        display: none !important;
      }
      .right {
        width: 95vw !important;
        // height: auto !important;
        .reg-warp {
          padding: 10vw;
          overflow: hidden;
          margin-top: -2vw !important;
        }
      }
    }
  }
}
@media screen and (min-width: 767px) {
  .top-l-r {
    margin-left: 10px !important;
  }

  .top-el-row {
    padding-right: 0px !important;
    position: relative;
  }
}

@media screen and (max-width: 767px) {
  .buttons {
    width: 70px !important;
    height: 30px !important;
    font-size: 12px !important;
    background-repeat: no-repeat;
    font-weight: 400;
    color: #fff;
    // background-color: rgb(230, 215, 14);
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 25px;
    background-image: url("../assets/img/my/button.png");
    background-size: 100% 100%;
    margin-right: 24px;
  }
}

.buttons {
  width: 90px;
  height: 35px;
  font-size: 14px;
  background-repeat: no-repeat;
  font-weight: 400;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 25px;
  background-image: url("../assets/img/my/button.png");
  background-size: 100% 100%;
  margin-right: 24px;
}

.home {
  width: 100%;
  height: auto;
  background-color: #02bf4d !important;
  .nav2 {
    // position: fixed;
    // top: 50%;
    // left: 0;
    height: 42px;
    margin-top: 15px;

    // z-index: 999;
    ul {
      display: flex;

      li {
        float: left;
        display: flex;
        align-items: center;
        margin-left: 20px;
        cursor: pointer;

        img {
          display: block;
          height: 34px;
          width: auto;
        }

        .img3 {
          display: none;
          position: relative;
          // left: -40px;
        }

        .img4 {
          display: none;
        }

        div {
          color: white;
          font-size: 15px;
          line-height: 38px;
          margin-left: 10px;
          white-space: nowrap;
        }
      }

      li:nth-child(1) {
        margin-left: 0px;
      }

      li:hover {
        .img3 {
          display: block;
          // animation: run 0.5s;
          // animation-iteration-count: 1; //播放几次动画
          // animation-delay: 0s; //动画运行前等待时间
          // animation-fill-mode: forwards; //动画结束 是否保持

          // @keyframes run {
          //   0% {
          //     left: -40px;
          //   }
          //   100% {
          //     left: 0;
          //   }
          // }
        }

        .img1 {
          display: none;
        }

        .img2 {
          display: none;
        }
      }
    }
  }

  .item-val {
    color: #fff !important;
  }

  .item-val-bian {
    color: rgba(255, 155, 11, 1);
  }

  .flexleft {
    height: 35px;
    position: fixed;
    z-index: 999;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: #191e2e;
    padding-bottom: 5px;

    ul {
      width: 100%;
      display: flex;
      flex-direction: row;
      padding: 0 12px;
      justify-content: space-between;

      li {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .top {
    background: linear-gradient(to right, #121325 0%, #211b4c 100%);
    height: 72px;
    color: #fff;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 98;
    width: calc(100% - 12rem);

    .top-l-r {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    // width: calc(100% - 17px);
    .top-el-row {
      display: flex;
      align-items: center;
      height: 100%;
      // background-color: #06090f;
      // background: linear-gradient(to right, #121325 0%, #211b4c 100%);
    }

    .top-btn {
      position: absolute;
      display: none;
    }

    .logo {
      display: flex;
      // justify-content: center;
      align-items: center;
      width: 90%;

      .logo-img {
        width: 126.6px !important;
        height: auto !important;
      }

      .top-bag,
      .top-pay {
        margin-top: 10px;
        margin-left: 10px;
        display: flex;
        align-items: center;

        img {
          width: 15px;
          height: auto;
          margin-right: 4px;
        }

        .span {
          font-size: 15px;
          color: white;
        }

        .span:hover {
          color: #e9b10e;
          cursor: pointer;
        }
      }

      .top-pay {
        .pay-span {
          margin-left: 10px;
          color: #1a1c24;
          display: inline-block;
          padding: 0 10px;
          font-size: 14px;
          border-radius: 5px;
          background-color: #02bf4d;
          position: relative;
        }

        .pay-span::after {
          top: 50%;
          left: -4px;
          color: #02bf4d;
          width: 0;
          content: "";
          position: absolute;
          border-top: 4px solid transparent;
          margin-top: -4px;
          border-right: 4px solid;
          border-bottom: 4px solid transparent;
        }
      }

      .img1 {
        height: 50px;
        // margin-top: 5px;
        width: auto;
      }

      .zm {
        height: 40px;
        margin-top: 10px;
        width: auto;
      }

      .img2 {
        height: 34px;
        width: auto;
      }

      .img3 {
        height: 34px;
        width: auto;
      }

      .span-line {
        height: 42px;
        width: 2px;
        background-color: #999999;
        margin: 10px 15px;
      }
    }

    .top-name-have {
      padding-right: 20px;
      justify-content: flex-end;
    }

    .top-name {
      align-items: center;
      display: flex;
      padding-right: 10px !important;
      justify-content: flex-end;

      .top-con {
        padding-left: 20px;
        border-left: 3px solid #999;
        display: flex;
        align-items: center;

        span {
          padding-left: 20px;
          color: #999;
        }
      }

      .top-right {
        display: flex;
        align-items: center;
        color: #fff;
        font-weight: bold;
        justify-content: center;
        border-color: #27ae60;
        color: #fff;
        background: linear-gradient(
          to right,
          rgba(39, 174, 96, 0) 0%,
          rgba(39, 174, 96, 0.3) 100%
        );
        border: 2px solid #27ae60;
        // margin: 14px 30px 0 0;
        width: 250px;
        line-height: 40px;
        border-radius: 99px;
        span {
          cursor: pointer;

          &:hover {
            // color: #e9b10e;
          }
        }
      }

      .top-right1 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        // margin-right: 25%;
        .mess {
          display: flex;
          align-items: center;

          .mess-left {
            display: flex;
            align-items: center;
            .nickname {
              margin: 0px 30px;
              text-decoration: underline;
            }
            img {
              width: 45px !important;
              height: 45px !important;
              border-radius: 50%;
              margin-block: 30px;
            }
          }

          .mess-right {
            //margin-left: -5px;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            padding-right: 12px;

            .mess-span1 {
              font-size: 16px;
              color: #ffffff;
              font-weight: 600;
            }

            .mess-span2 {
              display: flex;
              flex-direction: column;
              align-items: center;

              div {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                width: 70px;
              }

              img {
                width: auto;
                height: 16px;
              }

              strong {
                margin: 4px;
                margin-left: 3px;
                font-size: 10px;
                color: #e9b10e;
              }
            }
          }
        }

        .top-right1-ico {
          // margin-right: 20px;
          img {
            width: 20px;
            height: 20px;
          }
        }
      }

      .top-right1:hover {
        cursor: pointer;
      }

      .p-r {
        display: flex;
        color: white;
        justify-content: flex-start;
        margin-top: 5px;

        .logo-span {
          cursor: pointer;
          display: flex;
          height: 19px;
        }

        .logo-span:hover {
          .text {
            color: #e9b10e;
          }
        }

        .top-pay:nth-child(2) {
          margin-left: 12px;
        }

        .span,
        .pay-span {
          font-size: 14px;
          margin-left: 6px;
          white-space: nowrap;
        }

        .pay-span {
          margin-left: 10px;
          color: #1a1c24;
          display: inline-block;
          padding: 0 10px;
          font-size: 14px;
          border-radius: 5px;
          background-color: #02bf4d;
          position: relative;
        }

        .pay-span::after {
          top: 50%;
          left: -4px;
          color: #02bf4d;
          width: 0;
          content: "";
          position: absolute;
          border-top: 4px solid transparent;
          margin-top: -4px;
          border-right: 4px solid;
          border-bottom: 4px solid transparent;
        }
      }
    }
  }

  .isLogin {
    display: none !important;
  }

  .maringtop115 {
    margin-top: 115px;
  }

  .maringtop75 {
    padding-top: 72px;
  }

  .hh75 {
    width: 100%;
    background-color: white;
    height: 75px;
  }

  .backColor {
    min-height: 100%;
    // display: flex;
    background-color: #161932;
  }

  .bot {
    height: 100%;
    display: flex;
    background-color: #25252f;

    .bot-left {
      height: 100%;
      min-width: 290px;
      max-width: 290px;
      background-color: #000;
      position: relative;
      box-shadow: 5px 0px 8px #262626;
      overflow: hidden;
      overflow-y: auto;
      -ms-overflow-style: none;

      .imgtop {
        position: absolute;
        right: 4%;
        top: 0;
        width: 90%;

        img {
          width: 100%;
        }
      }

      .img {
        height: 45%;
        position: absolute;

        img {
          //height: 100%;
          width: 240px;
          height: 266px;
        }

        img:hover {
          cursor: pointer;
        }

        .img11 {
          position: absolute;
          height: 50%;
          width: auto;
          top: -35%;
        }

        .img12 {
          right: -27%;
        }

        .img13 {
          left: -27%;
        }

        .class3 {
          position: absolute;
          height: 50%;
          width: auto;
          top: 7%;
          right: -67%;
        }

        .img-bot {
          height: 30%;
          position: absolute;
          bottom: -15%;
          right: 20%;
          display: flex;
          flex-direction: column;

          img {
            height: 100%;
            width: auto;
          }

          img:last-child {
            margin-top: 10px;
          }
        }
      }

      .img1 {
        top: -25px;
        left: -22px;
      }

      .img2 {
        top: 150px;
        left: 73px;
      }

      .img3 {
        top: 320px;
        left: -22px;
      }

      .img4 {
        top: 490px;
        left: 73px;
      }

      .img5 {
        top: 660px;
        left: -22px;
      }
    }

    .bot-left1 {
      display: block;
      position: fixed;
      top: 60px;
      left: 0;
      z-index: 666;
    }

    //设置左侧滚动条
    .bot-left::-webkit-scrollbar {
      width: 0px;
    }

    /* .bot-left::-webkit-scrollbar-track {
      -webkit-box-shadow: inset006pxrgba(255, 255, 255, 0.3);
      border-radius: 10px;
    }*/

    .bot-right {
      width: 100%;
      // width: calc(100% - 290px);
      // height: 100%;
      height: calc(100vh - 75px);
    }

    .el-loading-spinner {
      position: relative;
    }
  }

  //注册
  .reg,
  .login {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    z-index: 999999;
    width: 100%;
    height: 100%;
    .content {
      display: flex;
      width: 800px;
      height: 520px;
      // background-image: linear-gradient(#e66465, #9198e5);
      // background-color: #1a1c24;
      background-image: linear-gradient(
        rgb(15, 14, 29) 0%,
        rgb(33, 44, 56) 62%
      );
      background-image: -moz-linear-gradient(
        270deg,
        rgb(15, 14, 29) 0%,
        rgb(33, 44, 56) 62%
      );
      background-image: -webkit-linear-gradient(
        270deg,
        rgb(15, 14, 29) 0%,
        rgb(33, 44, 56) 62%
      );
      background-image: -ms-linear-gradient(
        270deg,
        rgb(15, 14, 29) 0%,
        rgb(33, 44, 56) 62%
      );
      position: absolute;
      left: 50% !important;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    .left {
      height: 100%;
      width: 300px;
      position: relative;
      img {
        width: 100%;
        height: auto;
      }
      .img-t {
        width: 250px;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .right {
      height: 100%;
      width: 500px;
    }
    .reg-warp {
      padding: 10px 60px 50px;
      display: block;
      color: white;
      // width: 25%;
      // background-color: red;
      // border-radius: 5px;
      // position: absolute;
      // left: 50% !important;
      // top: 50%;
      // transform: translate(-50%, -50%);

      .btn-x {
        width: 20px;
        height: 20px;
        text-align: center;
        line-height: 20px;
        border-radius: 50%;
        position: absolute;
        top: 8px;
        right: 8px;
        font-size: 15px;
        font-weight: bold;
      }

      .btn-x:hover {
        background-color: #444659;
        cursor: pointer;
      }

      .reg-sel {
        display: flex;
        justify-content: space-around;
        align-items: center;
        font-size: 20px;

        span:hover {
          cursor: pointer;
        }

        .span1 {
          //color: white;
          color: white;
          // padding-bottom: 4px;
          // border-bottom: 2px solid #e9b10e;
        }

        .span2 {
          color: white;
        }
      }

      .reg-hint {
        margin-top: 30px;
        padding: 10px 10px;
        background-color: #5c3c47;
        border-radius: 5px;
        color: #ff5c5c;
        font-size: 14px;
      }

      .input {
        margin-top: 20px;

        .input1-warp {
          position: relative;

          .getCode {
            position: absolute;
            right: 5px;
            top: 25px;
            color: #c3c3e2;
            font-size: 14px;
            background-color: #24252f;
            border: none;
            outline: none;
          }

          .getCode:hover {
            cursor: pointer;
          }
        }
        .input1 {
          margin-top: 15px;
        }

        .input1 /deep/ input.el-input__inner {
          // margin-top: 15px;
          border-radius: 8px;
          background-color: #24252f;
          border-color: #646464;
          color: currentColor;
          &:hover {
            border-color: #e82069;
          }
        }
      }

      .reg-btn {
        width: 100%;
        margin-top: 25px;

        .btn-sub {
          width: 100%;
          background-color: #ff2171;
          color: white;
          font-weight: 600;
          font-size: 16px;
          opacity: 0.9;
          border: 1px;
        }

        .btn-sub:hover {
          // background-color: #05e05d;
          opacity: 1;
          transition: 0.3s;
        }
      }

      .reg-deal {
        margin-top: 20px;
        font-size: 14px;
        color: white;

        strong {
          color: white;
          cursor: pointer;
        }
      }

      .reg-deals {
        margin-top: 10px;
        font-size: 14px;
        color: white;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        p {
          width: 100% !important;
          color: white;
          // word-wrap: break-word;
        }

        strong {
          color: white;
          cursor: pointer;
        }
      }

      .go-login {
        // text-align: center;
        font-size: 14px;
        color: white;
      }

      .go-login:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .login {
    .login-title {
      display: flex;
      justify-content: center;
      font-size: 20px;
      color: white;
    }

    .login-pass {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .login-pass1 /deep/ .el-checkbox__input .el-checkbox__inner {
        border-radius: 50%;
      }
      .login-pass1 /deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
      .el-checkbox__input.is-indeterminate .el-checkbox__inner {
        background-color: #e9b10e;
        border-color: #e9b10e;
        border-radius: 50%;
      }

      .login-pass1 /deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
        color: white;
      }

      span {
        font-size: 14px;
      }

      span:hover {
        cursor: pointer;
      }
    }

    .reg-deals {
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .login-pass1 {
        display: flex;
        align-items: center;
      }
      .login-pass1 /deep/ .el-checkbox__input .el-checkbox__inner {
        background-color: #1a1c24;
        border-color: #ff2171;
        border-radius: 50%;
      }
      .login-pass1 /deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
      .el-checkbox__input.is-indeterminate .el-checkbox__inner {
        background-color: #ff2171;
        border-color: #ff2171;
        border-radius: 50%;
      }

      /deep/.el-checkbox {
        white-space: initial !important;
      }

      .login-pass1 /deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
        // color: #e9b10e;
        border-radius: 50%;
      }

      span {
        font-size: 14px;
      }

      span:hover {
        cursor: pointer;
      }
    }

    .login-rest {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;

      span {
        font-size: 14px;
      }

      img {
        margin-top: 20px;
        width: 35px;
        height: 35px;
      }
    }

    .login-hint {
      height: 90px;
      width: 100%;
      position: absolute;
      bottom: -90px;
      text-align: center;
      left: 0;
      line-height: 20px;
      background-color: #24252f;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: white;
      font-size: 12px;
    }
  }
}

.reg {
  span {
    color: white;
  }
  p {
    color: white;
  }
  .reg-deal /deep/ .el-checkbox__input .el-checkbox__inner {
    background-color: #1a1c24;
    border-color: #ff2171;
    border-radius: 50%;
  }
  .reg-deal /deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #ff2171;
    border-color: #ff2171;
    border-radius: 50%;
  }

  .reg-deal /deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
    color: white;
  }
}

//右侧导航

.sty {
  z-index: 99999 !important;

  .sty-menu {
    height: 100%;
    background-color: #30313f;
  }

  .sty-btn {
    padding: 8px;
    background-color: #1f1f26;
    display: flex;
    justify-content: space-between;

    span {
      color: #1a1c24;
      font-size: 13px;
      font-weight: 600;
      width: 100px;
      text-align: center;
      height: 40px;
      line-height: 40px;
      border-radius: 5px;
    }

    span:hover {
      cursor: pointer;
    }

    /deep/ span:first-child:hover {
      background-color: #f4206d;
    }

    /deep/ .bg-blue {
      background-color: #f4206d;
    }

    /deep/ .bg-org:hover {
      background-color: #f4206d;
    }

    /deep/ .bg-org {
      background-color: #f4206d;
    }
  }

  .sty-next {
    position: absolute;
    bottom: 0;
    background-color: #24252f;
    width: 100%;
    padding: 12px 0;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    color: white;
  }

  .sty-next:hover {
    cursor: pointer;
    background-color: #2b2c37;
    color: #c3c3e2;
  }
}

/deep/ .el-drawer__wrapper {
  // top: 60px;
  padding-top: 60px;
}

/deep/ .el-drawer__header {
  display: none;
}

/deep/ .el-drawer__body {
  background-color: #30313f;
}

/deep/ .el-menu {
  border-right: none;
}
</style>

<style lang="less">
.right-bar {
  top: 50%;
  right: 0px;
  z-index: 1200;
  position: fixed;
  background: #000;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
  background-color: #1d1d21;
  padding: 10px 0;
  border-radius: 1px;
}

.bar-show {
  width: 60px;
  transition: width 0.7s ease-out;
  min-height: 250px;
}

.bar-hidden {
  width: 0;
  transition: width 0.7s ease-out;
  min-height: 250px;
}

.switch {
  top: 50%;
  left: -20px;
  cursor: pointer;
  position: absolute;
  margin-top: -35px;
}

.switch img {
  width: 20px;
  vertical-align: top;
}

.btn-group {
  min-width: 60px;

  .btn-hong {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    img {
      width: 80px;
      height: 80px;
    }
  }

  .btn-hong:hover {
    cursor: pointer;
  }

  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 30px;
    cursor: pointer;
    padding: 10px 0 10px 0;
    color: #c3c3e2;
    font-weight: 500;

    > img {
      width: 32px;
      height: 32px;
    }

    > span {
      font-size: 12px;
      margin-top: 8px;
    }

    position: relative;

    .tip-num {
      position: absolute;
      background-color: red;
      width: 20px;
      height: 20px;
      text-align: center;
      line-height: 20px;
      border-radius: 10px;
      color: #fff;
      font-size: 12px;
      top: 5px;
      left: 8px;
    }
  }

  .btn:hover,
  .btn:active {
    color: #1a1c24;
    background-color: #e9b10e;
  }
}

.func {
  position: absolute;
  display: flex;
  height: 44px;
  width: 100%;
  bottom: 0px;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  border-top: 1px solid #444659;

  .hidden {
    flex: 1;
    height: 44px;
    line-height: 44px;
    background-color: #30313f;
    cursor: default;
    color: #c3c3e2;
  }

  .hidden:hover {
    background-color: #3a4050;
    color: #c3c3e2;
    cursor: pointer;
  }

  .refresh {
    flex: 1;
    height: 44px;
    position: relative;
    background-color: #30313f;

    > img {
      height: 44px;
      width: 25px;
      height: 25px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .refresh:hover {
    color: #1a1c24;
    background-color: #3a4050;
    cursor: pointer;
  }

  .refresh:active {
    background-color: #1a1c24;
  }
}

//取回列表
.give-box {
  //background-color: #fff;
  background-color: #30313f;
  width: 100%;
  height: 150px;
  position: absolute;
  bottom: 40px;
  overflow: hidden;
  overflow-y: auto;

  ul {
    padding: 0 10px;

    li {
      margin-top: 10px;

      .give-true {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .give-left {
          display: flex;
          align-items: flex-start;

          .give-img {
            img {
              width: 40px;
              height: 40px;
              border-radius: 50%;
            }
          }

          .give-text {
            margin-left: 10px;
            display: flex;
            flex-direction: column;

            span:first-child {
              font-size: 14px;
              color: #c3c3e2;
            }

            span:last-child {
              font-size: 12px;
              color: white;
            }
          }
        }

        .give-right {
          font-size: 14px;
          background-color: #e9b10e;
          padding: 8px 22px;
          border-radius: 5px;
          color: #1a1c24;
        }

        .give-right:hover {
          background-color: #f5c432;
          cursor: pointer;
        }
      }

      .give-false {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .give-false-left {
          display: flex;
          align-items: center;

          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
          }

          span {
            margin-left: 10px;
            font-size: 14px;
            color: #c3c3e2;
          }
        }

        .give-false-right {
          padding: 8px 22px;
          font-size: 14px;
          color: #c3c3e2;
        }
      }
    }
  }
}

.give-box1 {
  margin-top: 30px;
  background-color: #30313f;
  width: 100%;
  height: 150px;
  line-height: 150px;
  position: absolute;
  bottom: 40px;
  display: flex;
  justify-content: center;
  color: #c3c3e2;
  font-size: 16px;
}
// 红包pc未领取
.hongbao {
  z-index: 9999999999;
  width: 350px !important;
  height: 570px;
  background-color: #15172b;
  border: 1px solid #4b56bb;
  border-radius: 20px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  .cancel {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: -23px;
    margin-right: -23px;
    z-index: 100;
    text-align: center;
    transform: rotate(0deg);
    transition: transform 0.3s linear;
    img {
      // width: 30px;
      // height: auto;
      transform: scale(0.6);
    }
    &:hover {
      transform: rotate(90deg);
      // transform-origin:50% 50%;
      transition: transform 0.3s linear;
      // animation: move 1;
    }
  }
  // @keyframes move {
  //   0%{transform: rotate(0deg);}
  //   100%{transform: rotate(90deg);}
  // }
  // .hong-x {
  //   position: absolute;
  //   z-index: 20;
  //   right: 10px;
  //     top: 10px
  // }
  .title {
    margin-top: 20px;
    font-size: 26px;
    font-weight: 1000;
    color: white;
  }
  .swiper {
    width: 250px;
    // height: 30px;
    overflow: hidden;
    color: white;
    // line-height: 30px;
    border-radius: 5px;
    padding-bottom: 10px;
    position: relative;
    h3 {
      display: flex;
      justify-content: center;
      font-size: 16px;
    }
    .money {
      width: 20px;
      // height: 15px;
      // margin-top: 10px;
      strong {
        // margin-top: -10px;
      }
    }
  }
  input {
    text-indent: 17px;
    width: 300px;
    height: 50px !important;
    border: 1px solid #1e224a;
    border-radius: 10px;
    font-size: 16px;
    // margin-top: 30px;
    background-color: #090b14;
    outline: none;
    color: white;
  }
  .button {
    margin-top: 30px;
    height: 50px;
    // width: 200px;
  }
}
// 移动端 红包未领取
@media screen and (max-width: 767px) {
  .hongbao {
    z-index: 999999999;
    width: 250px !important;
    height: 430px;
    // margin-left: 85px !important;
    position: fixed;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    .cancel {
    }
    .title {
      font-size: 16px !important;
    }
    .theme {
      width: 200px;
      height: 200px;
    }
    .swiper {
      // width: ;
      h3 {
        font-size: 14px !important;
      }
    }
    input {
      width: 200px;
      height: 50px !important;
      // margin-bottom: -10px;
    }
    .button {
      height: 45px;
      margin-bottom: 20px;
    }
  }
}

// 红包 已领取页面样式
.hongbao1 {
  z-index: 99999999999;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 350px !important;
  height: 150px;
  background-color: #15172b;
  border: 1px solid #4b56bb;
  border-radius: 20px;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    margin-top: 10px;
    font-size: 26px;
    font-weight: 1000;
    color: white;
  }
  .cont {
    margin-top: 10px;
    font-size: 26px;
    font-weight: 1000;
    color: white;
    display: flex;
    align-items: center;
    img {
      width: 30px;
      height: 30px;
      margin-top: 7px;
    }
  }
  button {
    width: 60px;
    margin-top: 20px;
    font-size: 20px;
    background-color: #e9b10e;
    border-radius: 6px;
    border-collapse: collapse;
  }
}
// 移动端红包已领取样式
@media screen and (max-width: 767px) {
  .hongbao1 {
    z-index: 99999999999;
    width: 250px !important;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: 170px;
    transform: translate(-50%, -50%);
    height: 130px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // margin-left: 72px !important;
    position: fixed;
    top: 30% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;

    .title {
      font-size: 20px;
    }
    .cont {
      font-size: 20px;
      margin-top: 10px !important;
    }
    button {
      font-size: 16px !important;
      margin-top: 10px !important;
      background-color: #e9b10e;
      border-radius: 6px;
      width: 110px;
      border-collapse: collapse;
    }
    padding: 10px;
  }
}

.pass {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.pc {
  width: 26rem !important;
}
.pass-box {
  position: fixed;
  width: 80%;
  /* margin-left: -150px; */
  padding: 16px;
  border-radius: 5px;
  background-color: #16171c;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .pass-hint {
    position: absolute;
    right: 10px;
    top: 10px;

    i {
      font-size: 20px;
      color: #c3c3e2;
    }

    i:hover {
      cursor: pointer;
    }
  }

  .pass-title {
    display: flex;
    justify-content: center;
    color: #ffffff;
    font-size: 1.2rem;
    font-weight: 600;
    margin: 1.8rem auto;
  }
  p {
    span {
      color: red;
    }
    text-align: center;
    color: #56565e;
    margin: 1rem 0px 2rem 0px;
  }
  .input {
    margin-top: 2rem;
    width: 90%;
    font-size: 1.25rem;
    position: relative;
    margin: auto;
    input {
      width: 100%;
      height: 3.125rem;
      outline: none;
      border: none;
      color: #fff;
      background: #0f1012;
      border-radius: 5px;
      padding: 0px 10px;
      box-sizing: border-box;
    }
    .all {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      font-size: 16px;
      color: #d4a10f;
    }
    input:focus {
      border: none;
    }
  }

  .pass-btn {
    display: flex;
    margin-top: 1.5rem;
    width: 100%;
    justify-content: center;
    margin-bottom: 1rem;
    .cancel,
    .cancel:hover {
      background: #896f25;
      border: 2px solid #d4a10f;
      margin-right: 20px;
    }
    .submit {
      background-color: #d4a10f;
    }
    button {
      width: 30%;
      color: #fff;
    }
  }
}
.cover {
  z-index: 9999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
}
.cover2 {
  z-index: 10001;
  width: 100%;
  height: 100%;
  background-color: #00000000;
  position: fixed;
}
</style>

<style>
.quhui-box cccccc.el-drawer__body .el-drawer .el-drawer.ltr,
.quhui-box .el-drawer.rtl,
.quhui-box .el-drawer__container .el-drawer__body {
  background-color: transparent !important;
  /* flex: none !important; */
  box-shadow: none !important;
}

/*.el-table {
  position: fixed !important ;
  bottom: 44px !important ;
  background-color: #30313f !important;
}
.el-table th,
.el-table tr {
  background-color: #30313f !important;
}*/
</style>

<style scoped lang="less">
.suoxiao {
  transform: scale(0.9, 0.9);
  border-radius: 10px;
  clip-path: polygon(
    12px 0,
    calc(100% - 12px) 0,
    100% 50%,
    calc(100% - 12px) 100%,
    12px 100%,
    0 50%
  );
}
.ripple {
  position: relative;
  /* //隐藏溢出的径向渐变背景 */
  overflow: hidden;
  /* width: 200;
    height: 100; */
  clip-path: polygon(
    12px 0,
    calc(100% - 12px) 0,
    100% 50%,
    calc(100% - 12px) 100%,
    12px 100%,
    0 50%
  );
}

/* .ripple:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  //设置径向渐变
  background-image: radial-gradient(circle, #666 15%, transparent 15.01%);
  background-repeat: no-repeat;
  background-position: 50%;
  transform: scale(15, 15);
  opacity: 0;
  transition: transform 0.8s, opacity 0.8s;
} */

/* .ripple:active:after {
  transform: scale(0, 0);
  opacity: 0.6;
  //设置初始状态
  transition: 0s;    customClass: 'messageStyle'
} */

.loging {
  background-color: #808080 !important;
}
.messageStyle {
  z-index: 999999999 !important;
}

.fuli {
  z-index: 99999;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  .bg {
    width: 1100px;
    height: 680px;
    // background-color: red;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: url(./img/fuli.png);
    background-size: 100% auto;
    padding: 0 45px;
    box-sizing: border-box;
    .cancel {
      position: absolute;
      top: 20px;
      right: 40px;
      cursor: pointer;
      transition: transform 0.3s linear;
      &:hover {
        transform: rotate(90deg);
      }
      img {
        width: 25px;
        height: auto;
      }
    }
    .cont {
      width: 100%;
      height: 520px;
      // background-color: yellow;
      margin-top: 145px;
      display: flex;
      .left {
        height: 97%;
        width: 120px;
        background-color: #252734;
        .isActive {
          border-radius: 20px;
          color: #ffc600;
          background-color: #30323e;
        }
        div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 120px;
          height: 120px;
          background-color: #252734;
          color: white;
          cursor: pointer;
          font-size: 16px;

          img {
            width: 40px;
            height: auto;
          }
        }
      }
      .right {
        width: 980px;
        height: 97%;
        background-color: #191b29;
        padding: 25px;
        box-sizing: border-box;
        color: white;
        .day {
          // background-color: red;
          .loginQ,
          .chargeQ {
            .title {
              width: 100%;
              display: flex;
              justify-content: space-between;
              font-family: "Microsoft YaHei";
              .rightT {
                cursor: pointer;
                span {
                  padding: 6px 22px;
                  border-radius: 5px;
                  background-color: #ffc600;
                  color: black;
                  font-weight: 900;
                  font-size: 16px;
                  font-family: "Microsoft YaHei";
                  // font-family:"微软雅黑";
                }
              }
              span {
                font-size: 18px;
              }
            }
            .lists {
              display: flex;
              justify-content: space-between;
              margin: 30px 0;
              .itemActive {
                background-color: rgba(30, 206, 119, 0.2) !important;
                border: 2px solid rgba(30, 206, 119, 0.88) !important;
              }
              .keActive {
                background-color: rgba(255, 198, 0, 0.2) !important;
                border: 2px solid rgba(255, 198, 0, 0.88) !important;
              }
              .item {
                width: 117px;
                height: 60px !important;
                height: auto;
                background-color: rgba(141, 42, 255, 0.5);
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 12px 12px 0 0;
                border: 2px solid rgba(141, 42, 255, 0.88);
                box-sizing: border-box;
                cursor: pointer;
                .cont1 {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                }
              }
            }
          }
        }
        .quest {
          .topNav {
            display: flex;
            div {
              width: 140px;
              height: 50px;
              border-radius: 7px;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: #252734;
              margin-right: 7px;
              cursor: pointer;
              img {
                width: 25px;
                height: 25px;
              }
              span {
                margin-left: 5px;
              }
            }
          }
          .boxActive {
            background-color: rgba(251, 198, 0, 0.3) !important;
            color: #fbc600 !important;
          }
          .main {
            width: 100%;
            height: 419px;
            margin-top: 10px;
            // overflow: scroll;
            // background-color: red;
            .box1,
            .box2,
            .box3 {
              overflow: scroll;
              height: 100%;
            }
            .boxs {
              width: 100%;
              height: 100px;
              background-color: #252734;
              border-radius: 10px;
              display: flex;
              align-items: center;
              margin-bottom: 4px;
              position: relative;
              box-sizing: border-box;
              .xian {
                height: 50px;
                width: 2px;
                background-color: #fbc600;
              }
              .bleft {
                margin-left: 30px;

                .c1 {
                  font-weight: bold;
                  font-size: 18px;
                }
                .c2 {
                  display: flex;
                  margin-top: 8px;
                  .img {
                    display: flex;
                    align-items: center;
                    img {
                      width: 20px;
                      height: auto;
                    }
                  }
                }
              }
              .bright {
                width: 100px;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                // padding: 10px 25px;
                position: absolute;
                top: 50%;
                right: 25px;
                transform: translate(0, -50%);
                background-image: url(./img/bg-go.png);
                background-size: 100% 100%;
                color: black;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
  .win-box {
    position: fixed;
    z-index: 10002;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 300px;
    width: 400px;
    // background-color: rgba(0, 0, 0, 0.7);
    display: grid;
    place-items: center;
  }
  .win {
    width: 450px;
    padding: 20px;
    background-color: #1c2230;
    position: relative;
    box-sizing: border-box;
    border-radius: 10px;

    .win-span2 {
      padding: 10px 0;
      height: 20px;
      background-color: #e9b10e;
      background-size: 100% 100%;
      border: 1px solid #ffea95;
      color: #000000;
      display: flex;
      font-weight: bold;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      margin-top: 15px;
    }

    .win-span2:hover {
      cursor: pointer;
    }
  }
}
.messageIndex {
  z-index: 999999999999999 !important; // 设置的值一定要比2007大，因为dialog的层级是2007
}

//  右边弹窗
.boxss {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}
.rightBoxs {
  z-index: 99;
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  padding: 20px 30px;
  box-sizing: border-box;

  .cancel {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: flex-end;
    img {
      width: 20px;
      height: auto;
    }
  }
  .user {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .chognzhi {
      width: 70px;
      height: 35px;
      border-radius: 3px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #ffc600;
      font-size: 14px;
      color: black;
      margin-right: 10px;
    }
    .admin {
      display: flex;
      align-items: center;
      margin-left: 20px;
      img {
        width: 40px;
        height: 40px;
        // background-color: #02bf4d;
        border-radius: 50%;
      }
      div {
        display: flex;
        flex-direction: column;
        margin-left: 3px;
        font-size: 14px;
        color: white;
        span {
          font-size: 12px;
        }
      }
    }
  }
  .titleGo {
    width: 100%;
    height: 63px;
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    img {
      height: 100%;
      width: auto;
    }
  }
  .list {
    margin: 20px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    color: white;
    // background-color: red;
    font-size: 12px;
    .title {
      // width: 100%;
      margin-bottom: 20px;
      color: rgba(128, 126, 126, 0.651);
    }
    .conts {
      width: 100%;
      display: flex;
      justify-content: space-around;
      div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 50px;
        .img {
          width: 50px;
          height: 50px;
          box-sizing: border-box;
          padding: 12.5px;
          background-color: #171923;
          margin-bottom: 7px;
          img {
            width: 25px;
            height: auto;
          }
        }
      }
    }
  }
  .goout {
    // position: absolute;
    // bottom: 7px;
    // left: 50%;
    // transform: translate(-50%, 0);
    margin-top: 30px;
    border-radius: 5px;
    color: white;
    width: calc(80vw - 40px);
    height: 40px;
    background-color: #171923;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    margin-left: 10px;
    .s1 {
      margin-top: 2px;
    }
    span {
      margin-left: 5px;
    }
  }
  .login {
    width: 230px;
    color: #fff;
    background: linear-gradient(
      to right,
      rgba(39, 174, 96, 0) 0%,
      rgba(39, 174, 96, 0.3) 100%
    );
    border: 2px solid #27ae60;
    border-radius: 99px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    text-align: center;
    margin: 20px auto;
    position: static;
    margin-top: 0px;
  }
  .mobileMenu {
    color: #8494a7;
    font-size: 20px;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 60px;
    .menu-item {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-evenly;
      margin-bottom: 20px;
    }
    .menu-title {
      width: 60%;
      text-align: center;
    }
  }
}

.slide-fade-enter-active {
  transition: all 0.5s ease;
}
.slide-fade-leave-active {
  transition: all 0.5s ease;
  // transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
  transform: translateX(20px);
  opacity: 0;
}

// 7天领取完成后的奖励弹窗
.win-box {
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  height: 100vh !important;
  width: 100vw !important;
  background-color: rgba(0, 0, 0, 0.7);
  display: grid;
  place-items: center;
}

.win-conm {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .win-tips {
    height: 60px;
    color: #fff;
    font-size: 16px;
    line-height: 60px;
    font-weight: bold;
  }

  ul {
    margin: -5px;
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;

    li {
      .win-warp {
        border-radius: 5px;
        overflow: hidden;
        // width: 100%;
        cursor: pointer;
        position: relative;

        .win-text {
          text-overflow: ellipsis;
          position: absolute;
          width: 100%;
          text-align: center;
          left: 0;
          right: 0;
          bottom: 0;
          margin-left: auto;
          margin-right: auto;
          bottom: 30px;
          font-size: 8px !important;
          color: white;
        }

        .win-textt {
          width: 100%;
          padding: 6px 0;
          background-color: #e9b10e;
          background-size: 100% 100%;
          border: 1px solid #ffea95;
          color: #000000;
          // padding: 10px;
          //overflow: hidden;
          //white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 10px;
        }
      }
    }
  }
}
.win {
  width: 450px;
  padding: 20px;
  background-color: #1c2230;
  position: relative;
  box-sizing: border-box;
  border-radius: 10px;
  z-index: 10000001;
  .win-span2 {
    padding: 10px 0;
    height: 20px;
    background-color: #e9b10e;
    background-size: 100% 100%;
    border: 1px solid #ffea95;
    color: #000000;
    display: flex;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin-top: 15px;
  }

  .win-span2:hover {
    cursor: pointer;
  }
}

.advertisement {
  // width: calc(100% - 395px);
  width: calc(100% - 0px);
  height: 35px;
  background-color: black;
  margin: 10px 0;
  // margin-left: 197px;
  color: white;
  display: flex;
  align-items: center;
  background: linear-gradient(
    90deg,
    rgba(230, 157, 93, 0) 0%,
    rgba(230, 157, 93, 0.4) 18%,
    rgba(230, 157, 93, 0.67) 49%,
    rgba(230, 157, 93, 0.4) 85%,
    rgba(230, 157, 93, 0) 100%
  );
  @media (max-width: 550px) {
    width: 100vw;
    height: 45px;
    margin-left: 0px;
    box-sizing: border-box;
    // margin-top: -74px;
  }
  .laba {
    width: 40px;
    height: 100%;
    // background-color: #38373C;
    padding-top: 3px;
    padding-left: 2px;
    box-sizing: border-box;
    z-index: 99;
    transform: scale(1.1);
  }
  .span {
    width: 100%;
    overflow: hidden;
    .wenzi {
      width: 85%;
      transform: translateX(0%);
      animation: scrols 15s infinite linear;
      @media (max-width: 550px) {
        width: 100%;
        animation: none;
        font-size: 12px;
      }
      &:hover {
        animation-play-state: paused;
      }
    }
  }
}

@keyframes scrols {
  from {
    transform: translateX(1620px);
  }

  to {
    transform: translateX(-100%);
  }
}

.openhong {
  width: 730px;
  height: 600px;
  position: fixed;
  border-radius: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // background-color: aqua;
  background-image: url(./img/hongbao-bg.png);
  background-size: 100% 100%;
  z-index: 10000;
  padding: 25px;
  box-sizing: border-box;
  .close {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    transition: transform 0.3s linear;
    opacity: 0.6;
    &:hover {
      transform: rotate(90deg);
    }
    img {
      width: 17px;
      height: auto;
    }
  }
  .title {
    font-size: 24px;
    font-weight: 700;
    color: #fff;
  }
  .topss {
    width: 100%;
    height: 110px;
    line-height: 110px;
    margin-top: 20px;
    position: relative;
    padding: 0 20px;
    box-sizing: border-box;
    .all {
      width: 100%;
      height: 110px;
      display: flex;
      align-items: center;
      background-color: rgba(105, 23, 255, 0.5);
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: -80px;
        width: 60px;
        height: 70px;
        border: 20px solid rgba(105, 23, 255, 0.5);
        border-top-color: transparent;
        border-left-color: transparent;
        border-bottom-color: transparent;
      }
      &:after {
        content: "";
        position: absolute;
        top: 0;
        right: -80px;
        width: 60px;
        height: 70px;
        border: 20px solid rgba(105, 23, 255, 0.5);
        border-top-color: transparent;
        border-right-color: transparent;
        border-bottom-color: transparent;
      }
    }
    span {
      width: 100px;
      height: 45px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ff31ed;
      cursor: pointer;
      margin-left: 20px;
    }
    input {
      // margin: 20px;
      background-color: hsla(0, 0%, 100%, 0.8);
      color: #000;
      width: 460px;
      height: 45px;
      outline: none;
      border: none;
      border-radius: 5px;
      padding: 15px;
      box-sizing: border-box;
    }
  }
  .cont {
    width: 100%;
    height: 66%;
    // background-color: red;
    margin-top: 20px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 1px;
      background-color: #6917ff !important;
    }
    &::-webkit-scrollbar-button {
      // display: none;
      background-color: #6917ff;
      display: none;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #6917ff !important;
    }
    .item {
      width: 100%;
      height: 175px;
      background: hsla(0, 0%, 100%, 0.05);
      margin-bottom: 5px;
      border-radius: 8px;
      padding: 20px;
      box-sizing: border-box;
      position: relative;
      .xian {
        position: absolute;
        left: 0;
        top: 22.5%;
        width: 2px;
        height: 55%;
        background-color: #6917ff;
      }
      .shang {
        width: 100%;
        // height: 50px;
        height: 35%;
        background-color: #6917ff;
        color: white;
        padding: 0 20px;
        box-sizing: border-box;
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left {
          font-size: 18px;
          font-weight: 700;
        }
        .right {
          color: #d1d1d1;
        }
      }
      .xia {
        width: 100%;
        height: 50%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 3%;
        // background-color: rgba(189, 187, 187, 0.486);
        .left {
          height: 100%;
          display: flex;
          align-items: center;
          .content {
            display: flex;
            flex-direction: column;
            margin-left: 10px;
            font-size: 13px;
            color: #d1d1d1;
            .t1 {
              font-size: 18px;
              color: white;
              margin-bottom: 8px;
            }
          }
          img {
            width: auto;
            height: 90%;
          }
        }
        .right {
          width: 78px;
          height: 43px;
          background-image: url(./img/button-bg.png);
          background-size: 100% 100%;
          line-height: 45px;
          text-align: center;
          color: white;
          font-weight: 700;
          cursor: pointer;
        }
      }
    }
  }
}
.box-left {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  display: flex;
  width: 12rem;
  flex-shrink: 0;
  flex-direction: column;
  padding-bottom: 0.25rem;
  background: rgb(18 19 37);
  .drop-feed__header {
    width: 11rem;
    height: 76px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
    }
  }
  .socketService {
    .drop-feed__sort {
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom-width: 1px;
      background: linear-gradient(to right, #121325, #1f2040);
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      border-top: 1px solid rgb(41 42 61);
      border-bottom: 1px solid rgb(41 42 61);
      & > div {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        cursor: pointer;
        padding: 0.5rem 0.375rem;
      }
      .sort-item {
        height: 1.5rem;
        width: 1.5rem;
        background-repeat: no-repeat;
        background-position: 1px 1px !important;
      }
      & > div:nth-child(1) .sort-item {
        background: url("../assets/img/clock-gray.svg");
        &:hover {
          background: url("../assets/img/clock-white.svg");
        }
      }
      & > div:nth-child(2) .sort-item {
        background: url("../assets/img/thumb-up-gray.svg");
        &:hover {
          background: url("../assets/img/thumb-up-white.svg");
        }
      }
      & > div:nth-child(3) .sort-item {
        background: url("../assets/img/star-gray.svg");
        &:hover {
          background: url("../assets/img/star-white.svg");
        }
      }
    }
    .drop-feed__items-column {
      position: relative;
      z-index: 0;
      height: calc(100vh - 76px - 50px);
      overflow: hidden;
    }
  }
}
.box-right {
  width: calc(100% - 12rem);
  margin-left: 12rem;
  .top-right-logo {
    display: none;
  }
}
.menu {
  display: none;
}
@media screen and (max-width: 767px) {
  .top-right-logo {
    display: block !important;
    width: 170px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    img {
      width: 100%;
    }
  }

  .loginStatus {
    left: 38%;

    img {
      width: 70%;
    }
  }
  .box-left {
    display: none;
  }
  .box-right {
    width: 100%;
    margin-left: 0rem;
  }
  .home .top {
    width: 100%;
    background: #121325;
    z-index: 999;
  }
  .home .top .top-l-r {
    position: relative;
  }
  .menu {
    display: block !important;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    img {
      width: 33px;
      height: 30px;
    }
  }
  .top-right {
    display: none !important;
  }
}
.language {
  width: 1.5rem;
  margin-right: 2rem;
  img {
    width: 100%;
  }
}
/deep/.el-dialog__header {
  padding: 0px !important;
}
/deep/.el-dialog__body {
  background: #181d2c !important;
  border: transparent !important;
  padding: 0px !important;
}
.language-box {
  position: relative;
  min-height: 415px;
  // background: #121325;
  .language-top {
    font-size: 1.125rem;
    line-height: 1.75rem;
    color: #fff;
    padding: 20px;
  }
  .language-content {
    background: #1e2336;
    padding: 10px 20px;
    .content-list {
      display: flex;
      flex-direction: column;
      .item {
        display: flex;
        align-items: center;
        padding: 0px 6px;
        &:hover {
          background: #292f47;
        }
      }
      img {
        width: 30px;
        margin-right: 10px;
      }
      .active {
        background: #292f47;
      }
    }
  }
  .language-bottom {
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    .save {
      background-image: linear-gradient(
        90deg,
        transparent,
        rgba(61, 90, 254, 25%)
      );
      border: 2px solid rgb(61 90 254);
      padding: 0px 40px;
      border-radius: 50px;
      height: 36px;
      line-height: 36px;
      color: #fff;
    }
  }
}
@media screen and (max-width: 767px) {
  .language {
    position: absolute;
    // position: fixed;
    top: 50%;
    transform: translateY(-50%);
    left: 4%;
    width: 2rem !important;
  }
}
/deep/.el-tooltip__popper {
  z-index: 9999999999 !important;
}
.moblieAvatar {
  position: absolute;
  top: 3%;
  top: 2%;
  left: 30%;
  display: flex;
  align-items: center;
  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  .nickname {
    color: #fff;
    width: 10rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.button-green {
  color: #fff;
  background: linear-gradient(
    to right,
    rgba(39, 174, 96, 0) 0%,
    rgba(39, 174, 96, 0.3) 100%
  );
  font-size: 16px;
  border-right: none;
  position: relative;
  line-height: 40px;
  height: 40px;
  float: right;
  padding-left: 14px;
  padding-right: 54px;
  margin: 95px 20px 0 0;
  top: -80px;
  transition: 0.35s top;
  border: 2px solid #27ae60;
  border-radius: 99px;
  .button-plus {
    position: absolute;
    width: 40px;
    background: url("../assets/img/plus_bold.svg") no-repeat 50% 50%;
    border-radius: 50%;
    border: 2px solid #27ae60;
    top: -1px;
    width: 39px;
    height: 39px;
    right: 0;
  }
}
.moblieGoout {
  display: block;
  width: 33px;
  height: 26px;
  margin: 25px 10px 0 0;
  padding: 0;
  background: url("../assets/img/logout.svg") no-repeat left 50%;
}
.top-right2 {
  .button-green {
    top: 0px !important;
    margin: 0px !important;
    margin-right: 20px !important;
  }
  .moblieGoout {
    margin: 0px !important;
  }
}
</style>
<style lang="less">
.message-styles {
  bottom: 20px !important;
  right: 0 !important;
  top: inherit !important;
  left: inherit !important;
  transform: translateX(-10px) !important;
  padding: 25px 25px 25px 60px !important;
  width: 300px !important;
  border-radius: 0px !important;
  // background: #d9534f !important;
  border: none !important;
  color: #fff !important;
  p {
    color: #fff !important;
  }
}
.el-message .el-icon-error,
.el-message .el-icon-success {
  color: #fff !important;
  font-size: 40px;
}
.el-message__icon:before {
  content: "" !important;
  width: 40px !important;
  height: 40px !important;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 24px;
}
.el-icon-error:before {
  background: url("../assets/img/tooltip-error.png") no-repeat 50% 50% !important;
}
.el-icon-success:before {
  background: url("../assets/img/tooltip-ok.png") no-repeat 50% 50% !important;
}
.el-message--success {
  background: #5cb85c !important;
}
.el-message--error {
  background: #d9534f !important;
}
// 媒体查询
@media screen and (max-width: 767px) {
  .message-styles {
    width: 70% !important;
    min-width: auto !important;
  }
}
.el-dialog {
  background: none !important;
}
// 支付
.payment-box {
  .tabs {
    display: flex;
    flex-wrap: wrap;
    border: none;
    background: #131723;
    border-bottom: 1px solid #1e2335;
    padding-right: 65px;
    .item {
      line-height: 70px;
      padding: 0 30px;
      transition: 0.3s;
      color: #aeb9c9;
      font-weight: 700;
      cursor: pointer;
    }
    .item.selected {
      color: #fff;
      background: #1e2335;
      cursor: default;
    }
    .close {
      position: absolute;
      top: 3px;
      right: 5px;
      padding: 5px;
      cursor: pointer;
      font-size: 120%;
      display: inline-block;
      font-weight: 700;
      font-family: Roboto, "sans-serif";
      background: url("../assets/img/close.svg") no-repeat 50% 50%;
      padding: 32px;
      opacity: 0.8;
    }
  }
}
.payment-content {
  padding: 30px;
  .payment-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .item {
      width: calc(90% / 6);
      height: 6vw;
      text-align: center;
      line-height: 6vw;
      background: #131723;
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      position: relative;
      transition: all 0.3s;

      .symbol,
      .money {
        display: inline-flex;
        align-items: flex-start;
        color: #0fe274;

        text-shadow: 0px 0px 11.1642px rgba(15, 226, 116, 0.21),
          0px 0px 11.1642px rgba(15, 226, 116, 0.21),
          0px 0px 11.1642px rgba(15, 226, 116, 0.21),
          0px 0px 11.1642px rgba(15, 226, 116, 0.21),
          0px 0px 11.1642px rgba(15, 226, 116, 0.21),
          0px 0px 11.1642px rgba(15, 226, 116, 0.21),
          0px 0px 11.1642px rgba(15, 226, 116, 0.21),
          0px 0px 11.1642px rgba(15, 226, 116, 0.21);
      }
      .symbol {
        font-weight: 700;
        font-size: 1vw;
        // position: absolute;
        // top: -0.8vw;
        // left: 1.4vw;
      }
      .money {
        font-weight: 700;
        font-size: 1.8vw;
      }
    }
    .item.selected {
      border: 1px solid rgba(15, 226, 116, 0.21);
    }
  }
}
.payment-bottom {
  width: 100%;
  padding: 2vw 0;

  .payment-buy {
    width: 4vw;
    margin: 0 auto;
    color: #fff;
    background: linear-gradient(
      to right,
      rgba(39, 174, 96, 0) 0%,
      rgba(39, 174, 96, 0.3) 100%
    );
    text-decoration: none;
    display: block;
    border: 2px solid #27ae60;
    border-radius: 99px;
    line-height: 40px;
    font-size: 14px;
    text-align: center;
    font-family: inherit;
    cursor: pointer;
  }
}
.mobile-userarea {
  position: absolute;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  right: 70px;
  flex-direction: row;
}

.userarea-wallet {
  color: #7186fe;
  text-align: right;
  line-height: 13px;
  font-size: 13px;
  font-weight: 500;
}

.mobile-userarea__profil {
  cursor: pointer;
}

.userarea-wallet__icon {
  vertical-align: bottom;
  width: 14px;
  height: 14px;
}

.userarea-nickname {
  color: #8494a7;
  text-align: right;
  line-height: 22px;
  font-weight: 600;
  font-size: 13px;
}

.userarea-upbalance {
  margin-left: 11px;
  width: 32px;
  height: 32px;
  margin-bottom: auto;
  cursor: pointer;
}
.payment {
  top: -20% !important;
}
.payment-services {
  color: #0fe274;
  font-weight: 700;
  position: relative;
  margin-left: 30px;
}
.payment-services::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  background: #0fe274;
  bottom: -4px;
  left: 0;
}
.m-giveaway {
  position: relative;
  flex-direction: column;
  .menu-item {
    img {
      width: 1.8rem;
    }
  }
  .giveaway-menu {
    font-weight: normal;
    font-size: 1rem;
  }
}
@media screen and (max-width: 767px) {
  .payment-content .payment-list .item {
    width: calc(90% / 3) !important;
    height: 20vw !important;
  }
  .payment-content .payment-list .item .symbol {
    font-size: 3vw !important;
  }
  .payment-content .payment-list .item .money {
    font-size: 3.8vw !important;
  }
  .payment-bottom .payment-buy {
    width: 22vw !important;
  }
}
</style>
<template>
  <div
    class="openbox"
    :style="{
      backgroundImage: 'url(' + img + ')',
    }"
  >
    <div class="openbox">
      <!--中奖完成后盒子-->
      <div class="win-box" v-if="winState">
        <mywin
          :winList="winList"
          :winState="winState"
          :isis="false"
          @winexchange="winexchange($event)"
          @winget="winget($event)"
          @winX="winX($event)"
        ></mywin>
      </div>
      <div class="clear"></div>
      <!-- 箱子信息加载loading效果 -->
      <!-- <div
        v-show="loadFlag"
        v-loading="loadFlag"
        :element-loading-text="$t('public.loading')"
        class="kai-masked"
      ></div> -->
      <!-- 箱子主体信息 -->
      <div v-show="box_name" class="kai-con" v-if="showNav">
        <div class="con-list">
          <div class="openbox-container_header breadcrumb">
            <div class="cat-breadcrumb" @click="$router.go(-1)">
              {{ type_name?type_name:'CSGOOP' }}
            </div>
            <div class="ng-binding">
              <img  src="../assets/img/chevron-down-1.svg" alt="" />
              {{ box_name }}
            </div>
          </div>
          <ul>
            <li v-for="item in kaiBox1" :key="item">
              <div class="conlist-warp">
                <div class="type_name">{{ box_name }}</div>
                <!-- 控制声音按钮 -->
                <div class="sound" @click="toggleSound">
                  <img
                    src="../assets/img/sound.svg"
                    alt=""
                    :class="{ 'sound-0': shengyin }"
                  />
                </div>
                <div class="conlist-box-outside" v-if="!kaiState">
                  <div class="conlist-box1" v-for="i in kaiBox" :key="i">
                    <img :src="box_obj.image" />
                    <img
                      :style="!box_obj.active_image ? 'display: none;' : ''"
                      :src="box_obj.active_image"
                    />
                  </div>
                </div>
                <!-- 开箱动画 -->
                <div class="kai-box" v-if="kaiState">
                  <img
                    class="dingbu"
                    src="../assets/img/openBox/shadow-top.png"
                    alt=""
                  />
                  <div
                    class="public-css"
                    :class="[
                      showNav == false ? 'kai-box-open2m' : 'kai-box-open2',
                    ]"
                    v-if="openBoxState && kaiBox == 1"
                  >
                    <div class="kai-warp11">
                      <div>
                        <ul
                          :style="{
                            '--animation-duration': animationDuration + 's',
                          }"
                        >
                          <li
                            v-for="(item, index) in imgList"
                            :key="index"
                            :class="{ opacity_04: isFinish && item.prop != 0 }"
                          >
                            <div
                              class="border"
                              :class="'gradient-color-' + item.color"
                            ></div>
                            <div
                              class="item-img"
                              :class="'gradient-bg-color-' + item.color"
                            >
                              <img
                                class="lottery-item-img item-image-filter"
                                :src="item.img"
                              />
                            </div>
                            <div class="item-name">
                              <span
                                >{{ getExteriorName(item) }}<br /><span
                                  class="item-name-thin"
                                  >{{ getShortName(item) }}</span
                                ></span
                              >
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <!-- 2个盒子打开动画  v-if="openBoxState && kaiBox != 1" -->
                  <div
                    class="public-css"
                    :class="[
                      showNav == false ? 'kai-box-open22m' : 'kai-box-open22',
                    ]"
                    v-if="openBoxState && kaiBox >= 2"
                  >
                    <!-- <img
                      class="dingbu"
                      src="../assets/img/openBox/shadow-top.png"
                      alt=""
                    /> -->
                    <div class="kai-warp11">
                      <div
                        :class="{
                          'kaibox-warp kaibox-warp2 ': kaiBox >= 2,
                        }"
                      >
                        <!-- <ul class="kaibox-ul"> -->
                        <ul :class="['kaibox-ul', 'an' + kaiBox]">
                          <li
                            :class="['kaibox-li', 'li' + kaiBox]"
                            v-for="(item, index) in imgList1"
                            :key="index"
                            :style="{
                              '--animation-duration': animationDuration + 's',
                            }"
                          >
                            <ul>
                              <!-- backgroundImage: 'url(' + item1.background + ')', -->
                              <li
                                v-for="(item1, index1) in item"
                                :key="index1"
                                :class="{
                                  opacity_04: isFinish && item1.prop != 0,
                                }"
                              >
                                <div
                                  class="border"
                                  :class="'gradient-color-' + item1.color"
                                ></div>
                                <div
                                  class="item-img"
                                  :class="'gradient-bg-color-' + item1.color"
                                >
                                  <img
                                    class="lottery-item-img item-image-filter"
                                    :src="item1.img"
                                  />
                                </div>
                                <div class="item-name">
                                  <span
                                    >{{ getExteriorName(item1) }}<br /><span
                                      class="item-name-thin"
                                      >{{ getShortName(item1) }}</span
                                    ></span
                                  >
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <!-- <img
                      class="dibu"
                      src="../assets/img/openBox/shadow-down.png"
                      alt=""
                    />
                    <div class="lottery-vert-line" style="opacity: 1"></div> -->
                  </div>
                  <img
                    class="dibu"
                    src="../assets/img/openBox/shadow-down.png"
                    alt=""
                  />
                  <div class="lottery-vert-line" style="opacity: 1"></div>
                </div>
                <!-- 开箱中按钮 -->
                <div v-if="kaiState" class="Opening">
                  {{ $t("openBox.Opening") }}...
                </div>
              </div>
            </li>
          </ul>
          <div class="openbox-container_footer" v-if="!kaiState">
            <div class="choose-num" v-if="is_activity != 1">
              <!-- 选择数量 -->
              <div class="dropdown" :class="'color' + box_colour" v-if="type==null">
                <span
                  >{{ kaiBox }}
                  <i
                    class="el-icon-caret-bottom"
                    style="color: #777777; font-size: 12px"
                  ></i
                ></span>
                <div class="dropdown-content">
                  <a href="#" v-for="num in 5" @click="kaiBox = num">{{
                    num
                  }}</a>
                </div>
              </div>
              <div
                class="open-btn"
                :class="'color' + box_colour"
                @click="buyBox()"
              >
                {{ $t("openBox.title") }} {{ (price * kaiBox).toFixed(2) }}
              </div>
              <!-- 控制动画 -->
              <div
                class="cartState"
                :class="['color' + box_colour, { 'cartState-0': cartState }]"
                @click="toggleCartState()"
              >
                <img src="../assets/img/openBox/lightning.svg" alt="" />
              </div>
            </div>
          </div>
          <!-- 免费箱子描述 -->
          <div class="free-box-desc" v-if="type == 'free'">
            {{ box_obj.describe }}
          </div>
        </div>
      </div>
      <!-- 箱子主体信息 -->
      <div v-show="box_name" class="kai-conm" v-else>
        <!-- <img
          src="@/assets/new/hot-recommond.png"
          class="topic"
          alt=""
          srcset=""
          style="margin-top: 32px"
        /> -->
        <div class="con-list moblie-box-info">
          <div class="openbox-container_header breadcrumb">
            <div class="cat-breadcrumb" @click="$router.go(-1)">
              {{ type_name?type_name:'CSGOOP' }}
            </div>
            <div class="ng-binding">
              <img  src="../assets/img/chevron-down-1.svg" alt="" />
              {{ box_name }}
            </div>
          </div>
          <div class="box-header">{{ box_name }}</div>
          <!-- 控制声音 -->
          <div class="sound" @click="toggleSound">
            <img
              src="../assets/img/sound.svg"
              alt=""
              :class="{ 'sound-0': shengyin }"
            />
          </div>
          <div
            :class="kaiBox > 1 ? 'conlist-box-outside' : ''"
            v-if="!kaiState"
          >
            <div
              class="conlist-box1"
              :class="kaiBox > 1 ? 'w50' : ''"
              v-for="i in kaiBox"
              :key="i"
            >
              <img :src="box_obj.image" />
              <img
                :style="!box_obj.active_image ? 'display: none;' : ''"
                :src="box_obj.active_image"
              />
            </div>
          </div>
          <!-- 开箱动画 -->
          <div class="kai-box" v-if="kaiState">
            <img
              class="dingbu"
              src="../assets/img/openBox/shadow-top.png"
              alt=""
            />
            <div
              class="public-css"
              :class="[showNav == false ? 'kai-box-open2m' : 'kai-box-open2']"
              v-if="openBoxState && kaiBox == 1"
            >
              <div class="kai-warp11">
                <div>
                  <ul
                    :style="{ '--animation-duration': animationDuration + 's' }"
                  >
                    <li
                      v-for="(item, index) in imgList"
                      :key="index"
                      :class="{ opacity_04: isFinish && item.prop != 0 }"
                    >
                      <div
                        class="border"
                        :class="'gradient-color-' + item.color"
                      ></div>
                      <div
                        class="item-img"
                        :class="'gradient-bg-color-' + item.color"
                      >
                        <img
                          class="lottery-item-img item-image-filter"
                          :src="item.img"
                        />
                      </div>
                      <div class="item-name">
                        <span
                          >{{ getExteriorName(item) }}<br /><span
                            class="item-name-thin"
                            >{{ getShortName(item) }}</span
                          ></span
                        >
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <!-- 2个盒子打开动画  v-if="openBoxState && kaiBox != 1" -->
            <div
              class="public-css"
              :class="[showNav == false ? 'kai-box-open22m' : 'kai-box-open22']"
              v-if="openBoxState && kaiBox >= 2"
            >
              <!-- <img
                class="dingbu"
                src="../assets/img/openBox/shadow-top.png"
                alt=""
              /> -->
              <div class="kai-warp11">
                <div
                  :class="{
                    'kaibox-warp kaibox-warp2 ': kaiBox >= 2,
                  }"
                >
                  <!-- <ul class="kaibox-ul"> -->
                  <ul :class="['kaibox-ul', 'an' + kaiBox]">
                    <li
                      :class="['kaibox-li', 'li' + kaiBox]"
                      v-for="(item, index) in imgList1"
                      :key="index"
                      :style="{
                        '--animation-duration': animationDuration + 's',
                      }"
                    >
                      <ul>
                        <!-- backgroundImage: 'url(' + item1.background + ')', -->
                        <li
                          v-for="(item1, index1) in item"
                          :key="index1"
                          :class="{
                            opacity_04: isFinish && item1.prop != 0,
                          }"
                        >
                          <div
                            class="border"
                            :class="'gradient-color-' + item1.color"
                          ></div>
                          <div
                            class="item-img"
                            :class="'gradient-bg-color-' + item1.color"
                          >
                            <img
                              class="lottery-item-img item-image-filter"
                              :src="item1.img"
                            />
                          </div>
                          <div class="item-name">
                            <span
                              >{{ getExteriorName(item1) }}<br /><span
                                class="item-name-thin"
                                >{{ getShortName(item1) }}</span
                              ></span
                            >
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
                <!-- <img
                  :class="['dibu', 'dibu' + kaiBox]"
                  src="../assets/img/openBox/lottery_bg.png"
                  alt=""
                /> -->
              </div>
              <!-- <img
                class="dibu"
                src="../assets/img/openBox/shadow-down.png"
                alt=""
              /> -->
              <!-- <div class="lottery-vert-line" style="opacity: 1"></div> -->
            </div>
            <img
              class="dibu"
              src="../assets/img/openBox/shadow-down.png"
              alt=""
            />
            <div class="lottery-vert-line" style="opacity: 1"></div>
          </div>
          <!-- 开箱中按钮 -->
          <div v-if="kaiState" class="Opening">Opening...</div>
          <div class="openbox_footer" v-if="!kaiState">
            <div class="choose-num" v-if="is_activity != 1">
              <!-- 选择数量 -->
              <div class="dropdown" :class="'color' + box_colour" v-if="type==null">
                <span
                  >{{ kaiBox }}
                  <i
                    class="el-icon-caret-bottom"
                    style="color: #777777; font-size: 12px"
                  ></i
                ></span>
                <div class="dropdown-content">
                  <a href="#" v-for="num in 5" @click="selectNum(num)">{{
                    num
                  }}</a>
                </div>
              </div>
              <div
                class="open-btn"
                :class="'color' + box_colour"
                @click="buyBox()"
              >
                {{ $t("openBox.title") }} {{ (price * kaiBox).toFixed(2) }}
              </div>
              <!-- 控制动画 -->
              <div
                class="cartState"
                :class="['color' + box_colour, { 'cartState-0': cartState }]"
                @click="toggleCartState()"
              >
                <img src="../assets/img/openBox/lightning.svg" alt="" />
              </div>
            </div>
          </div>
           <!-- 免费箱子描述 -->
           <div class="free-box-desc" v-if="type == 'free'">
            {{ box_obj.describe }}
          </div>
        </div>
      </div>
      <!-- <div class="kai-num">
        <span></span>
      </div> -->
    </div>
    <div class="box-list">
      <div class="act_fallRecent">{{ $t("openBox.act_fallRecent") }}</div>
      <div class="xiangzi">
        <ul>
          <li v-for="(item, index) in skin_list" :key="index" class="card">
            <div class="border" :class="'gradient-color-' + item.color"></div>
            <div class="boxlist-warp">
              <div class="list-pirce">
                <div class="pirce-right" v-if="item.probability != 0">
                  <span>{{ item.probability }}%</span>
                </div>
                <div class="pirce-left">
                  <img src="../assets/img/money.png" />
                  <div>
                    <span>{{ item.price }}</span>
                  </div>
                </div>
              </div>
              <div class="item-background ng-scope" v-if="!showNav">
                <div class="item-main-background"></div>
                <div class="item-background-elements">
                  <div class="item-image-background"></div>
                  <div
                    class="item_image_background_1"
                    :class="'item_image_background_1_' + item.color"
                  ></div>
                  <div
                    class="item_image_background_2"
                    :class="'item_image_background_2_' + item.color"
                  ></div>
                </div>
              </div>
              <div class="boxlist1-top">
                <img :src="item.img" class="item-image-filter" />
              </div>
              <div class="boxlist1-bottom">
                <div class="boxlist1-bot" :title="item.name">
                  {{ getShortName(item) }}
                </div>
                <div class="boxlist1-bot2" :title="item.name">
                  <span>{{ getExteriorName(item) }}</span>
                </div>
              </div>
            </div>
          </li>
          <li class="card"></li>
          <li class="card"></li>
          <li class="card"></li>
          <li class="card"></li>
          <li class="card"></li>
          <li class="card"></li>
          <li class="card"></li>
        </ul>
      </div>
      <!-- <div class="boxlist-top">
				<div class="left">
					<span :class="winBoxState ? '' : 'span1'" @click="getBoxOrn">包含以下皮肤</span>
					<span :class="winBoxState ? 'span1' : ''" @click="getWinPeo">最近掉落</span>
				</div>

				<div class="right">
					<div class="right-one">
					</div>
				</div>
			</div>

			<div class="boxlist-bot" v-if="!winBoxState && showNav">
				<ul>
					<li v-for="(item, index) in skin_list" :key="index" class="card" :style="{
              backgroundImage: 'url(' + item.background + ')',
            }">
						<div class="boxlist-warp">
							<div class="boxlist1-top">
								<span class="ico">{{ item.exteriorName }}</span>
								<img :src="item.img" />
							</div>
							<div>
								<div class="boxlist1-bot" :title="item.name">
									{{ item.name }}
								</div>
								<div class="list-pirce">
									<div class="pirce-left">
										<img src="../assets/new/money.png" />
										<div>
											<span>{{ item.price }}</span>
											<span>{{ item.probability }}%</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</li>
				</ul>
			</div>
			<div class="boxlist-botm" v-if="!winBoxState && !showNav">
				<ul>
					<li v-for="(item, index) in skin_list" :key="index" class="card" :style="{
              backgroundImage: 'url(' + item.background + ')',
            }">
						<div class="boxlist-warp">
							<div class="boxlist1-top">
								<span class="ico">{{ item.exteriorName }}</span>
								<img :src="item.img" />
							</div>
							<div>
								<div class="boxlist1-bot" :title="item.name">
									{{ item.name }}
								</div>
								<div class="list-pirce">
									<div class="pirce-left">
										<img src="../assets/new/money.png" />
										<div>
											<span>{{ item.price }}</span>
										</div>
									</div>
									<div class="pirce-right">
										<span>{{ item.probability }}%</span>
									</div>
								</div>
							</div>
						</div>
					</li>
				</ul>
			</div>

			<div class="boxlist-botsm" v-if="winBoxState && showNav">
				<ul>
					<li v-for="(item, index) in skin_list1" class="card" :key="index">
						<div class="boxlist-warp">
							<div class="boxlist1-top">
								<img :src="item.player_img" />
							</div>
							<div class="boxlist1-bot1">
								<img src="../assets/new/money.png" />{{item.price}}
							</div>
							<div class="boxlist1-top">
								<img :src="item.imageUrl" />
								<span :style="{color: item.color}">{{item.skin_name}}</span>
							</div>
						</div>
					</li>
				</ul>
			</div>
			<div class="boxlist-botsm" v-if="winBoxState && !showNav">
				<ul>
					<li v-for="(item, index) in skin_list1" :key="index">
						<div class="boxlist-warp">
							<div class="boxlist1-top">
								<img :src="item.player_img" />
							</div>
							<div class="boxlist1-bot1">
								<img src="../assets/new/money.png" />{{item.price}}
							</div>
							<div class="boxlist1-top">
								<img :src="item.imageUrl" />
								<span :style="{color: item.color}">{{item.skin_name}}</span>
							</div>
						</div>
					</li>
				</ul>
			</div> -->

      <div class="clear"></div>
    </div>

    <!-- 分享-->
    <el-dialog
      title="分享链接"
      :visible.sync="dialogFormVisible"
      width="200px"
      class="share-hide"
    >
      <div class="share-btn">
        <el-input v-model="url" autocomplete="off"></el-input>
        <el-button class="btn" type="warning" @click="copyUrl()"
          >复制</el-button
        >
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogFormVisible = false"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <!-- open_box2 -->
    <audio controls ref="notify" class="audio" style="display: none">
      <source src="../assets/audio/open_box_scroll.7134f.mp3" />
    </audio>

    <!-- open_box2 单个 -->
    <!-- <audio controls ref="notifySin" class="audio" loop="loop" style="display:none;">
      <source src="../assets/audio/box3-8-4.mp3" />
    </audio> -->

    <audio controls ref="notify1" class="audio" style="display: none">
      <source src="../assets/audio/succeed.mp3" />
    </audio>
  </div>
</template>

<script>
import myslide from "@/components/my_slide1.vue";
import mywin from "@/components/my_win.vue";
import Utils from "../assets/js/util.js";
export default {
  components: {
    myslide,
    mywin,
  },
  props: ["showNav", "img"],
  data() {
    return {
      btnActive: 1,
      openPhone: true,
      img: "",
      img1: require("../assets/img/1mdpi.png"),
      cartState: false,
      skin_list1: [],
      duihuaninput: null,
      winBoxState: false,
      loading: false,
      url: window.location.href,
      dialogFormVisible: false,
      funState: true,
      winState: false,
      kaiState: false,
      openBoxState: false,
      box_id: this.$route.query.box_id,
      is_activity: this.$route.query.is_activity,
      type_name: this.$route.query.type_name,
      box_colour: this.$route.query.box_colour,
      type: this.$route.query.type,
      box_name: "",
      box_obj: {},
      price: 0,
      totalPrice: 0,
      skin_list: [],
      duihuan: false,
      skins_types: [],
      value: true,
      kaiBox: 1,
      kaiBox1: 1,
      kaiBoxNum: [],
      winList: [],
      listBox: [],
      imgList: [],
      imgList1: [],
      keynum: null,
      keyback: null,
      // loadFlag: true, // 页面加载Loading标识
      shengyin: false,
      selectedQuantity: null, // 用于保存用户选择的数量
      numbers: [1, 2, 3, 4, 5], // 数量选项
      isFinish: false,
      language:
        localStorage.getItem("lang") ||
        this.$store.state.currentLanguage ||
        "zh",
      animationDuration: 8,
      // showContent:true
    };
  },
  watch: {
    box_id(val){
      console.log(val,'测试3222222')
    },
    kaiBox(val) {
      let _this = this;
      _this.totalPrice = (this.price * val).toFixed(2);
    },
    skin_list: {
      handler(val) {
        if (val) {
          // this.$nextTick(() => {
          // 	this.caculateCardHeight();
          // });
        }
      },
      // deep: true,
    },
    skin_list1: {
      handler(val) {
        if (val) {
          // this.$nextTick(() => {
          // 	this.caculateCardHeight();
          // });
        }
      },
      // deep: true,
    },
    openBoxState: {
      handler(value) {
        if (value) {
          // this.scrollToTop();
        }
      },
    },
    winState: {
      handler(value) {
        // if (value) {
        // 	this.scrollToTop();
        // 	document.body.style.overflow = "hidden";
        // } else {
        // 	document.body.style.overflow = "";
        // }
      },
    },
  },
  methods: {
    getShortName(item) {
      const lang = this.language;
      switch (lang) {
        case "zh":
          return item.shortName;
        case "en":
          return item.english_shortName;
        default:
          return item.shortName;
      }
    },
    getExteriorName(item) {
      const lang = this.language;
      switch (lang) {
        case "zh":
          return item.exteriorName;
        case "en":
          return item.english_exteriorName;
        default:
          return item.exteriorName;
      }
    },
    selectNum(num) {
      this.kaiBox = num;
      // this.showContent = false
    },
    toggleSound() {
      console.log("点击了");
      this.shengyin = !this.shengyin;
    },
    toggleCartState() {
      console.log("点击了");
      this.cartState = !this.cartState;
    },
    updateDynamicSpecialType() {
      if (this.is_activity == 1) {
        this.kaiBoxNum = [
          {
            num: 1,
            state: true,
          },
        ];
      } else {
        this.kaiBoxNum = [
          {
            num: 1,
            state: true,
          },
          {
            num: 2,
            state: false,
          },
          {
            num: 3,
            state: false,
          },
          {
            num: 4,
            state: false,
          },
          {
            num: 5,
            state: false,
          },
        ];
      }
    },
    getBackZuijin(e) {
      if (e.color == "#007acc") {
        e.background = require("../assets/img/openBox/4.png");
      } else if (e.color == "#FF4500") {
        // 红
        e.background = require("../assets/img/openBox/2.png");
      } else if (e.color == "#8a2be2") {
        // 粉
        e.background = require("../assets/img/openBox/3.png");
      } else if (e.color == "#FFA500") {
        // 金
        e.background = require("../assets/img/openBox/1.png");
      }
      return e.background;
    },
    getBackZuijins(e) {
      if (e.color == "#007acc") {
        e.background = require("../assets/img/openBox/d1.png");
      } else if (e.color == "#FF4500") {
        // 红
        e.background = require("../assets/img/openBox/c1.png");
      } else if (e.color == "#8a2be2") {
        // 粉
        e.background = require("../assets/img/openBox/b1.png");
      } else if (e.color == "#FFA500") {
        // 金
        e.background = require("../assets/img/openBox/e1.png");
      }
      return e.background;
    },
    getBackZuijinss(e) {
      if (e.color == "#007acc") {
        e.background = require("../assets/img/openBox/d.png");
      } else if (e.color == "#FF4500") {
        // 红
        e.background = require("../assets/img/openBox/c.png");
      } else if (e.color == "#8a2be2") {
        // 粉
        e.background = require("../assets/img/openBox/b.png");
      } else if (e.color == "#FFA500") {
        // 金
        e.background = require("../assets/img/openBox/e.png");
      }
      return e.background;
    },
    duihuans() {
      let _this = this;
      let param = {
        password: this.duihuaninput,
      };
      _this.$axios
        .post("/index/Activity/key_openbox", this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          if (data.status == 1) {
            this.$message({
              message: this.language == "zh" ? data.msg : data.english_msg,
              customClass: "message-styles",
              type: "success",
            });
            _this.getBoxInfo();
            this.duihuan = false;
            return;
          }

          this.$message({
            message: this.language == "zh" ? data.msg : data.english_msg,
            customClass: "message-styles",
            type: "error",
          });
        });
    },
    scrollToTop() {
      if (window.scrollTo) {
        window.scrollTo({
          top: 0,
          behavior: "smooth", // 平滑滚动
        });
      } else {
        document.body.scrollTop = 0; // 兼容老版本浏览器
        document.documentElement.scrollTop = 0; // 兼容新版本浏览器
      }
    },
    scrollToTop1() {
      if (window.scrollTo) {
        window.scrollTo({
          top: 0,
        });
      } else {
        document.body.scrollTop = 0; // 兼容老版本浏览器
        document.documentElement.scrollTop = 0; // 兼容新版本浏览器
      }
    },
    // caculateCardHeight() {
    // 	const els = document.querySelectorAll(".card");
    // 	const width = window.getComputedStyle(els[0]).getPropertyValue("width");
    // 	els.forEach((el) => {
    // 		el.style.height = (393 / 326) * width.split("px")[0] + "px";
    // 	});
    // },
    //请求背景图片
    getBack() {
      let _this = this;
      // _this.$axios.post("/index/Setting/background").then((res) => {
      //   if (res.data.status == 1) {
      //     this.img = res.data.data.img;
      //     if (!this.img) {
      //       this.img = this.img1;
      //     }
      //   }
      // });
    },
    //音乐 过程
    playAlarm() {
      this.$refs.notify.currentTime = 0;
      this.$refs.notify.play();
    },
    //音乐 过程关闭
    playAlarmStop() {
      this.$refs.notify.pause();
    },
    //音乐 结果
    playAlarm1() {
      this.$refs.notify1.play();
    },
    //音乐 结果暂停
    playAlarm2() {
      this.$refs.notify1.pause();
    },

    //点击包含以下皮肤
    getBoxOrn() {
      this.winBoxState = false;
      //this.getBoxInfo();
      let _this = this;
      let param = {
        box_id: _this.box_id,
      };
      _this.$axios
        .post("/api/box/boxinfo", _this.$qs.stringify(param))
        .then((res) => {
          if (res.data) {
            _this.skin_list = res.data.data.box_skins;
          }
        });
    },
    //最近掉落
    getWinPeo() {
      this.winBoxState = true;
      let _this = this;
      let param = {
        page: 1,
        pagesize: 12,
        box_id: this.box_id,
      };
      _this.$axios
        .post("/api/index/lately", this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          // console.log(data);
          if (data.code == 1) {
            _this.skin_list1 = data.data.data;
          }
        });
    },
    //最近掉落
    getWinPeos() {
      let _this = this;
      let param = {
        page: 1,
        pageSize: 12,
        box_id: this.box_id,
      };
      _this.$axios
        .post("/api/index/lately", this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          // console.log(data);
          if (data.code == 1) {
            if (!_this.openBoxState) {
              _this.skin_list1 = data.data.data;
            }
          }
        });
    },
    //分享复制
    copyUrl() {
      var input = document.createElement("input"); // js创建一个input输入框
      input.value = window.location.href; // 将需要复制的文本赋值到创建的input输入框中
      document.body.appendChild(input);
      input.select();
      document.execCommand("Copy");
      document.body.removeChild(input);
      this.$message({
        message: this.$t("profileMessage.copySuccess"),
        type: "success",
        customClass: "message-styles",
      });
      this.dialogFormVisible = false;
    },
    //盲盒信息
    getBoxInfo() {
      let _this = this;
      let param = {
        box_id: _this.box_id,
      };
      _this.$axios
        .post("/api/box/boxinfo", _this.$qs.stringify(param))
        .then((res) => {
          if (res.data) {
            //  console.log(res.data);
            // this.loadFlag = false;
            this.box_obj = res.data.data;
            console.log(res.data.data, "data");
            _this.box_name = res.data.data.name;
            _this.price = _this.totalPrice = res.data.data.price;
            _this.skin_list = res.data.data.box_skins;
            _this.skins_types = res.data.data.skins_types;
          }
        });
    },
    exchange(e) {
      if (e > Number.parseFloat(this.masonry)) {
        this.$message({
          message: this.$t("钻石数量不足"),
          type: "error",
          customClass: "message-styles",
        });
        return;
      }
      var _this = this;
      let param = {
        coin: e,
      };
      this.$axios
        .post("/api/player/exchange", this.$qs.stringify(param))
        .then((res) => {
          var newMoney = parseFloat(this.money) + parseFloat(param.coin);
          var newMasonry = parseFloat(this.masonry) - parseFloat(param.coin);
          let userInfo = JSON.parse(localStorage.getItem("userInfo"));
          userInfo.total_amount = newMoney;
          userInfo.masonry = newMasonry;
          //userInfo.masonry = masonry
          localStorage.setItem("userInfo", JSON.stringify(userInfo));
          // _this.selfLogin();
        });
    },
    //购买盲盒
    buyBox() {
      if (!this.$store.state.loginStates) {
        this.$message({
          message: this.$t('public.noLogin'),
          type: "error",
          customClass: "message-styles",
        });
        return;
      }
      let _this = this;
      this.loading = true;
      this.kaiState = true;
      this.isFinish = false; // 开盲盒状态
      let param = {
        box_id: _this.box_id,
        num: _this.kaiBox,
        cartState: this.cartState,
      };
      _this.$axios
        .post("/api/box/buyboxfake", _this.$qs.stringify(param))
        .then((res) => {
          if (res.data.code == 1) {
            _this.getBoxInfo();
            _this.getWinPeos();
            //console.log(res.data);
            this.$parent.getPlayerInfo();
            this.$parent.openTop();
            // console.log(res.data.data.total_amount);
            Utils.$emit("money", res.data.data.total_amount);
            //let player_box_skin_id = res.data.data.player_box_skin_id;
            let imgCopy = JSON.parse(JSON.stringify(this.skin_list));
            console.log(imgCopy, "imgCopy");
            if (!this.shengyin) {
              // if (this.cartState) {
              //   this.playAlarm1();
              // } else {
              this.playAlarm(); // 播放音乐
              // }
            }
            if (this.kaiBox == 1) {
              let imgCopy1 = [];
              for (let i = 0; i < Math.floor(80 / imgCopy.length) + 1; i++) {
                for (let j = 0; j < imgCopy.length; j++) {
                  imgCopy1.push(imgCopy[j]);
                }
              }
              imgCopy1 = imgCopy1.slice(0, 80);
              imgCopy1 = this.getRandomArr(imgCopy1, 80);
              this.imgList = imgCopy1;
              console.log(this.imgList, "imglist");
              // console.log(this.imgList[0].background,'图片')
              // console.log(this.imgList.length);
            } else if (this.kaiBox >= 2) {
              let imgCopy2 = [];
              for (let i = 0; i < this.kaiBox; i++) {
                this.imgList1.push(imgCopy);
              }
              for (
                let i = 0;
                i < Math.floor(100 / this.imgList1[0].length) + 1;
                i++
              ) {
                for (let j = 0; j < this.imgList1[0].length; j++) {
                  imgCopy2.push(this.imgList1[0][j]);
                }
              }
              imgCopy2 = imgCopy2.slice(0, 100);
              for (let i = 0; i < this.imgList1.length; i++) {
                this.imgList1[i] = imgCopy2;
                this.imgList1[i] = this.getRandomArr(this.imgList1[i], 100);
              }
            } else {
              let imgCopy2 = [];
              for (let i = 0; i < this.kaiBox; i++) {
                this.imgList1.push(imgCopy);
              }
              for (
                let i = 0;
                i < Math.floor(150 / this.imgList1[0].length) + 1;
                i++
              ) {
                for (let j = 0; j < this.imgList1[0].length; j++) {
                  imgCopy2.push(this.imgList1[0][j]);
                }
              }
              imgCopy2 = imgCopy2.slice(0, 150);
              for (let i = 0; i < this.imgList1.length; i++) {
                this.imgList1[i] = imgCopy2;
                this.imgList1[i] = this.getRandomArr(this.imgList1[i], 150);
              }
            }

            res.data.data.skins_info.forEach((e) => {
              e.background = this.getBackZuijins(e);
            });
            //开盲盒
            //_this.getBoxResult(player_box_skin_id);
            this.loading = false;
            if (res.data.data.skins_info.length == 1) {
              this.imgList[62] = res.data.data.skins_info[0];
              if (!this.showNav) {
                this.imgList[48] = res.data.data.skins_info[0];
              }
              _this.setStyle(1);
            } else if (res.data.data.skins_info.length >= 2) {
              let data = res.data.data.skins_info;
              let imgListCopy = JSON.parse(JSON.stringify(this.imgList1));

              for (let i = 0; i < data.length; i++) {
                imgListCopy[i][62] = data[i];
                if (!this.showNav) {
                  imgListCopy[i][68] = data[i];
                }
              }
              this.imgList1 = imgListCopy;
              _this.setStyle(2);
            } else if (res.data.data.skins_info.length == 3) {
              let data = res.data.data.skins_info;
              let imgListCopy = JSON.parse(JSON.stringify(this.imgList1));

              for (let i = 0; i < data.length; i++) {
                imgListCopy[i][34] = data[i];
                if (!this.showNav) {
                  imgListCopy[i][62] = data[i];
                }
              }
              this.imgList1 = imgListCopy;
              var length = res.data.data.skins_info.length;
              _this.setStyle(3, length);
            } else if (res.data.data.skins_info.length == 4) {
              let data = res.data.data.skins_info;
              let imgListCopy = JSON.parse(JSON.stringify(this.imgList1));
              for (let i = 0; i < data.length; i++) {
                imgListCopy[i][41] = data[i];
                if (!this.showNav) {
                  imgListCopy[i][68] = data[i];
                }
              }
              this.imgList1 = imgListCopy;
              _this.setStyle(4, length);
            } else if (res.data.data.skins_info.length == 5) {
              let data = res.data.data.skins_info;
              let imgListCopy = JSON.parse(JSON.stringify(this.imgList1));

              for (let i = 0; i < data.length; i++) {
                imgListCopy[i][54] = data[i];
                if (!this.showNav) {
                  imgListCopy[i][88] = data[i];
                }
              }
              this.imgList1 = imgListCopy;
              _this.setStyle(5, length);
            }
            _this.winList = res.data.data;

            //是否开启动画
            if (this.cartState) {
              // _this.winState = true;
              // _this.kaiState = false;
              // // this.playAlarmStop(); // 关闭音乐
              // if (!this.shengyin) {
              //   this.playAlarm1();
              // }
              // res.data.data.skins_info.forEach((e) => {
              //   e.background = this.getBackZuijins(e);
              // });
              // this.imgList = [];
              // this.imgList1 = [];
              // this.playAlarm();

              this.animationDuration = 2;
              _this.openBoxState = true;
              var length = res.data.data.skins_info.length;
              _this.setStyle("", length);
              setTimeout(() => {
                _this.openBoxState = false;
                _this.winState = true;
                _this.kaiState = false;
                this.imgList = [];
                this.imgList1 = [];
                this.$parent.closeTop();
                res.data.data.skins_info.forEach((e) => {
                  e.background = this.getBackZuijins(e);
                });
                this.playAlarm1(); // 钱币音效
              }, 4000 + 200 * length);
              setTimeout(() => {
                this.playAlarmStop(); // 关闭音乐
                //   // 动画结束
                this.isFinish = true;
              }, 1600 + 200 * length);
            } else {
              this.animationDuration = 8;
              _this.openBoxState = true;
              var length = res.data.data.skins_info.length;
              _this.setStyle("", length);
              setTimeout(() => {
                _this.openBoxState = false;
                _this.winState = true;
                _this.kaiState = false;
                this.imgList = [];
                this.imgList1 = [];
                this.$parent.closeTop();
                res.data.data.skins_info.forEach((e) => {
                  e.background = this.getBackZuijins(e);
                });
                this.playAlarm1(); // 钱币音效
              }, 8000 + 200 * length);
              setTimeout(() => {
                this.playAlarmStop(); // 关闭音乐
                //   // 动画结束
                this.isFinish = true;
              }, 6600 + 200 * length);
            }
          } else {
            this.loading = false;
            this.kaiState = false;
            let msg = res.data.msg;

            // if (msg == "新用户仅限于新用户开箱/萌新开箱") {
            //   this.$message({
            //     message: "为了校验你是否机器人，进行任意额度充值即可开箱提取。",
            //     type: "error",
            //   });
            //   return;
            // }
            // if (msg == "新注册用户仅限开箱一次") {
            //   this.$message({
            //     message: msg,
            //     type: "error",
            //   });
            //   return;
            // }
            // if (msg == "当天仅限开箱一次") {
            //   this.$message({
            //     message: msg,
            //     type: "error",
            //   });
            //   return;
            // }
            // if (msg == "玩家信息错误") {
            //   this.$message({
            //     message: "请先登录",
            //     type: "error",
            //   });
            //   this.$store.commit("getLogin", true);
            //   return;
            // }
            // if (msg == "购买失败，请联系客服") {
            //   this.$message("购买失败，请联系客服处理");
            //   return;
            // }
            // if (msg.indexOf("当前余额不足") != -1) {
            // }
            this.$message({
              message:
                this.language == "zh" ? res.data.msg : res.data.english_msg,
              type: "error",
              customClass: "message-styles",
            });
            return;
            // this.$message(msg);
          }
        });
    },

    //开盒规则
    goOpenboxRule() {
      this.$router.push({
        path: `/OpenboxRule`,
      });
    },
    // 打乱数组
    getRandomArr(arr, num) {
      var _arr = arr.concat();
      var n = _arr.length;
      var result = [];

      // 先打乱数组
      while (n-- && num--) {
        var index = Math.floor(Math.random() * n); // 随机位置
        [_arr[index], _arr[n]] = [_arr[n], _arr[index]]; // 交换数据
        result.push(_arr[n]); // 取出当前最后的值，即刚才交换过来的值
      }
      return result;
    },
    chooseNum(num) {
      // 默认只为一个 不做修改了
      this.kaiBox = num;

      for (let i = 0; i < this.kaiBoxNum.length; i++) {
        if (this.kaiBoxNum[i].num == num) {
          this.kaiBoxNum[i].state = true;
        } else {
          this.kaiBoxNum[i].state = false;
        }
      }
    },
    //子组件修改父组件
    winexchange(msg) {
      this.winState = msg;
    },
    winget(msg) {
      this.winState = msg;
    },
    winX(msg) {
      this.winState = msg;
    },
    setStyle(index, num) {
      let style = document.createElement("style");
      style.setAttribute("type", "text/css");
      document.head.appendChild(style);
      let sheet = style.sheet;
      if (index == 1) {
        // let random = Math.floor(Math.random() * 190) + 11905;
        let random = 12000;
        // console.log(random);
        sheet.insertRule(
          `@keyframes run` +
            index +
            `{
							0% {
							  left: 0;
							}
							100% {
							  left: -` +
            random +
            `px
							}
						}`,
          0
        );
      } else if (index == 2) {
        for (var i = 0; i < num; i++) {
          if (this.showNav) {
            var random = 8000;
            // var random = Math.floor(Math.random() * 190) + 9905;
          } else {
            //屏幕小于600的宽度
            var random = 800;
            // var random = Math.floor(Math.random() * 90) + 5955;
          }
          console.log(random);
          sheet.insertRule(
            `@keyframes run-li` +
              i +
              `{
							  0% {
								left: 0;
							  }
							  100% {
								left: -` +
              random +
              `px
							  }
						  }`,
            0
          );
        }
      } else if (index == 3) {
        console.log(num);
        for (var i = 0; i < num; i++) {
          if (this.showNav) {
            var random = 12000;
            // var random = Math.floor(Math.random() * 190) + 9905;
          } else {
            //屏幕小于600的宽度
            var random = 6000;
            // var random = Math.floor(Math.random() * 90) + 5955;
          }
          console.log(random);
          sheet.insertRule(
            `@keyframes run-li` +
              i +
              `{
					        0% {
                    left:0;
					        }
					        100% {
					          left: -` +
              random +
              `px
					        }
					    }`,
            0
          );
        }
      } else if (index == 4) {
        for (var i = 0; i < num; i++) {
          if (this.openPhone == true) {
            var random = 8000;
            // var random = Math.floor(Math.random() * 190) + 9905;
          } else {
            //屏幕小于600的宽度
            var random = 6000;
            // var random = Math.floor(Math.random() * 90) + 5955;
          }
          sheet.insertRule(
            `@keyframes run-li` +
              i +
              `{
					        0% {
					          top: 0;
					        }
					        100% {
					          top: -` +
              random +
              `px
					        }
					    }`,
            0
          );
        }
      } else if (index == 5) {
        for (var i = 0; i < num; i++) {
          if (this.openPhone == true) {
            var random = 6000;
            // var random = Math.floor(Math.random() * 190) + 9905;
          } else {
            //屏幕小于600的宽度
            var random = 6000;
            // var random = Math.floor(Math.random() * 90) + 5955;
          }
          sheet.insertRule(
            `@keyframes run-li` +
              i +
              `{
					        0% {
					          top: 0;
					        }
					        100% {
					          top: -` +
              random +
              `px
					        }
					    }`,
            0
          );
        }
      } else {
        for (var i = 0; i < num; i++) {
          if (this.openPhone == true) {
            var random = 6000;
            // var random = Math.floor(Math.random() * 190) + 9905;
          } else {
            //屏幕小于600的宽度
            var random = 6000;
            // var random = Math.floor(Math.random() * 90) + 5955;
          }
          sheet.insertRule(
            `@keyframes run-li` +
              i +
              `{
					        0% {
					          top: 0;
					        }
					        100% {
					          top: -` +
              random +
              `px
					        }
					    }`,
            0
          );
        }
      }
    },
  },
  mounted() {
    // this.buyBox()
    let _this = this;
    _this.getWinPeo();
    setInterval(function () {
      _this.getWinPeos();
    }, 5000);
    _this.winBoxState = false;
    _this.getBoxInfo();
    _this.getBack();
    _this.scrollToTop1();
    document.addEventListener(
      "touchmove",
      (event) => {
        if (this.openBoxState) event.preventDefault();
      },
      {
        passive: true,
      }
    );
    //判断屏幕宽度
    // console.log(document.body.clientWidth);
    if (document.body.clientWidth < 600) {
      this.openPhone = false;
    }
    // _this.setStyle(5,5)
    this.updateDynamicSpecialType();
    //计算卡片高度
    // window.addEventListener("resize", () => {
    // 	this.caculateCardHeight();
    // });
  },
};
</script>
<style lang="less" scoped>
@import "./css/openbox.less";

.act_fallRecent {
  color: #fff;
  padding-top: 24px;
  height: 80px;
  font-size: 20px;
  font-weight: 400;
  max-width: 90%;
  margin: 20px auto 0;
}

.xiangzi {
  width: 100%;
  max-width: 90%;
  margin: auto;

  ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;

    li {
      width: 200px;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      cursor: pointer;
      margin-top: 12px;
      position: relative;
      .boxlist-warp {
        background: url("../assets/img/openBox/item-bg.jpg");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        // padding: 8px;
        border-radius: 5px;
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: space-between;
        height: calc(100% - 20px);
        position: relative;
        box-sizing: border-box;
        // padding-bottom: 10px;

        .boxlist1-top {
          padding: 0 20px;
          background-size: 100% 100%;
          text-align: center;
          // border-bottom: 3px solid #f1a920;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
          // padding: 15% 10% 50% 10%;
          height: 110px;
          overflow: hidden;
          // position: absolute;
          // top: 0;
          // bottom: 0;
          // left: 0;
          // right: 0;
          // margin: auto;

          // box-shadow: 0px 5px 10px #f1a920;
          img {
            // width: 70%;
            height: 100%;
            cursor: pointer;
          }
        }
        .boxlist1-bottom {
          // height: 56px;
          background: #131724;
          padding: 8px 0px;
        }
        .boxlist1-bot {
          width: 90%;
          margin: auto;
          color: white;
          font-family: "微软雅黑";
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          // line-height: 24px;
          font-size: 14px;
          font-weight: bold;
          word-wrap: break-word;
        }

        .boxlist1-bot2 {
          height: 16px;
          width: 90%;
          margin: auto;
          color: rgb(204, 204, 204);
          font-family: "微软雅黑";
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          font-size: 14px;
          word-wrap: break-word;
        }

        .boxlist1-bot1 {
          padding: 8px;
          display: flex;
          align-items: center;

          img {
            width: 25px;
            height: 25px;
            border-radius: 50%;
          }

          span {
            margin-left: 8px;
            color: #c3c3e2;
            font-size: 14px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }

        .list-pirce {
          padding: 0px 5px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: #131724;
          line-height: 40px;
        }

        .ico {
          position: absolute;
          top: 0;
          left: 0;
          font-size: 12px;
          color: #04f540;
          background-color: rgba(0, 0, 0, 0.5);
          padding: 2px 4px;
        }

        .pirce-left {
          display: flex;
          align-items: center;
          // padding-right: 10px;
          font-size: 13px;
          color: #a5aabd;
          font-weight: 700;
          img {
            width: auto;
            height: 20px;
            margin-right: 5px;
          }
        }

        .pirce-right {
          color: #a5aabd !important;
          font-weight: 700;
          font-size: 13px;
          font-family: "微软雅黑";
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .border {
        position: absolute;
        top: 0;
        bottom: 0;
        left: -0.375rem;
        width: 0.375rem;
        z-index: 0;
      }
    }
  }
}

.zuijin {
  width: 1660px;
  margin: auto;
  overflow: hidden;

  ul {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    height: 180px;

    li {
      .boxlist-warp {
        // background-color: #24252f;
        width: 150px;
        margin: 8px;
        border-radius: 5px;
        position: relative;
        background-size: 100%;

        .boxlist1-top {
          padding: 0 20px;
          background-size: 100% 100%;
          text-align: center;
          // border-bottom: 3px solid #f1a920;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;

          // box-shadow: 0px 5px 10px #f1a920;
          img {
            width: 40px;
            // margin-top: 20%;
            height: 40px;
            cursor: pointer;
          }
        }

        .boxlist1-bot {
          padding: 0 20px;
          background-size: 100% 100%;
          text-align: center;
          // border-bottom: 3px solid #f1a920;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;

          // box-shadow: 0px 5px 10px #f1a920;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: auto;
            height: auto;
            max-height: 60px;
            height: 100%;
            // min-height: 100px;
          }
          // word-wrap: break-word;
        }

        .boxlist1-bot1 {
          color: white;
          padding: 8px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 18px;
            height: 18px;
            border-radius: 50%;
          }

          span {
            margin-left: 8px;
            color: white;
            font-family: "微软雅黑";
            font-size: 14px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }

        .list-pirce {
          padding: 0px 5px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #a5aabd;
          font-size: 13px;
        }

        .ico {
          position: absolute;
          top: 0;
          left: 0;
          font-size: 12px;
          color: #04f540;
          background-color: rgba(0, 0, 0, 0.5);
          padding: 2px 4px;
        }

        .pirce-left {
          // flex: 1;
          display: flex;
          align-items: center;
          padding-right: 10px;
          font-size: 13px;
          color: #a5aabd;
          img {
            width: auto;
            height: 15px;
            margin-right: 5px;
          }

          & > div {
            flex: 1;
            color: #f4bd50;
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            span:last-child {
              color: #c3c3e2;
            }
          }
        }

        .pirce-right {
          color: #848492;
          font-size: 14px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

.key-box {
  position: fixed;
  top: 200px;
  right: 0;
  width: 300px;
  height: 60px;
  display: flex;
  flex-direction: row;
  background: rgba(255, 255, 255, 0.1);
  padding: 8px 24px;
  align-items: center;

  .center {
    margin-left: 12px;

    div:first-child {
      color: white;
    }

    div:last-child {
      color: rgba(255, 188, 0, 1);
    }
  }

  img {
    width: 48px;
    height: 48px;
  }

  .duihuan {
    margin-left: 12px;
    background: rgba(255, 188, 0, 1);
    padding: 4px 18px;
    height: 25px;
    text-align: center;
  }
}

.key-boxs {
  // width: 100%;
  margin-top: 12px;
  height: 40px;
  display: flex;
  flex-direction: row;
  background: rgba(255, 255, 255, 0.1);
  padding: 4px 8px;
  justify-content: space-between;
  align-items: center;

  .center {
    margin-left: 12px;

    div:first-child {
      color: white;
    }

    div:last-child {
      color: rgba(255, 188, 0, 1);
    }
  }

  img {
    width: 24px;
    height: 24px;
  }

  .duihuan {
    margin-left: 12px;
    color: white;
    background: rgba(226, 112, 39, 1);
    padding: 4px 18px;
    height: 25px;
    text-align: center;
  }
}

.duihuans {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99;
  color: white;

  .box {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 200px;
    height: 140px;
    background: rgb(28, 28, 28);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px 48px;

    .title {
      font-size: 20px;
    }

    input {
      margin-top: 12px;
      width: 150px;
      border: 1px solid rgb(146, 146, 146);
      background: none;
      color: rgb(146, 146, 146);
      padding: 8px 16px;
    }

    .duihuan {
      margin-top: 12px;
      background: rgba(226, 112, 39, 1);
      padding: 8px 32px;
      border-radius: 5px;
    }
  }
}

::v-deep {
  .el-switch__label--left {
    margin-right: 20px;

    color: #fff !important;
  }

  .el-switch__core {
    background-color: #595171;
    border: 1px solid #595171;
    height: 14px;

    &:after {
      height: 28px;
      width: 28px;
      top: -8px;
      left: -10px;
      background-color: #fff;
    }
  }

  .el-switch.is-checked .el-switch__core {
    border-color: #595171;
    background-color: #595171;
  }
}

.moblie-box-info {
  user-select: none;

  .openbox_footer {
    margin: 0 8px;
    border-radius: 10px;
    padding: 8px 12px;
    background-color: #10151b;
    margin-bottom: -18px;

    .animation {
      font-size: 14px;
      color: #5e77a7;
      text-align: end;
      margin-bottom: 5px;
    }

    // .open-btn {
    //   color: #fff;
    //   width: 220px;
    //   height: 42px;
    //   font-size: 18px;
    //   font-weight: 600;
    //   line-height: 42px;
    //   color: #10151b;
    //   border-radius: 8px;
    //   cursor: pointer;
    //   color: transparent;
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   // background: url("../assets/img/openBox/button.png") no-repeat !important;
    //   // background-size: 100% 100% !important;
    //   // background-position: 50% 50% !important;
    //   // border: 0 !important;
    //   text-align: center;
    //   margin: 30px auto;
    // }

    .open-btns {
      color: #fff !important;
      height: 42px;
      font-size: 18px;
      // font-weight: 600;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 42px;
      background-color: rgba(225, 113, 35, 1);
      color: #10151b;
      border-radius: 8px;
      cursor: pointer;
      margin-top: 10px;

      img {
        margin: 0 6px;
      }
    }

    .choose-num {
      display: flex;
      // flex-direction: column;
      align-items: center;
      color: #fff;
      justify-content: center;

      .show-price {
        // background-color: #10151b;
        height: 50px;
        text-align: center;
        width: auto;
        border-radius: 8px;
        padding: 0 20px;
        display: flex;
        align-items: center;
        font-size: 18px;
        justify-content: center;
        color: #fff;
        font-family: "微软雅黑";

        img {
          width: 20px;
          height: 20px;
          margin-right: 5px;
        }
      }

      .nums {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        .a1 {
          background: url("../assets/img/openBox/a1.png") no-repeat;
          background-size: 100%;
        }

        .a2 {
          background: url("../assets/img/openBox/a2.png") no-repeat;
          background-size: 100%;
        }

        .a3 {
          background: url("../assets/img/openBox/a3.png") no-repeat;
          background-size: 100%;
        }

        .a4 {
          background: url("../assets/img/openBox/a4.png") no-repeat;
          background-size: 100%;
        }

        .a5 {
          background: url("../assets/img/openBox/a5.png") no-repeat;
          background-size: 100%;
        }

        .s1 {
          background: url("../assets/img/openBox/s1.png") no-repeat;
          background-size: 100%;
        }

        .s2 {
          background: url("../assets/img/openBox/s2.png") no-repeat;
          background-size: 100%;
        }

        .s3 {
          background: url("../assets/img/openBox/s3.png") no-repeat;
          background-size: 100%;
        }

        .s4 {
          background: url("../assets/img/openBox/s4.png") no-repeat;
          background-size: 100%;
        }

        .s5 {
          background: url("../assets/img/openBox/s5.png") no-repeat;
          background-size: 100%;
        }

        .num-item {
          height: 30px;
          width: 30px;
          // border: 1px solid #6279a3;
          margin-right: 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 0 !important;
          font-size: 14px;
          font-weight: 600;
          cursor: pointer;

          .ride {
            transform: rotate(45deg);
          }
        }
      }
    }
  }

  // .box-header {
  //   height: 50px;
  //   width: 100%;
  //   line-height: 50px;
  //   text-align: center;
  //   font-size: 18px;
  //   font-weight: bold;
  //   color: #fff;
  //   background-size: 100% 100%;
  //   background-repeat: no-repeat;
  //   background-image: url("../assets/img/open-box/d3e403fb-09df-4482-bc42-1f896d529a1b.png");
  // }

  .conlist-box1 {
    // position: relative;
    // margin: 20px;
    // background-position: center;
    // background-size: cover;
    // height: 300px;
    // background-repeat: no-repeat;
    // background-image: url("../assets/img/open-box/2078b7b9-e5ad-40b5-b4f1-7019d64cf83e.png");

    img {
      width: 50%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -80%);
      animation: boxhover 1.5s linear 0s infinite alternate;
    }
  }
}

.openbox-container_header {
  color: #fff;
  width: 100%;
  //   min-width: 500px;
  height: 30px;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  line-height: 30px;
  margin: 20px auto 0 auto;
  // background-position: center;
  // background-repeat: no-repeat;
  // background-size: 500px auto;
  // background-image: url("../assets/img/open-box/5b5458b9-63ce-4d0c-bc2c-e834c294ac38.webp");
  position: relative;
  box-sizing: border-box;

  .animation {
    position: absolute;
    right: 15%;
    top: 20px;
    display: flex;
  }

  .back {
    position: absolute;
    left: 15%;
    top: 20px;
    width: 43px * 1.2;
    height: 24px * 1.2;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("../assets/img/open-box/back.png");
    color: #fff;
  }
}
.gradient-color-\#FFA50 {
}
.breadcrumb {
  display: flex;
  font-size: 14px;
  padding: 0px 30px;
  .cat-breadcrumb {
    color: #cedbea;
    cursor: pointer;
  }

  .ng-binding {
    display: flex;
    align-items: center;
    color: #727989;
    img {
      width: 20px;
    }
  }
}
.openbox-container_footer {
  width: 100%;
  // min-width: 300px;
  margin: 0 auto 0 auto;

  .open-btn {
    color: #7a7a7a;

    background-color: #ffffff;
    color: #10151b;
    border-radius: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    background: linear-gradient(
      to right,
      rgba(238, 73, 70, 0) 0%,
      rgba(238, 73, 70, 0.3) 100%
    );
    height: 40px;
    line-height: 40px;
    color: #fff;
    text-align: center;
    width: 136px;
    border: 2px solid #ee4946;
    margin-left: 4px;
  }
  .cartState {
    width: 40px;
    height: 40px;
    line-height: 40px;
    color: #fff;
    text-align: center;
    background: linear-gradient(
      to right,
      rgba(238, 73, 70, 0) 0%,
      rgba(238, 73, 70, 0.3) 100%
    );
    text-align: center;
    border-radius: 99px;
    border: 2px solid #3954ed;
    border-color: #ee4946;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 4px;
    img {
      width: 20px;
      height: 20px;
    }
  }
  .cartState-0 {
    opacity: 0.4;
  }
  .choose-num {
    display: flex;
    // flex-direction: column;
    align-items: center;
    color: #fff;
    // overflow: auto;
    justify-content: center;

    .show-price {
      // background-color: #10151b;
      height: 50px;
      text-align: center;
      width: auto;
      padding: 0 20px;
      display: flex;
      align-items: center;
      font-size: 18px;
      justify-content: center;
      color: #fff;
      font-family: "微软雅黑";

      img {
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }
    }

    .nums {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      .a1 {
        background: url("../assets/img/openBox/a1.png") no-repeat;
        background-size: 100%;
      }

      .a2 {
        background: url("../assets/img/openBox/a2.png") no-repeat;
        background-size: 100%;
      }

      .a3 {
        background: url("../assets/img/openBox/a3.png") no-repeat;
        background-size: 100%;
      }

      .a4 {
        background: url("../assets/img/openBox/a4.png") no-repeat;
        background-size: 100%;
      }

      .a5 {
        background: url("../assets/img/openBox/a5.png") no-repeat;
        background-size: 100%;
      }

      .s1 {
        background: url("../assets/img/openBox/s1.png") no-repeat;
        background-size: 100%;
      }

      .s2 {
        background: url("../assets/img/openBox/s2.png") no-repeat;
        background-size: 100%;
      }

      .s3 {
        background: url("../assets/img/openBox/s3.png") no-repeat;
        background-size: 100%;
      }

      .s4 {
        background: url("../assets/img/openBox/s4.png") no-repeat;
        background-size: 100%;
      }

      .s5 {
        background: url("../assets/img/openBox/s5.png") no-repeat;
        background-size: 100%;
      }

      .num-item {
        height: 45px;
        width: 45px;
        // border: 1px solid #6279a3;
        margin-right: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        font-size: 14px;
        font-weight: 600;
        cursor: pointer;

        .ride {
          transform: rotate(45deg);
        }
      }

      .active {
        // background: #ffba00;
        // border: 1px solid #ffea95;
        // color: #10151b;
      }
    }
  }
}

.bax_num_bg {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.openbox {
  width: 100%;
  background-position: center 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: black;
  -webkit-background-size: cover;
  /* 兼容Webkit内核浏览器如Chrome和Safari */
  -o-background-size: cover;
  /* 兼容Opera */
  zoom: 1;
  // overflow: hidden;
  // overflow-y: auto;
  // background: url("../assets/img/1mdpi.png");
  width: 100%;
  height: 100%;
  // background-size: cover;
  // background-repeat: no-repeat;
  // background-color: #000;

  .switch-name {
    color: #848492;
    font-size: 16px;
    margin-right: 5px;
  }

  .switch2 {
    margin-right: 10px;
  }

  .audio {
    display: none;
  }

  .kai-box {
    max-width: 1144px;
    width: 96%;
    margin: 30px auto;
    position: relative;
    // overflow: hidden;
    padding: 20px 0px;
    background: url("../assets/img/openBox/lottery_bg.png");
    background-size: contain;
    .kai-share {
      float: right;
      color: #848492;
      font-size: 15px;
      font-weight: 600;
      padding: 9px 22px;
      background-color: #333542;
      border-radius: 5px;
    }

    z .kai-share:hover {
      background-color: #3a3f50;
      cursor: pointer;
    }
  }

  //测试开盒子 单个
  .kai-box-open2 {
    // position: absolute;
    // padding-bottom: 500px;
    width: 100%;
    height: 100%;
    // overflow: hidden;
    position: relative;

    .kai-warp11 {
      // position: fixed;
      max-width: 1144px;
      width: 100%;
      // height: 150px;
      overflow: hidden;
      position: relative;
      // margin-left: -500px;

      // background-color: #fff;
      // overflow: hidden;
      .kaibox-line {
        position: absolute;
        height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        // // width: 800px;
        // height: 223px;
        // width: auto;
        // left: 50%;
        // transform: translate(-50%, 0%);
        // top: -38px;
        // z-index: 9999;
      }

      ul {
        position: relative;
        // left: 0;
        // top: 50%;
        // transform: translateY(-50%);
        width: 16000px;
        display: flex;
        flex-wrap: nowrap;
        // z-index: 1;
        animation: run4 var(--animation-duration, 8s);
        animation-timing-function: cubic-bezier(
          0.4,
          0.89,
          0.63,
          1
        ); //动画慢 快 慢
        animation-iteration-count: 1; //播放几次动画
        animation-delay: 0s; //动画运行前等待时间
        animation-fill-mode: forwards; //动画结束 是否保持

        li {
          // width: 140px !important;
          // // height: 134px;
          // // background-image: url("../assets/img/box-skins-null.jpg");
          // background-size: 100% 100%;
          // display: flex;
          // justify-content: center;
          // align-items: center;
          // position: relative;
          // padding: 0 10px 0 4px;
          // flex-direction: column;
          // transition: all 0.4s;

          img {
            // margin-left: 10%;
            // margin-top: 10%;
            width: 80%;
            // height: 80%;
            height: auto;
          }
          // .border {
          //   position: absolute;
          //   left: 0;
          //   top: 0;
          //   bottom: 0;
          //   height: 100%;
          //   width: 4px;
          // }
          // .item-img {
          //   width: 140px;
          //   text-align: center;
          //   height: 80px;
          //   background: radial-gradient(
          //     ellipse at center,
          //     #d32ce6 -300%,
          //     #191c28 75%
          //   );
          //   .lottery-item-img {
          //     width: 100px;
          //     height: 80px;
          //   }
          //   .item-image-filter {
          //     filter: brightness(1.1) saturate(1.15);
          //   }
          // }
        }

        .li1 {
          background-image: url("../assets/img/box-skins-golden.jpg");
        }

        .li2 {
          background-image: url("../assets/img/box-skins-violet.jpg");
        }
      }
    }
  }

  //测试开盒子 单个
  .kai-box-open2m {
    // position: absolute;
    // padding-bottom: 550px;
    // left: 0;
    // width: 100%;
    // z-index: 999;

    .kai-warp11 {
      // position: fixed;
      // width: 1000px;
      // height: 150px;
      // top: 0;
      // bottom: 0;
      // margin: auto;
      // left: 38%;
      // margin-left: -500px;

      // background-color: #fff;
      // overflow: hidden;
      .kaibox-line {
        // position: absolute;
        // width: 500px !important;
        // height: auto;
        // left: 30%;
        // top: 3px;
        // z-index: 99;
      }

      .dingbu {
        position: absolute;
        width: 45%;
        left: 32.5%;
        top: -20px;
        z-index: 99;
      }

      .dibu {
        position: absolute;
        width: 45%;
        left: 32.5%;
        bottom: -10px;
        transform: rotate(180deg);
      }

      ul {
        position: relative;
        // top: 25px;
        width: 16000px;
        display: flex;
        flex-wrap: nowrap;
        z-index: 20;
        animation: run44 var(--animation-duration, 8s);
        animation-timing-function: cubic-bezier(
          0.4,
          0.89,
          0.63,
          1
        ); //动画慢 快 慢
        animation-iteration-count: 1; //播放几次动画
        animation-delay: 0s; //动画运行前等待时间
        animation-fill-mode: forwards; //动画结束 是否保持

        li {
          // width: 125px;
          // // height: 110px;
          // height: 85px !important;
          // background-image: url("../assets/img/box-skins-null.jpg");
          // background-size: 100% 100%;

          img {
            margin-left: 10%;
            margin-top: 10%;
            width: 80%;
            height: 80%;
          }
        }

        .li1 {
          background-image: url("../assets/img/box-skins-golden.jpg");
        }

        .li2 {
          background-image: url("../assets/img/box-skins-violet.jpg");
        }
      }

      @keyframes run44 {
        0% {
          left: 0;
        }

        100% {
          left: -7265px;
        }
      }
    }
  }

  .kai-box-open22 {
    // position: absolute;
    // padding-bottom: 500px;
    // top: 0;
    // left: 0;
    // width: 100%;
    // height: 80%;
    // z-index: 66;

    .kai-warp11 {
      // position: fixed;
      max-width: 1144px;
      width: 100%;
      // height: 300px;
      overflow: hidden;
      position: relative;
      // left: 50%;
      // transform: translate(-50%,-50%);
      // margin-left: -500px;

      // background-color: #fff;
      // overflow: hidden;
      .kaibox-line {
        // position: absolute;
        // height: 260px;
        // width: auto;
        // left: 128px;
        // top: -100px;
        // z-index: 99;
      }
      .line3 {
        height: 340px;
        top: -170px;
        left: 120px;
      }
      .line4 {
        height: 450px;
        top: -216px;
        left: 100px;
      }
      .line5 {
        height: 570px;
        top: -254px;
        left: 100px;
      }

      .dingbu {
        position: absolute;
        width: 100%;
        left: 0%;
        top: -150px;
        z-index: 99;
      }
      .dingbu3 {
        top: -218px;
      }
      .dingbu4 {
        top: -270px;
      }
      .dingbu5 {
        top: -300px;
      }

      .dibu {
        position: absolute;
        transform: rotate(180deg);
        width: 100%;
        bottom: -60px;
        z-index: 99;
      }
      .dibu3 {
        bottom: -70px;
      }
      .dibu4 {
        bottom: -142px;
      }
      .dibu5 {
        bottom: -214px;
      }

      .kaibox-warp2 {
        // margin-top: 10px;
        .an2 {
          .kaibox-li:nth-child(1) {
            animation-timing-function: cubic-bezier(
              0.4,
              0.75,
              0.76,
              1
            ) !important; //动画慢 快 慢
            // margin-top: -100px;
          }

          .kaibox-li:nth-child(2) {
            animation-timing-function: cubic-bezier(
              0.4,
              0.85,
              0.96,
              1
            ) !important; //动画慢 快 慢
            // animation-delay: 0.25s !important;
          }
        }
        .an3 {
          .kaibox-li:nth-child(1) {
            animation-timing-function: cubic-bezier(
              0.4,
              0.7,
              0.53,
              1
            ) !important; //动画慢 快 慢
            // margin-top: -200px;
          }

          .kaibox-li:nth-child(2) {
            animation-timing-function: cubic-bezier(
              0.4,
              0.8,
              0.73,
              1
            ) !important; //动画慢 快 慢
            // margin-top: -90px;
            // animation-delay: 0.25s !important;
          }
          .kaibox-li:nth-child(3) {
            animation-timing-function: cubic-bezier(
              0.4,
              0.9,
              0.93,
              1
            ) !important; //动画慢 快 慢
            // margin-top: 18px;
            // animation-delay: 0.25s !important;
          }
        }
        .an4 {
          .kaibox-li:nth-child(1) {
            animation-timing-function: cubic-bezier(
              0.4,
              0.6,
              0.33,
              1
            ) !important; //动画慢 快 慢
            // margin-top: -245px;
          }

          .kaibox-li:nth-child(2) {
            animation-timing-function: cubic-bezier(
              0.4,
              0.7,
              0.53,
              1
            ) !important; //动画慢 快 慢
            // margin-top: -140px;
          }
          .kaibox-li:nth-child(3) {
            animation-timing-function: cubic-bezier(
              0.4,
              0.8,
              0.73,
              1
            ) !important; //动画慢 快 慢
            // margin-top: -30px;
            // animation-delay: 0.25s !important;
          }
          .kaibox-li:nth-child(4) {
            animation-timing-function: cubic-bezier(
              0.4,
              0.9,
              0.93,
              1
            ) !important; //动画慢 快 慢
            // margin-top: 76px;
            // animation-delay: 0.25s !important;
          }
        }
        .an5 {
          .kaibox-li:nth-child(1) {
            animation-timing-function: cubic-bezier(
              0.4,
              0.5,
              0.13,
              1
            ) !important; //动画慢 快 慢
            // margin-top: -280px;
          }

          .kaibox-li:nth-child(2) {
            animation-timing-function: cubic-bezier(
              0.4,
              0.6,
              0.33,
              1
            ) !important; //动画慢 快 慢
            // margin-top: -170px;
          }
          .kaibox-li:nth-child(3) {
            animation-timing-function: cubic-bezier(
              0.4,
              0.7,
              0.53,
              1
            ) !important; //动画慢 快 慢
            // margin-top: -60px;
            // animation-delay: 0.25s !important;
          }
          .kaibox-li:nth-child(4) {
            animation-timing-function: cubic-bezier(
              0.4,
              0.8,
              0.73,
              1
            ) !important; //动画慢 快 慢
            // margin-top: 50px;
            // animation-delay: 0.25s !important;
          }
          .kaibox-li:nth-child(5) {
            animation-timing-function: cubic-bezier(
              0.4,
              0.9,
              0.93,
              1
            ) !important; //动画慢 快 慢
            // margin-top: 160px;
            // animation-delay: 0.25s !important;
          }
        }
      }

      .kaibox-ul {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        position: relative;
        flex-direction: column;
        .kaibox-li {
          //overflow: hidden;
          // position: absolute;
          // left: 0;
          // top: 0;
          animation: run51 var(--animation-duration, 8s);
          animation-iteration-count: 1 !important; //播放几次动画
          animation-delay: 0s; //动画运行前等待时间
          animation-fill-mode: forwards !important; //动画结束 是否保持

          // animation-timing-function: ease;
          // animation-iteration-count: 1;
          // animation-fill-mode: forwards;
          ul {
            width: 20000px;
            display: flex;
            flex-wrap: nowrap;
            // z-index: 20;

            li {
              // margin-left: -52px;
              // width: 200px;
              // height: 134px;
              // // height: auto;
              // background-image: url("../assets/img/box-skins-null.jpg");
              // background-size: 100% 100%;
              // transform: scale(0.7);
              // display: flex;
              // justify-content: center;
              // align-items: center;
              // position: relative;

              // border: 1px solid #fff;
              img {
                // margin-left: 2%;
                // margin-top: 10px;
                width: 80%;
                // max-height: 180px;
                height: auto;
              }
            }

            .li1 {
              background-image: url("../assets/img/box-skins-golden.jpg");
            }

            .li2 {
              background-image: url("../assets/img/box-skins-violet.jpg");
            }
          }
        }
      }

      @keyframes run51 {
        0% {
          left: 0;
        }

        100% {
          left: -9048px;
        }
      }
    }
  }

  .kai-box-open22m {
    // position: absolute;
    // padding-bottom: 500px;
    // left: 0;
    // width: 100%;
    // height: 100%;
    // z-index: 106;

    .kai-warp11 {
      // position: fixed;
      max-width: 1000px;
      width: 100%;
      overflow: hidden;
      // height: 150px;
      // top: 20%;
      // bottom: 0;
      // margin: auto;
      // left: 50%;
      // margin-left: -500px;
      .kaibox-line {
        // position: absolute;
        // width: auto;
        // height: 205px;
        // left: 21.1%;
        // top: -115px;
        // z-index: 99;
      }
      .line3 {
        top: -165px;
        left: 16.65%;
        height: 305px !important;
      }
      .line4 {
        top: -217px;
        left: 14.2%;
        height: 410px !important;
      }
      .line5 {
        top: -260px;
        left: 16.2%;
        height: 490px !important;
      }

      .dingbu3 {
        top: -190px;
      }
      .dingbu4 {
        top: -240px;
      }
      .dingbu5 {
        top: -285px;
      }

      .dibu3 {
        bottom: -10px;
      }
      .dibu4 {
        bottom: -70px;
      }
      .dibu5 {
        bottom: -106px;
      }

      .kaibox-warp2 {
        .an2 {
          .kaibox-li:nth-child(1) {
            animation-timing-function: cubic-bezier(
              0.4,
              0.75,
              0.76,
              1
            ) !important; //动画慢 快 慢
            // margin-top: -100px;
          }

          .kaibox-li:nth-child(2) {
            animation-timing-function: cubic-bezier(
              0.4,
              0.85,
              0.96,
              1
            ) !important; //动画慢 快 慢
            // margin-top: -8px;
            // animation-delay: 0.25s !important;
          }
        }
        .an3 {
          .kaibox-li:nth-child(1) {
            animation-timing-function: cubic-bezier(
              0.4,
              0.7,
              0.53,
              1
            ) !important; //动画慢 快 慢
            // margin-top: -147px;
          }

          .kaibox-li:nth-child(2) {
            animation-timing-function: cubic-bezier(
              0.4,
              0.8,
              0.73,
              1
            ) !important; //动画慢 快 慢
            // margin-top: -55px;
          }
          .kaibox-li:nth-child(3) {
            animation-timing-function: cubic-bezier(
              0.4,
              0.9,
              0.93,
              1
            ) !important; //动画慢 快 慢
            // margin-top: 38px;
            // animation-delay: 0.25s !important;
          }
        }

        .an4 {
          .kaibox-li:nth-child(1) {
            animation-timing-function: cubic-bezier(
              0.4,
              0.6,
              0.33,
              1
            ) !important; //动画慢 快 慢
            // margin-top: -196px;
          }

          .kaibox-li:nth-child(2) {
            animation-timing-function: cubic-bezier(
              0.4,
              0.7,
              0.53,
              1
            ) !important; //动画慢 快 慢
            // margin-top: -101px;
          }
          .kaibox-li:nth-child(3) {
            animation-timing-function: cubic-bezier(
              0.4,
              0.8,
              0.73,
              1
            ) !important; //动画慢 快 慢
            // margin-top: -6px;
            // animation-delay: 0.25s !important;
          }
          .kaibox-li:nth-child(4) {
            animation-timing-function: cubic-bezier(
              0.4,
              0.9,
              0.93,
              1
            ) !important; //动画慢 快 慢
            // margin-top: 89px;
            // animation-delay: 0.25s !important;
          }
        }
        .an5 {
          .kaibox-li:nth-child(1) {
            animation-timing-function: cubic-bezier(
              0.4,
              0.5,
              0.13,
              1
            ) !important; //动画慢 快 慢
            // margin-top: -243px;
          }

          .kaibox-li:nth-child(2) {
            animation-timing-function: cubic-bezier(
              0.4,
              0.6,
              0.33,
              1
            ) !important; //动画慢 快 慢
            // margin-top: -149px;
          }
          .kaibox-li:nth-child(3) {
            animation-timing-function: cubic-bezier(
              0.4,
              0.7,
              0.53,
              1
            ) !important; //动画慢 快 慢
            // margin-top: -56px;
            // animation-delay: 0.25s !important;
          }
          .kaibox-li:nth-child(4) {
            animation-timing-function: cubic-bezier(
              0.4,
              0.8,
              0.73,
              1
            ) !important; //动画慢 快 慢
            // margin-top: 36px;
            // animation-delay: 0.25s !important;
          }
          .kaibox-li:nth-child(5) {
            animation-timing-function: cubic-bezier(
              0.4,
              0.9,
              0.93,
              1
            ) !important; //动画慢 快 慢
            // margin-top: 128px;
            // animation-delay: 0.25s !important;
          }
        }
      }

      .kaibox-ul {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        position: relative;
        flex-direction: column;
        .kaibox-li {
          //overflow: hidden;
          // margin-left: -45px;
          // position: absolute;
          // left: 0;
          // top: 0;
          animation: run5 var(--animation-duration, 8s);
          animation-iteration-count: 1 !important; //播放几次动画
          animation-delay: 0s; //动画运行前等待时间
          animation-fill-mode: forwards !important; //动画结束 是否保持

          // animation-timing-function: ease;
          // animation-iteration-count: 1;
          // animation-fill-mode: forwards;
          ul {
            width: 20000px;
            display: flex;
            flex-wrap: nowrap;
            z-index: 20;

            li {
              // width: 125px;
              // height: 85px;
              // background-image: url("../assets/img/box-skins-null.jpg");
              // background-size: 100% 100%;
              // display: flex;
              // justify-content: center;
              // align-items: center;
              // border: 1px solid #fff;
              img {
                // margin-left: 2%;
                width: 80%;
                height: auto;
                // max-height: 180px;
                // margin-top: -5px !important;
              }
            }

            .li1 {
              background-image: url("../assets/img/box-skins-golden.jpg");
            }

            .li2 {
              background-image: url("../assets/img/box-skins-violet.jpg");
            }
          }
        }
      }

      @keyframes run5 {
        0% {
          left: 0;
        }

        100% {
          left: -9422px;
        }
      }
    }
  }

  //测试开多个盒子
  .kai-box-open3 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 870px;
    margin-top: 32px;
    z-index: 106;

    .kai-warp1 {
      position: fixed;
      width: 870px;
      left: 120px;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      margin-top: 10%;
      display: flex;
      justify-content: center;
      // overflow: hidden;

      .kaibox-line {
        position: absolute;
        width: 130%;
        // height: 860px;
        // transform: rotate(270deg);
        left: -9%;
        top: 45%;
        z-index: 99;
      }

      .dingbu {
        position: absolute;
        width: 100%;
        left: -70px;
        top: -80px;
        z-index: 99;
      }

      .dibu {
        position: absolute;
        width: 100%;
        left: -70px;
        bottom: -105px;
        transform: rotate(180deg);
        z-index: 99;
      }

      .kaibox-warp {
        height: 480px;
        padding-top: 60px;
        overflow: hidden;
        width: 100%;

        .kaibox-ul {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          position: relative;
          flex-direction: column;
          .kaibox-li {
            //overflow: hidden;
            width: 290px;
            position: absolute;
            top: 0;
            left: 0;
            animation: run5 var(--animation-duration, 8s);
            animation-timing-function: cubic-bezier(
              0.4,
              0.89,
              0.63,
              1
            ) !important; //动画慢 快 慢
            animation-iteration-count: 1 !important; //播放几次动画
            animation-delay: 0s; //动画运行前等待时间
            animation-fill-mode: forwards !important; //动画结束 是否保持

            // animation-timing-function: ease;
            // animation-iteration-count: 1;
            // animation-fill-mode: forwards;
            ul {
              li {
                width: 240px;
                height: 180px;
                background-image: url("../assets/img/box-skins-null.jpg");
                background-size: 100% 100%;

                // border: 1px solid #fff;
                img {
                  margin-left: 2%;
                  margin-top: 10px;
                  width: 95%;
                  max-height: 180px;
                }
              }

              .li1 {
                background-image: url("../assets/img/box-skins-golden.jpg");
              }

              .li2 {
                background-image: url("../assets/img/box-skins-violet.jpg");
              }
            }
          }
        }
      }

      .kaibox-warp3 {
        //width: 900px;
        .kaibox-ul {
          .kaibox-li:nth-child(1) {
            animation-delay: 0.15s !important;
          }

          .kaibox-li:nth-child(2) {
            left: 240px;
            animation-delay: 0.3s !important;
          }

          .kaibox-li:nth-child(3) {
            left: 480px;
            animation-delay: 0.45s !important;
          }
        }
      }

      // @keyframes run5 {
      //   0% {
      //     top: 0;
      //   }
      //   100% {
      //     //50
      //     top: -10000px;
      //   }
      // }
    }
  }

  //测试开多个盒子
  .kai-box-open3m {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    // height: 100vh;
    margin-top: 32px;
    z-index: 999;

    .kai-warp1 {
      position: fixed;
      width: 350px;
      left: 50%;
      top: 50%;
      margin-left: -165px;
      transform: translateY(-50%);

      .kaibox-line {
        position: absolute;
        width: 112%;
        // height: 380px;
        // transform: rotate(270deg);
        left: -9%;
        top: 45%;
        z-index: 99;
      }

      .dingbu {
        position: absolute;
        width: 106%;
        left: -20px;
        top: -28px;
        z-index: 99;
      }

      .dibu {
        position: absolute;
        width: 106%;
        left: -20px;
        bottom: -40px;
        transform: rotate(180deg);
        z-index: 99;
      }

      .kaibox-warp {
        height: 188px;
        padding-top: 92px;
        overflow: hidden;
        width: 100%;

        .kaibox-ul {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          position: relative;
          flex-direction: column;
          .kaibox-li {
            //overflow: hidden;
            width: 290px;
            position: absolute;
            top: 0;
            animation: run5 var(--animation-duration, 8s);
            animation-timing-function: cubic-bezier(
              0.4,
              0.89,
              0.63,
              1
            ) !important; //动画慢 快 慢
            animation-iteration-count: 1 !important; //播放几次动画
            animation-delay: 0s; //动画运行前等待时间
            animation-fill-mode: forwards !important; //动画结束 是否保持

            // animation-timing-function: ease;
            // animation-iteration-count: 1;
            // animation-fill-mode: forwards;
            ul {
              li {
                width: 110px;
                height: 96.8px;
                background-image: url("../assets/img/box-skins-null.jpg");
                background-size: 100% 100%;

                // border: 1px solid #fff;
                img {
                  margin-left: 2%;
                  margin-top: 5px !important;
                  width: 95% !important;
                }
              }

              .li1 {
                background-image: url("../assets/img/box-skins-golden.jpg");
              }

              .li2 {
                background-image: url("../assets/img/box-skins-violet.jpg");
              }
            }
          }
        }
      }

      .kaibox-warp3 {
        //width: 900px;
        .kaibox-ul {
          .kaibox-li:nth-child(1) {
            animation-delay: 0.15s !important;
          }

          .kaibox-li:nth-child(2) {
            left: 110px;
            animation-delay: 0.3s !important;
          }

          .kaibox-li:nth-child(3) {
            left: 220px;
            animation-delay: 0.45s !important;
          }
        }
      }

      // @keyframes run5 {
      //   0% {
      //     top: 0;
      //   }
      //   100% {
      //     //50
      //     top: -10013px;
      //   }
      // }
    }
  }

  //测试开多个盒子
  .kai-box-open4 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 870px;
    margin-top: 32px;
    z-index: 106;

    .kai-warp1 {
      position: fixed;
      width: 870px;
      left: 120px;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      margin-top: 10%;
      display: flex;
      justify-content: center;
      // overflow: hidden;

      .kaibox-line {
        position: absolute;
        width: 110%;
        // height: 860px;
        // transform: rotate(270deg);
        left: -13%;
        top: 45%;
        z-index: 99;
      }

      .dingbu {
        position: absolute;
        width: 100%;
        left: -70px;
        top: -80px;
        z-index: 99;
      }

      .dibu {
        position: absolute;
        width: 100%;
        left: -70px;
        bottom: -105px;
        transform: rotate(180deg);
        z-index: 99;
      }

      .kaibox-warp {
        height: 490px;
        padding-top: 50px;
        margin-left: -60px;
        overflow: hidden;
        width: 100%;

        .kaibox-ul {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          position: relative;
          flex-direction: column;
          .kaibox-li {
            //overflow: hidden;
            width: 250px;
            position: absolute;
            top: 0;
            left: 0;
            animation: run5 var(--animation-duration, 8s);
            animation-timing-function: cubic-bezier(
              0.4,
              0.89,
              0.63,
              1
            ) !important; //动画慢 快 慢
            animation-iteration-count: 1 !important; //播放几次动画
            animation-delay: 0s; //动画运行前等待时间
            animation-fill-mode: forwards !important; //动画结束 是否保持

            // animation-timing-function: ease;
            // animation-iteration-count: 1;
            // animation-fill-mode: forwards;
            ul {
              li {
                width: 200px;
                height: 150px;
                background-image: url("../assets/img/box-skins-null.jpg");
                background-size: 100% 100%;

                // border: 1px solid #fff;
                img {
                  margin-left: 2%;
                  margin-top: 10px;
                  width: 95%;
                  max-height: 180px;
                }
              }

              .li1 {
                background-image: url("../assets/img/box-skins-golden.jpg");
              }

              .li2 {
                background-image: url("../assets/img/box-skins-violet.jpg");
              }
            }
          }
        }
      }

      .kaibox-warp4 {
        //width: 900px;
        .kaibox-ul {
          .kaibox-li:nth-child(1) {
            left: 0;
            animation-delay: 0.15s !important;
          }

          .kaibox-li:nth-child(2) {
            left: 200px;
            animation-delay: 0.3s !important;
          }

          .kaibox-li:nth-child(3) {
            left: 400px;
            animation-delay: 0.45s !important;
          }

          .kaibox-li:nth-child(4) {
            left: 600px;
            animation-delay: 0.45s !important;
          }
        }
      }

      // @keyframes run5 {
      //   0% {
      //     top: 0;
      //   }
      //   100% {
      //     //50
      //     top: -10000px;
      //   }
      // }
    }
  }

  //测试开多个盒子
  .kai-box-open4m {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    // height: 100vh;
    margin-top: 32px;
    z-index: 999;

    .kai-warp1 {
      position: fixed;
      width: 350px;
      left: 50%;
      top: 50%;
      margin-left: -110px;
      transform: translateY(-50%);

      .kaibox-line {
        position: absolute;
        width: 112%;
        // height: 380px;
        // transform: rotate(270deg);
        left: -24.5%;
        top: 45%;
        z-index: 99;
      }

      .dingbu {
        position: absolute;
        width: 106%;
        left: -75px;
        top: -32px;
        z-index: 99;
      }

      .dibu {
        position: absolute;
        width: 106%;
        left: -75px;
        bottom: -40px;
        transform: rotate(180deg);
        z-index: 99;
      }

      .kaibox-warp {
        height: 160px;
        padding-top: 100px;
        margin-left: -68px;
        overflow: hidden;

        .kaibox-ul {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          position: relative;
          flex-direction: column;
          .kaibox-li {
            //overflow: hidden;
            width: 250px;
            position: absolute;
            top: 0;
            left: 0;
            animation: run5 var(--animation-duration, 8s);
            animation-timing-function: cubic-bezier(
              0.4,
              0.89,
              0.63,
              1
            ) !important; //动画慢 快 慢
            animation-iteration-count: 1 !important; //播放几次动画
            animation-delay: 0s; //动画运行前等待时间
            animation-fill-mode: forwards !important; //动画结束 是否保持

            // animation-timing-function: ease;
            // animation-iteration-count: 1;
            // animation-fill-mode: forwards;
            ul {
              li {
                width: 90px;
                height: 88px;
                background-image: url("../assets/img/box-skins-null.jpg");
                background-size: 100% 100%;

                // border: 1px solid #fff;
                img {
                  margin-left: 2%;
                  margin-top: 10px !important;
                  width: 95%;
                  max-height: 180px;
                }
              }

              .li1 {
                background-image: url("../assets/img/box-skins-golden.jpg");
              }

              .li2 {
                background-image: url("../assets/img/box-skins-violet.jpg");
              }
            }
          }
        }
      }

      .kaibox-warp4 {
        //width: 900px;
        .kaibox-ul {
          .kaibox-li:nth-child(1) {
            left: 0;
            animation-delay: 0.15s !important;
          }

          .kaibox-li:nth-child(2) {
            left: 90px;
            animation-delay: 0.3s !important;
          }

          .kaibox-li:nth-child(3) {
            left: 180px;
            animation-delay: 0.45s !important;
          }

          .kaibox-li:nth-child(4) {
            left: 270px;
            animation-delay: 0.45s !important;
          }
        }
      }

      // @keyframes run5 {
      //   0% {
      //     top: 0;
      //   }
      //   100% {
      //     //50
      //     top: -10000px;
      //   }
      // }
    }
  }

  //测试开多个盒子
  .kai-box-open5 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    // height: 100vh;
    margin-top: 32px;
    z-index: 999;

    .kai-warp1 {
      position: fixed;
      width: 870px;
      left: 120px;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      margin-top: 10%;
      display: flex;
      justify-content: center;
      // overflow: hidden;

      .kaibox-line {
        position: absolute;
        width: 104%;
        // height: 860px;
        // transform: rotate(270deg);
        left: -9%;
        top: 42%;
        z-index: 99;
      }

      .dingbu {
        position: absolute;
        width: 100%;
        left: -70px;
        top: -65px;
        z-index: 99;
      }

      .dibu {
        position: absolute;
        width: 100%;
        left: -70px;
        bottom: -105px;
        transform: rotate(180deg);
        z-index: 99;
      }

      .kaibox-warp {
        height: 400px;
        padding-top: 102px;
        overflow: hidden;
        width: 100%;

        .kaibox-ul {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          position: relative;
          flex-direction: column;
          .kaibox-li {
            //overflow: hidden;
            width: 250px;
            position: absolute;
            top: 0;
            left: 0;
            animation: run5 var(--animation-duration, 8s);
            animation-timing-function: cubic-bezier(
              0.4,
              0.89,
              0.63,
              1
            ) !important; //动画慢 快 慢
            animation-iteration-count: 1 !important; //播放几次动画
            animation-delay: 0s; //动画运行前等待时间
            animation-fill-mode: forwards !important; //动画结束 是否保持

            // animation-timing-function: ease;
            // animation-iteration-count: 1;
            // animation-fill-mode: forwards;
            ul {
              li {
                width: 150px;
                height: 112.5px;
                background-image: url("../assets/img/box-skins-null.jpg");
                background-size: 100% 100%;

                // border: 1px solid #fff;
                img {
                  margin-left: 2%;
                  margin-top: 10px;
                  width: 95%;
                  max-height: 180px;
                }
              }

              .li1 {
                background-image: url("../assets/img/box-skins-golden.jpg");
              }

              .li2 {
                background-image: url("../assets/img/box-skins-violet.jpg");
              }
            }
          }
        }
      }

      .kaibox-warp5 {
        //width: 900px;
        .kaibox-ul {
          .kaibox-li:nth-child(1) {
            animation-delay: 0.15s !important;
          }

          .kaibox-li:nth-child(2) {
            left: 150px;
            animation-delay: 0.3s !important;
          }

          .kaibox-li:nth-child(3) {
            left: 300px;
            animation-delay: 0.45s !important;
          }

          .kaibox-li:nth-child(4) {
            left: 450px;
            animation-delay: 0.45s !important;
          }

          .kaibox-li:nth-child(5) {
            left: 600px;
            animation-delay: 0.45s !important;
          }
        }
      }

      // @keyframes run5 {
      //   0% {
      //     top: 0;
      //   }
      //   100% {
      //     //50
      //     top: -10000px;
      //   }
      // }
    }
  }

  //测试开多个盒子
  .kai-box-open5m {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    // height: 100vh;
    margin-top: 32px;
    z-index: 999;

    .kai-warp1 {
      position: fixed;
      width: 335px;
      left: 50%;
      top: 50%;
      margin-left: -105px;
      transform: translateY(-50%);

      .kaibox-line {
        position: absolute;
        width: 123%;
        // height: 380px;
        // transform: rotate(270deg);
        left: -30.5%;
        top: 45%;
        z-index: 99;
      }

      .dingbu {
        position: absolute;
        width: 114%;
        left: -85px;
        top: -32px;
        z-index: 99;
      }

      .dibu {
        position: absolute;
        width: 114%;
        left: -85px;
        bottom: -40px;
        transform: rotate(180deg);
        z-index: 99;
      }

      .kaibox-warp {
        height: 245px;
        padding-top: 30px;
        margin-left: -72px;
        overflow: hidden;

        .kaibox-ul {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          position: relative;
          flex-direction: column;
          .kaibox-li {
            //overflow: hidden;
            width: 250px;
            position: absolute;
            top: 0;
            left: 0;
            animation: run5 var(--animation-duration, 8s);
            animation-timing-function: cubic-bezier(
              0.4,
              0.89,
              0.63,
              1
            ) !important; //动画慢 快 慢
            animation-iteration-count: 1 !important; //播放几次动画
            animation-delay: 0s; //动画运行前等待时间
            animation-fill-mode: forwards !important; //动画结束 是否保持

            // animation-timing-function: ease;
            // animation-iteration-count: 1;
            // animation-fill-mode: forwards;
            ul {
              li {
                width: 70px;
                height: 69px;
                background-image: url("../assets/img/box-skins-null.jpg");
                background-size: 100% 100%;

                // border: 1px solid #fff;
                img {
                  margin-left: 2%;
                  margin-top: 12px !important;
                  width: 95%;
                  max-height: 180px;
                }
              }

              .li1 {
                background-image: url("../assets/img/box-skins-golden.jpg");
              }

              .li2 {
                background-image: url("../assets/img/box-skins-violet.jpg");
              }
            }
          }
        }
      }

      .kaibox-warp5 {
        //width: 900px;
        .kaibox-ul {
          .kaibox-li:nth-child(1) {
            left: 0;
            animation-delay: 0.15s !important;
          }

          .kaibox-li:nth-child(2) {
            left: 70px;
            animation-delay: 0.3s !important;
          }

          .kaibox-li:nth-child(3) {
            left: 140px;
            animation-delay: 0.45s !important;
          }

          .kaibox-li:nth-child(4) {
            left: 210px;
            animation-delay: 0.45s !important;
          }

          .kaibox-li:nth-child(5) {
            left: 280px;
            animation-delay: 0.45s !important;
          }
        }
      }

      // @keyframes run5 {
      //   0% {
      //     top: 0;
      //   }
      //   100% {
      //     //50
      //     top: -10000px;
      //   }
      // }
    }
  }

  // .kai-box-open4 {
  // 	position: absolute;
  // 	top: 0;
  // 	left: 0;
  // 	width: 100%;
  // 	height: 100%;
  // 	background-color: rgba(0, 0, 0, 0.5);
  // 	z-index: 66;

  // 	.kai-warp1 {
  // 		position: absolute;
  // 		width: 100%;
  // 		height: 120px;
  // 		top: 30%;
  // 		left: 0;
  // 		display: flex;
  // 		justify-content: center;

  // 		.kaibox-line {
  // 			width: 100%;
  // 			height: 2px;
  // 			position: absolute;
  // 			top: 59px;
  // 			left: 0;
  // 			z-index: 99;
  // 			background-color: #e9b10e;
  // 		}

  // 		.kaibox-warp {
  // 			overflow: hidden;
  // 			width: 100%;

  // 			.kaibox-ul {
  // 				width: 100%;
  // 				display: flex;
  // 				flex-wrap: nowrap;
  // 				position: relative;

  // 				.kaibox-li {
  // 					//overflow: hidden;
  // 					width: 20%;
  // 					position: absolute;
  // 					top: 0;
  // 					left: 0;
  // 					animation: run6 10s;
  // 					animation-timing-function: cubic-bezier(0.4,
  // 							0.89,
  // 							0.63,
  // 							0.93) !important; //动画慢 快 慢
  // 					animation-iteration-count: 1 !important; //播放几次动画
  // 					animation-delay: 0s; //动画运行前等待时间
  // 					animation-fill-mode: forwards !important; //动画结束 是否保持

  // 					ul {
  // 						li {
  // 							width: 100%;
  // 							height: 120px;
  // 							background-image: url("../assets/img/box-skins-null.jpg");
  // 							background-size: 100% 100%;

  // 							// border: 1px solid #fff;
  // 							img {
  // 								// margin-left: 2%;
  // 								margin-top: 10px;
  // 								width: 95%;
  // 								max-height: 100px;
  // 							}
  // 						}

  // 						.li1 {
  // 							background-image: url("../assets/img/box-skins-golden.jpg");
  // 						}

  // 						.li2 {
  // 							background-image: url("../assets/img/box-skins-violet.jpg");
  // 						}
  // 					}
  // 				}
  // 			}
  // 		}

  // 		.kaibox-warp2 {

  // 			//width: 600px;
  // 			.kaibox-ul {
  // 				.kaibox-li:nth-child(1) {
  // 					left: 30%;
  // 					animation-delay: 0.25s !important;
  // 				}

  // 				.kaibox-li:nth-child(2) {
  // 					left: 49.5%;
  // 					animation-delay: 0.5s !important;
  // 				}
  // 			}
  // 		}

  // 		.kaibox-warp3 {

  // 			//width: 900px;
  // 			.kaibox-ul {
  // 				.kaibox-li:nth-child(1) {
  // 					left: 20%;
  // 					animation-delay: 0.15s !important;
  // 				}

  // 				.kaibox-li:nth-child(2) {
  // 					left: 39.5%;
  // 					animation-delay: 0.3s !important;
  // 				}

  // 				.kaibox-li:nth-child(3) {
  // 					left: 59%;
  // 					animation-delay: 0.45s !important;
  // 				}
  // 			}
  // 		}

  // 		.kaibox-warp4 {

  // 			//width: 1200px;
  // 			.kaibox-ul {
  // 				.kaibox-li:nth-child(1) {
  // 					left: 10%;
  // 					animation-delay: 0.15s !important;
  // 				}

  // 				.kaibox-li:nth-child(2) {
  // 					left: 29.5%;
  // 					animation-delay: 0.3s !important;
  // 				}

  // 				.kaibox-li:nth-child(3) {
  // 					left: 49%;
  // 					animation-delay: 0.45s !important;
  // 				}

  // 				.kaibox-li:nth-child(4) {
  // 					left: 68.5%;
  // 					animation-delay: 0.6s !important;
  // 				}
  // 			}
  // 		}

  // 		.kaibox-warp5 {

  // 			//width: 1500px;
  // 			.kaibox-ul {
  // 				.kaibox-li:nth-child(1) {
  // 					left: 0;
  // 					animation-delay: 0.15s !important;
  // 				}

  // 				.kaibox-li:nth-child(2) {
  // 					left: 19.9%;
  // 					animation-delay: 0.3s !important;
  // 				}

  // 				.kaibox-li:nth-child(3) {
  // 					left: 39.8%;
  // 					animation-delay: 0.45s !important;
  // 				}

  // 				.kaibox-li:nth-child(4) {
  // 					left: 59.7%;
  // 					animation-delay: 0.6s !important;
  // 				}

  // 				.kaibox-li:nth-child(5) {
  // 					left: 79.6%;
  // 					animation-delay: 0.75s !important;
  // 				}
  // 			}
  // 		}

  // 		// @keyframes run6 {
  // 		//   0% {
  // 		//     top: 0;
  // 		//   }
  // 		//   100% {
  // 		//     //50
  // 		//     top: -6000px;
  // 		//   }
  // 		// }
  // 	}
  // }

  //中奖后盒子
  .win-box {
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.9);
    display: grid;
    place-items: center;
  }

  .topic {
    width: 15%;
    min-width: 160px;
    margin: 50px 0;
  }

  .kai-masked {
    width: 100%;
    min-height: 600px;

    /deep/ .el-loading-mask {
      background-color: inherit !important;

      .circular {
        width: 64px;
        height: 64px;
      }

      .el-loading-spinner .path {
        stroke: #ff9b0b;
      }

      .el-loading-spinner .el-loading-text {
        color: #ff9b0b;
        font-size: 18px;
        margin-top: 12px;
      }
    }
  }

  // overflow: hidden;

  .kai-con {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #1e2335;

    .con-name {
      margin-top: 20px;
      font-size: 24px;
      color: #f4bd50;
    }

    .con-list {
      margin-top: 20px;
      width: 100%;
      // min-width: 1200px;
      padding-bottom: 40px;
      // height: 700px;
      // background-image: url("../assets/img/open-box/3d99ed39-00c5-4546-bd3f-2a75bbf8b01a.webp");
      // background-repeat: no-repeat;
      // background-position: center;
      // background-size: 100% 100%;
      display: flex;
      flex-direction: column;

      & > ul {
        // width: 100%;
        display: flex;
        justify-content: center;
        // margin-top: 40px;
        // height: calc(100% - 420px);

        li {
          // max-width: 140px;
          width: 100%;
          // max-width: 600px;
          height: 100%;

          .conlist-warp {
            position: relative;
            margin: 0 5px;
            text-align: center;
            display: flex;
            justify-content: center;
            height: 100%;
            flex-direction: column;
            .type_name {
              color: #fff;
              font-size: 20px;
              font-weight: 700;
            }
            .sound {
              margin-top: 40px;
            }
            .sound-0 {
              opacity: 0.4;
            }
            .conlist-box {
              height: 100%;

              //   height: 500px;
              text-align: center;

              img {
                width: 100%;
                height: 100%;
              }
            }
            .conlist-box-outside {
              display: flex;
              justify-content: center;
            }
            .conlist-box1 {
              width: 100%;
              max-width: 300px;
              position: relative;
              // position: absolute;
              // left: 0;
              // top: 60%;
              // transform: translateY(-50%);
              // left: 20%;

              img {
                width: 70%;
                // height: 80%;
              }

              img:last-child {
                width: 70%;
                // height: 50%;
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                margin: auto;
                animation: boxhover 1.5s linear 0s infinite alternate;
              }
            }
          }
        }

        li:hover {
          cursor: pointer;
        }

        // li:hover .conlist-box1 {
        // 	position: absolute;
        // 	top: 50%;
        // 	transform: translateY(-50%);
        // 	animation: boxhover 1.5s linear 0s infinite alternate;
        // }

        @keyframes boxhover {
          0% {
            top: calc(-10px);
          }

          50% {
            top: calc(-50px);
          }

          100% {
            top: calc(-10px);
          }
        }
      }
    }

    .con-num {
      margin-top: -120px;
      height: 70px;
      width: 100%;
      max-width: 600px;
      text-align: center;

      ul {
        display: flex;
        justify-content: center;
        height: 100%;

        li {
          width: 100px;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;

          span {
            z-index: 88;
            font-size: 20px;
            color: #f4bd50;
          }
        }

        li:hover {
          cursor: pointer;
          // background-color: #444659;
          opacity: 0.8 !important;
        }

        .con-num-check {
          // background-color: #ff9b0b;
          span {
            color: #f4bd50;
          }
        }
      }
    }

    .buy-container {
      margin-top: 20px;
      display: flex;
      align-items: center;
    }

    .con-btn {
      display: flex;
      margin-right: 20px;
      justify-content: space-between;
      align-items: center;
      padding: 10px 30px;
      border-radius: 40px;
      // border: 1px solid #e9b10e;
      border: 1px solid #e9b10e;

      // background-image:linear-gradient(to right,#87cde0, #c537bc);
      //background-color: #93b0d9;
      .con-btn1 {
        padding: 4px 16px;
        display: flex;
        align-items: center;
        cursor: pointer;

        span {
          font-size: 15px;
          color: #e9b10e;
          font-weight: 600;
          margin-left: 5px;
        }

        img {
          width: auto;
          height: 20px;
          margin-right: 10px;
        }
      }

      .con-btn2 {
        border: none;
        background-image: linear-gradient(to right, #ff571b, #ff9b0b);
        color: white;
        // padding: 8px 16px;
        border-radius: 20px;
        font-size: 14px;
        margin-right: -1px;
      }

      .con-btn2:hover {
        cursor: pointer;
      }
    }
  }

  .kai-conm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #1e2335;

    .con-name {
      margin-top: 20px;
      font-size: 24px;
      color: #f4bd50;
    }

    .con-list {
      width: 100%;

      ul {
        width: 100%;
        display: flex;
        justify-content: center;

        li {
          width: 100%;
          // max-width: 600px;

          .conlist-warp {
            // border-radius: 50%;
            position: relative;
            // background-color: rgba(65, 105, 161, 0.2);
            // padding: 14% 0;
            margin: 0 5px;
            text-align: center;
            display: flex;
            justify-content: center;
            .type_name {
              color: #fff;
            }
            .conlist-box {
              height: auto;
              text-align: center;

              img {
                width: 70%;
                height: 100%;
              }
            }

            .conlist-box1 {
              width: 60%;
              position: absolute;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
              left: 20%;
              display: flex;
              justify-content: center;
              animation: boxhover 1.5s linear 0s infinite alternate;

              img {
                width: 50%;
                height: 80%;
              }
            }
          }
        }

        li:hover {
          cursor: pointer;
        }

        // li:hover .conlist-box1 {
        // 	position: absolute;
        // 	top: 50%;
        // 	transform: translateY(-50%);
        // 	animation: boxhover 1.5s linear 0s infinite alternate;
        // }

        @keyframes boxhover {
          0% {
            top: calc(-10px);
          }

          50% {
            top: calc(-50px);
          }

          100% {
            top: calc(-10px);
          }
        }
      }
    }

    .con-num {
      margin-top: -35px;
      height: 70px;
      width: 100%;
      max-width: 600px;
      text-align: center;

      ul {
        display: flex;
        justify-content: center;
        height: 100%;

        li {
          width: 60px;
          height: 80%;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;

          span {
            z-index: 88;
            font-size: 20px;
            color: #f4bd50;
          }
        }

        li:hover {
          cursor: pointer;
          // background-color: #444659;
          opacity: 0.8 !important;
        }

        .con-num-check {
          // background-color: #ff9b0b;
          span {
            color: #f4bd50;
          }
        }
      }
    }

    .buy-container {
      margin-top: 40px;
      display: flex;
      align-items: center;
    }

    .con-btn {
      display: flex;
      margin-right: 20px;
      justify-content: space-between;
      align-items: center;
      padding: 5px 16px;
      border-radius: 40px;
      // border: 1px solid #e9b10e;
      border: 1px solid #e9b10e;

      // background-image:linear-gradient(to right,#87cde0, #c537bc);
      //background-color: #93b0d9;
      .con-btn1 {
        padding: 4px 16px;
        display: flex;
        align-items: center;
        cursor: pointer;

        span {
          font-size: 15px;
          color: #e9b10e;
          font-weight: 600;
          margin-left: 5px;
        }

        img {
          width: auto;
          height: 20px;
          margin-right: 10px;
        }
      }

      .con-btn2 {
        border: none;
        background-image: linear-gradient(to right, #ff571b, #ff9b0b);
        color: white;
        // padding: 8px 16px;
        border-radius: 20px;
        font-size: 14px;
        margin-right: -1px;
      }

      .con-btn2:hover {
        cursor: pointer;
      }
    }
  }

  .kai-num {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      font-size: 16px;
      color: #848492;
      display: flex;
      align-items: center;
    }

    i {
      color: #0e0d0d;
      font-size: 20px;
    }

    h6 {
      font-size: 16px;
      color: #848492;
      font-weight: 200;
    }
  }

  .box-list {
    padding: 20px 16px 16px 16px;
    background-color: #292f47;

    .boxlist-top {
      height: 40px !important;
      display: flex;
      width: max-content;
      justify-content: center;
      margin: 10px auto 20px auto;
      //   border-bottom: 1px solid #f3ab34;
      //   background-color: #f3ab34;
      border: 1px solid #283347;
      border-radius: 50px;
      align-items: center;
      padding-bottom: 1px;
      overflow: hidden;

      .left {
        span {
          font-size: 14px;
          color: #c3c3e2;
          display: inline-block;
          height: 40px;
          line-height: 40px;
          width: 120px;
          text-align: center;
        }

        .span1 {
          //   border-bottom: 2px solid #f3ab34;
          background-color: #f3ab34;
          color: #000;
          border-radius: 50px;
          // font-weight: bold;
          //   background-image: url("../assets/new/bg_border.png");
        }

        span:hover {
          cursor: pointer;
        }
      }

      .right {
        display: flex;
        align-items: center;

        .right-one,
        .right-two {
          display: flex;
        }

        .right-data {
          display: flex;
          align-items: center;

          .percent {
            margin-right: 15px;
            display: flex;
            align-items: center;

            span {
              display: table;
              width: 26px;
              height: 7px;
              border-radius: 5px;
            }

            .span1 {
              background-color: #0f0f0f;
            }

            .span11 {
              color: #0e0d0d;
            }

            .span2 {
              background-color: #b969d4;
            }

            .span12 {
              color: #b969d4;
            }

            .span3 {
              background-color: #adc8cd;
            }

            .span13 {
              color: #adc8cd;
            }
          }
        }
      }
    }

    .boxlist-bot {
      zoom: 0.64;
      padding: 10px 24%;

      ul {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(160px, 160px));
        gap: 8px;

        li {
          width: 100%;
          background-size: 100% auto;

          background-repeat: no-repeat;
          cursor: pointer;

          .boxlist-warp {
            // background-color: #24252f;
            margin: 8px;
            border-radius: 5px;
            display: flex;
            position: relative;
            flex-direction: column;
            justify-content: space-between;
            height: calc(100% - 20px);

            .boxlist1-top {
              padding: 0 20px;
              background-size: 100% 100%;
              text-align: center;
              // border-bottom: 3px solid #f1a920;
              border-top-left-radius: 5px;
              border-top-right-radius: 5px;

              // box-shadow: 0px 5px 10px #f1a920;
              img {
                width: 100%;
                margin-top: 20%;
                height: auto;
                min-height: 100px;
                cursor: pointer;
              }
            }

            .boxlist1-bot {
              padding: 8px;
              color: #c3c3e2;
              text-overflow: ellipsis;
              overflow: hidden;
              font-size: 14px;
              white-space: nowrap;
              // word-wrap: break-word;
            }

            .boxlist1-bot1 {
              padding: 8px;
              display: flex;
              align-items: center;

              img {
                width: 25px;
                height: 25px;
                border-radius: 50%;
              }

              span {
                margin-left: 8px;
                color: #c3c3e2;
                font-size: 14px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
              }
            }

            .list-pirce {
              padding: 0px 5px;
              display: flex;
              justify-content: space-between;
              align-items: center;
            }

            .ico {
              position: absolute;
              top: 0;
              left: 0;
              font-size: 12px;
              color: #04f540;
              background-color: rgba(0, 0, 0, 0.5);
              padding: 2px 4px;
            }

            .pirce-left {
              display: flex;
              align-items: center;
              padding-right: 10px;
              font-size: 13px;
              color: #a5aabd;

              img {
                width: auto;
                height: 15px;
                margin-right: 5px;
              }

              & > div {
                flex: 1;
                color: #f4bd50;
                font-size: 14px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                span:last-child {
                  color: #c3c3e2;
                }
              }
            }

            .pirce-right {
              color: #848492;
              font-size: 14px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }

    .boxlist-botm {
      zoom: 0.64;
      padding: 0 0px;

      ul {
        width: 100%;
        display: grid;
        grid-gap: 10px 0;
        justify-content: space-between;
        grid-template-columns: repeat(auto-fill, 32%);

        li {
          width: 100%;
          background-size: 100% 100%;
          background-repeat: no-repeat;
          cursor: pointer;

          .boxlist-warp {
            // background-color: #24252f;
            margin: 12px;
            border-radius: 5px;
            display: flex;
            position: relative;
            flex-direction: column;
            justify-content: space-between;
            height: calc(100% -20px);

            .boxlist1-top {
              padding: 0 20px;
              background-size: 100% 100%;
              text-align: center;
              // border-bottom: 3px solid #f1a920;
              border-top-left-radius: 5px;
              border-top-right-radius: 5px;

              // box-shadow: 0px 5px 10px #f1a920;
              img {
                width: 100%;
                margin-top: 10%;
                height: auto;
                cursor: pointer;
              }
            }

            .boxlist1-bot {
              padding: 8px;
              color: #c3c3e2;
              text-overflow: ellipsis;
              overflow: hidden;
              font-size: 10px;
              white-space: nowrap;
              // word-wrap: break-word;
            }

            .boxlist1-bot1 {
              padding: 8px;
              display: flex;
              align-items: center;

              img {
                width: 25px;
                height: 25px;
                border-radius: 50%;
              }

              span {
                margin-left: 8px;
                color: #c3c3e2;
                font-size: 14px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
              }
            }

            .list-pirce {
              display: flex;
              justify-content: space-between;
              align-items: center;
            }

            .ico {
              position: absolute;
              top: 0;
              left: 0;
              font-size: 12px;
              color: #04f540;
              background-color: rgba(0, 0, 0, 0.5);
              padding: 2px 4px;
            }

            .pirce-left {
              display: flex;
              align-items: center;
              padding-right: 10px;
              font-size: 13px;
              color: #a5aabd;

              img {
                width: auto;
                height: 15px;
                margin-right: 5px;
              }

              & > div {
                flex: 1;
                color: #f4bd50;
                font-size: 10px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                span:last-child {
                  color: #c3c3e2;
                }
              }
            }

            .pirce-right {
              color: #848492;
              font-size: 10px;
            }
          }
        }
      }
    }

    .boxlist-botsm {
      // zoom: 0.64;
      padding: 10px 24%;

      ul {
        width: 100%;

        li {
          width: 100%;
          // background-size: 100% 100%;
          // background-repeat: no-repeat;
          // cursor: pointer;
          height: 45px !important;
          background: rgba(22, 26, 37, 1);
          border-radius: 3px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 12px;

          .boxlist-warp {
            // background-color: #24252f;
            width: 100%;
            padding: 12px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .boxlist1-top {
              padding: 0 20px;
              text-align: center;
              border-radius: 8px;
              display: flex;
              align-items: center;
              // border-bottom: 3px solid #f1a920;

              // box-shadow: 0px 5px 10px #f1a920;
              img {
                width: 24px !important;
                border-radius: 8px;
                height: auto;
              }

              span {
                width: 130px;
                color: white;
                margin-left: 8px;
                color: #c3c3e2;
                font-size: 12px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
              }
            }

            .boxlist1-top:last-child {
              display: flex;
              margin-left: 0 !important;

              img {
                width: 32px !important;
                border-radius: 8px;
                height: auto;
              }
            }

            .boxlist1-bot {
              padding: 8px;
              color: #c3c3e2;
              text-overflow: ellipsis;
              overflow: hidden;
              font-size: 10px;
              white-space: nowrap;
              // word-wrap: break-word;
            }

            .boxlist1-bot1 {
              width: 60px;
              padding: 8px;
              display: flex;
              font-size: 12px;
              align-items: center;
              margin-left: 5px !important;
              color: white !important;

              img {
                width: 14px;
                height: 14px;
                border-radius: 50%;
                margin-right: 6px !important;
              }

              span {
                margin-left: 8px;
                font-size: 14px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
              }
            }

            .list-pirce {
              display: flex;
              justify-content: space-between;
              align-items: center;
            }

            .ico {
              position: absolute;
              top: 0;
              left: 0;
              font-size: 12px;
              color: #04f540;
              background-color: rgba(0, 0, 0, 0.5);
              padding: 2px 4px;
            }

            .pirce-left {
              display: flex;
              align-items: center;
              padding-right: 10px;
              font-size: 13px;
              color: #a5aabd;

              img {
                width: auto;
                height: 15px;
                margin-right: 5px;
              }

              & > div {
                flex: 1;
                color: #f4bd50;
                font-size: 10px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                span:last-child {
                  color: #c3c3e2;
                }
              }
            }

            .pirce-right {
              color: #848492;
              font-size: 10px;
            }
          }
        }
      }
    }
  }

  /* .share-hide {
    //height: 300px;
  }*/
  .share-btn {
    display: flex;

    .btn {
      margin-left: 10px;
    }
  }

  /deep/ .el-dialog__footer {
    display: none;
  }

  /deep/ .el-dialog__title {
    color: #848492;
  }

  /deep/ .el-dialog__body {
    padding: 5px 20px;
  }

  /deep/ .el-dialog {
    min-height: none;
  }
}

@media screen and (max-width: 767px) {
  .openbox {
    // background-size: cover !important;
    // background-color: black;
    .kai-box {
      // padding: 0 !important;
      // position: fixed;
      // z-index: 99999;
      // top: 0;
      // left: 0;
      // height: 100vh;
      // width: 100vw;
    }

    .win-box {
      left: 0 !important;
      margin-left: 0 !important;
    }
  }

  .con-num {
    height: 55px !important;
  }
}
.gailv {
  color: #fff;
  display: flex;
  justify-content: flex-end;
  span {
    display: flex;
    align-items: center;
  }
  .probability-icon {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin: 0 2px 0 12px;
  }
}

.zhe {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: radial-gradient(
    46.87% 50% at 50% 50%,
    rgba(0, 0, 0, 0) 23.14%,
    rgba(0, 0, 0, 0.29) 44.96%,
    #000000 100%
  );
  z-index: 99999;
  @media (max-width: 550px) {
    background: radial-gradient(
      46.87% 50% at 50% 50%,
      rgba(0, 0, 0, 0) 23.14%,
      rgba(0, 0, 0, 0.29) 44.96%,
      #000000 100%
    ) !important;
  }
}
.zhe2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: radial-gradient(
    43.88% 50% at 50% 50%,
    rgba(0, 0, 0, 0) 45.3%,
    rgba(0, 0, 0, 0.5) 68.14%,
    #000000 100%
  );
  z-index: 99999;
  @media (max-width: 550px) {
    background: radial-gradient(
      46.61% 50% at 50% 50%,
      rgba(0, 0, 0, 0) 45%,
      rgba(0, 0, 0, 0.33) 60%,
      #000000 100%
    ) !important;
  }
}
.zhe3 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: radial-gradient(
    43.88% 50% at 50% 50%,
    rgba(0, 0, 0, 0) 45.3%,
    rgba(0, 0, 0, 0.5) 68.14%,
    #000000 100%
  );
  z-index: 99999;
  @media (max-width: 550px) {
    background: radial-gradient(
      46.61% 50% at 50% 50%,
      rgba(0, 0, 0, 0) 60%,
      rgba(0, 0, 0, 0.33) 70%,
      #000000 100%
    ) !important;
  }
}
.zhe4 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: radial-gradient(
    43.88% 50% at 50% 50%,
    rgba(0, 0, 0, 0) 60.2%,
    rgba(0, 0, 0, 0.5) 73.87%,
    #000000 100%
  );
  z-index: 99999;
  @media (max-width: 550px) {
    // background: radial-gradient(57.03% 50% at 50% 50%, rgba(0, 0, 0, 0) 65.24%, rgba(0, 0, 0, 0.72) 73.92%, #000000 98.25%) !important;
    background: radial-gradient(
      46.61% 50% at 50% 50%,
      rgba(0, 0, 0, 0) 60%,
      rgba(0, 0, 0, 0.33) 70%,
      #000000 100%
    ) !important;
  }
}
.zhe5 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: radial-gradient(
    43.88% 50% at 50% 50%,
    rgba(0, 0, 0, 0) 75.56%,
    rgba(0, 0, 0, 0.5) 87.32%,
    #000000 100%
  );
  z-index: 99999;
  @media (max-width: 550px) {
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(0, 0, 0, 0) 70.16%,
      rgba(0, 0, 0, 0.59) 88.04%,
      rgba(0, 0, 0, 0.81) 98.25%
    ) !important;
  }
}
.dropdown {
  display: inline-block;
  width: 58px !important;
  height: 40px;
  border-color: #ee4946;
  background: linear-gradient(
    to right,
    rgba(238, 73, 70, 0) 0%,
    rgba(238, 73, 70, 0.3) 100%
  );
  text-align: center;
  border-radius: 99px;
  color: #fff;
  line-height: 40px;
  border: 2px solid #ee4946;
  position: relative;
  z-index: 0;
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #1e1a45;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  // padding: 12px 16px;
  z-index: 8;
  box-sizing: border-box;
  // margin-top: px;
}
.dropdown:hover .dropdown-content {
  display: block;
  // background: #2a2461;
}
.dropdown:visited .dropdown-content {
  display: block;
  // background: #2a2461;
}
.dropdown-content a {
  color: #fff;
  // padding: 8px 0;
  text-decoration: none;
  display: block;
}
.dropdown-content a:hover {
  background-color: #2a2461;
}

.open-btn:hover {
  background: #ee4946;
}
.open-btn:hover .color\#FF0000 {
  background: #ee4946;
}
.open-btn:hover .color\#FFCC33 {
  background: #f5c900;
}
.open-btn:hover .color\#000000 {
  background: #5e5e5e;
}
.open-btn:hover .color\#3366FF {
  background: #3d5afe;
}
.open-btn:hover .color\#00CC00 {
  background: #25a464;
}
.open-btn:hover .color\#9900FF {
  background: #883cff;
}
.open-btn:hover .color\#FF5511 {
  background: #eca63c;
}
.cartState:hover {
  background: #ee4946;
}
.cartState:hover .color\#FF0000 {
  background: #ee4946;
}
.cartState:hover .color\#FFCC33 {
  background: #f5c900;
}
.cartState:hover .color\#000000 {
  background: #5e5e5e;
}
.cartState:hover .color\#3366FF {
  background: #3d5afe;
}
.cartState:hover .color\#00CC00 {
  background: #25a464;
}
.cartState:hover .color\#9900FF {
  background: #883cff;
}
.cartState:hover .color\#FF5511 {
  background: #eca63c;
}
.gradient-color-\#007acc {
  background-image: linear-gradient(to bottom, #4b69ff 0, #2740bb 100%);
}
// 紫色
.gradient-color-\#8a2be2 {
  background-image: linear-gradient(to bottom, #8847ff 0, #5e29bf 100%);
}
// 金色
.gradient-color-\#FFA500 {
  background-image: linear-gradient(to bottom, #ffae39 0%, #b46d06 100%);
}
// 红色
.gradient-color-\#FF4500 {
  background-image: linear-gradient(to bottom, #e34343 0%, #ca2d2d 100%);
}
// 红色
.color\#FF0000 {
  border-color: #ee4946 !important;
  background: linear-gradient(
    to right,
    rgba(238, 73, 70, 0) 0%,
    rgba(238, 73, 70, 0.3) 100%
  ) !important;
}
// 黄色
.color\#FFCC33 {
  border-color: #f5c900 !important;
  background: linear-gradient(
    to right,
    rgba(245, 201, 0, 0) 0%,
    rgba(245, 201, 0, 0.3) 100%
  ) !important;
}
// 灰色
.color\#000000 {
  border-color: #5e5e5e !important;
  background: linear-gradient(
    to right,
    rgba(94, 94, 94, 0) 0%,
    rgba(94, 94, 94, 0.3) 100%
  ) !important;
}
// 蓝色
.color\#3366FF {
  border-color: #3d5afe !important;
  background: linear-gradient(
    to right,
    rgba(61, 90, 254, 0) 0%,
    rgba(61, 90, 254, 0.3) 100%
  ) !important;
}
// 绿色
.color\#00CC00 {
  border-color: #25a464 !important;
  background: linear-gradient(
    to right,
    rgba(37, 164, 100, 0) 0%,
    rgba(37, 164, 100, 0.3) 100%
  ) !important;
}
// 紫色
.color\#9900FF {
  border-color: #883cff !important;
  background: linear-gradient(
    to right,
    rgba(136, 60, 255, 0) 0%,
    rgba(136, 60, 255, 0.3) 100%
  ) !important;
}
// 橙色
.color\#FF5511 {
  border-color: #eca63c !important;
  background: linear-gradient(
    to right,
    rgba(236, 166, 60, 0) 0%,
    rgba(236, 166, 60, 0.3) 100%
  ) !important;
}
// 蓝色
.gradient-bg-color-\#007acc {
  background: radial-gradient(
    ellipse at center,
    #4b69ff -300%,
    #191c28 75%
  ) !important;
}
// 紫色
.gradient-bg-color-\#8a2be2 {
  background: radial-gradient(
    ellipse at center,
    #8847ff -300%,
    #191c28 75%
  ) !important;
}
// 金色
.gradient-bg-color-\#FFA500 {
  background: radial-gradient(
    ellipse at center,
    #ffae39 -300%,
    #191c28 75%
  ) !important;
}
// 红色
.gradient-bg-color-\#FF4500 {
  background: radial-gradient(
    ellipse at center,
    #eb4b4b -300%,
    #191c28 75%
  ) !important;
}
.Opening {
  width: 120px;
  height: 40px;
  border-radius: 30px;
  background-color: #3f5aff;
  margin: 0 auto;
  line-height: 40px;
  color: #fff;
  padding: 4px 20px;
}
.border {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -0.375rem;
  width: 0.375rem;
  z-index: 0;
}
.public-css ul li {
  max-width: 140px !important;
  background-size: 100% 100%;
  display: flex;
  // justify-content: center;
  // align-items: center;
  position: relative;
  padding: 0 10px 0 4px;
  flex-direction: column;
  transition: opacity linear 0.5s 0.7s;
  .item-img {
    width: 140px;
    text-align: center;
    height: 80px;
  }
}
.public-css ul li {
  margin-bottom: 5px;
}
.public-css ul li:last-of-type {
  margin-bottom: 0px;
}
.item-name {
  color: #d2d3d5;
  background-color: #141721;
  text-align: left;
  padding: 6px 20px 6px 15px;
  min-height: 28px;
  box-sizing: border-box;
  width: 100%;
  font-size: 10px;
  span {
    font-size: 10px;
    line-height: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }
}
.dingbu {
  position: absolute;
  height: 20px;
  // width: 50%;
  width: 100%;
  left: 0;
  top: -20px;
  z-index: 2;
}

.dibu {
  position: absolute;
  z-index: 2;
  left: 0;
  height: 20px;
  bottom: -20px;
  // transform: rotate(180deg);
  width: 100%;
  // bottom: -80px;
  // z-index: 99;
}
.lottery-vert-line {
  width: 4px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  background: #2fcf72;
  box-shadow: 0 0 20px #2fcf72;
  opacity: 0;
  z-index: 3;
}
.lottery-vert-line:after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #2fcf72;
  left: -13px;
  z-index: 3;
}
@keyframes run4 {
  0% {
    left: 0;
  }

  100% {
    left: -9048px;
  }
}
.free-box-desc{
  width:  50%;
  color: #fff;
  margin: 30px auto;
  text-align: center;
  line-height: 28px;
}
.opacity_04 {
  opacity: 0.4;
}
</style>
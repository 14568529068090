<template>
  <div class="base-header">
    <div class="nav2">
      <ul>
        <li
          v-for="(item, index) in menu"
          :key="index"
          @click="goMenu(item.id, item.path)"
          :class="{
            activeli: $route.name == item.paths,
          }"
        >
          <img :src="item.img1" alt="" v-if="$route.name == item.paths" />
          <img :src="item.img2" alt="" v-else />
          <span
            :class="{
              active: $route.name == item.paths,
            }"
            >{{ item.value }}</span
          >
        </li>
        <li class="giveaway">
          <img src="@/assets/img/giveaway.svg" alt="" />
          <span>{{ $t("赠品") }}</span>
          <ul class="sub giveaway-menu">
            <li
              v-for="(item, index) in giveawayList"
              :key="index"
              @click="goBox(item.id,1)"
            >
              {{ item.name }}
            </li>
            <li  v-for="(item,index) in boxInfo" :key="'timebox'+index+item.id" @click="goBox(item.id,2)">
             {{ item.title }}
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "base-header",
  data() {
    return {
      menu: [
        {
          id: 1,
          img1: require("@/assets/img/nav2/box2.png"),
          img2: require("@/assets/img/nav2/box.png"),
          selState: true,
          path: "Index",
          paths: "Index",
          child: ["Index", "Openbox"],
          value: this.$t("menu.Index"),
        },
        {
          id: 5,
          img1: require("@/assets/img/nav2/store2.png"),
          img2: require("@/assets/img/nav2/store.png"),
          selState: false,
          path: "Roll",
          paths: "Roll",
          child: ["Roll"],
          value: this.$t("menu.Roll"),
        },
        {
          id: 8,
          img1: require("@/assets/img/nav2/taihuan2.png"),
          img2: require("@/assets/img/nav2/taihuan.png"),
          selState: false,
          path: "upgrader",
          paths: "upgrader",
          child: ["upgrader", "Openbox"],
          value: this.$t("升级"),
        },
         {
        	id: 4,
        	img1: require("@/assets/img/nav2/lucky2.png"),
        	img2: require("@/assets/img/nav2/lucky.png"),
        	selState: false,
        	path: "LuckySkins",
        	paths: "LuckySkins",
        	child: [
        		"Ornament",
        		"LuckySkins",
        		"OrnamentHistory",
        		"OrnamentHistory2",
        	],
        	value: this.$t('menu.OrnamentOpen'),
        },
        // {
        // 	id: 2,
        // 	img1: require("@/assets/img/nav2/battle2.png"),
        // 	img2: require("@/assets/img/nav2/battle.png"),
        // 	selState: false,
        // 	path: "Lucky",
        // 	paths: "Lucky",
        // 	child: ["Lucky", "LuckyRoom", "LuckyRule", "LuckyHistory"],
        // 	value:  this.$t('menu.Lucky'),
        // },
        // {
        // 	id: 3,
        // 	img1: require("@/assets/img/nav2/roll2.png"),
        // 	img2: require("@/assets/img/nav2/roll.png"),
        // 	selState: false,
        // 	path: "Arena",
        // 	paths: "Arena",
        // 	child: ["Arena", "ArenaRoom"],
        // 	value: this.$t('menu.Arena'),
        // },
       

        // {
        // 	id: 7,
        // 	img1: require("@/assets/img/nav2/mianfeifuli2.png"),
        // 	img2: require("@/assets/img/nav2/mianfeifuli.png"),
        // 	selState: false,
        // 	path: "Activity",
        // 	paths: "Activity",
        // 	child: ["Activity","Openbox"],
        // 	value:this.$t('menu.Activity'),
        // },

        // {
        // 	id: 9,
        // 	img1: require("@/assets/img/nav2/shijianbox2.png"),
        // 	img2: require("@/assets/img/nav2/shijianbox.png"),
        // 	selState: false,
        // 	path: "TimeBox",
        // 	paths: "TimeBox",
        // 	child: ["TimeBox","Openbox"],
        // 	value:  this.$t('menu.TimeBox')
        // },
      ],
      giveawayList: [
        
      ],
      language:
        localStorage.getItem("lang") ||
        this.$store.state.currentLanguage ||
        "zh",
        boxInfo:[]
    };
  },
  mounted() {
    this.getboxList();
    this.getInfo();
  },
  methods: {
    goMenu(id, path) {
      this.$emit("goMenu", id, path);
    },
    goBox(id, type) {
      this.$emit("goBox", id, type);
    },
    getboxList() {
      let _this = this;
      _this.$axios.post("/api/welfare/getnewlist").then((res) => {
        if (res.data.code == 1) {
          _this.giveawayList = res.data.data;
        }
      });
    },
    // 获取时间盲盒数据
    getInfo() {
      this.$axios({
        url: "/api/rechargetime/rechargetimelist",
        method: "post",
      }).then((res) => {
        if (res.data.code == 1) {
          this.boxInfo = res.data.data;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.base-header {
  padding-left: 50px;

  .nav2 {
    height: 42px;

    ul {
      display: flex;

      .active {
        color: #fff;
      }
      .activeli {
        position: relative;
        // &::after{
        // 	position: absolute;
        // 	bottom: 0px;
        // 	left: 50%;
        // 	transform: translate(-50%);
        // 	content: '';
        // 	width: 50px;
        // 	height: 2px;
        // 	background: linear-gradient(98deg, rgb(255, 47, 47) 2.49%, rgb(159, 36, 255) 92.88%);
        // 	display: block;
        // }
      }
      li {
        float: left;
        display: flex;
        align-items: center;
        justify-content: center;
        // width: 100px;
        text-align: center;
        cursor: pointer;
        color: #8494a7;
        font-size: 16px;
        line-height: 38px;
        padding: 0px 14px;
        white-space: nowrap;
        font-weight: 700;
        border-right: 1px solid #2a2550;
        &:last-child {
          border: none;
        }
        img {
          width: 17px;
          height: auto;
          margin-right: 10px;
        }
        &:hover {
          color: #fff;
        }
        .icon {
          width: 20px;
          height: 20px;
          background-image: url(../../../assets/img/nav2/b11.png);
          background-size: 100% 100%;
        }
      }
    }
  }
}
.giveaway {
  position: relative;
}

.giveaway::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  height: 2rem;
  pointer-events: all; /* 扩大鼠标悬停范围 */
}
.giveaway:hover .sub,
.giveaway .giveaway-menu:hover .sub {
  display: block !important;
}
.sub {
  display: none !important;
}
.giveaway-menu {
  position: absolute;
  top: 55px;
  left: 0px;
  width: 100%;
  //   background: #1e1e1e;
  z-index: 999;
  li {
    background: #1e1a45;
    border: none;
    margin: 0;
    padding: 12px 30px !important;
    color: #8494a7 !important;
    transition: 0.3s;
    text-decoration: none;
    line-height: 30px !important;
    font-weight: 700 !important;
    font-size: 13px !important;
    width: 6rem;
    max-width: 6rem;
    &:hover {
      color: #fff !important;
    }
  }
}
@media screen and (max-width: 767px) {
  .base-header {
    display: none;
  }
}
</style>